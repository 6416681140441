import React, { createContext, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const TENANT_MOBICARE = 'TENANT_MOBICARE';
export const TENANT_CLARO = 'TENANT_CLARO';

const hostMobicare = [
  'pme-mobicare-dev.apolowifi.com',
  'pme-mobicare-hml.apolowifi.com',
];

const hostClaro = [
  'pme-claro-dev.apolowifi.com',
  'pme-claro-hml.apolowifi.com',
];

const validateHostname = (host, hosts) => !!hosts.find((h) => h === host);

const TenantContext = createContext({});

export const TenantProvider = ({ children }) => {
  const [tenant, setTenant] = useState(null);

  const validateTenant = useCallback(async () => {
    const { hostname } = window.location;
    if (validateHostname(hostname, hostClaro)) {
      setTenant(TENANT_CLARO);
    } else if (validateHostname(hostname, hostMobicare)) {
      setTenant(TENANT_MOBICARE);
    } else {
      setTenant(TENANT_MOBICARE);
    }
  }, []);

  // const signOut = useCallback(() => {
  //   localStorage.removeItem('@GoBarber:token');
  //   localStorage.removeItem('@GoBarber:user');

  //   setTenant(null);
  // }, []);

  return (
    <TenantContext.Provider value={{ tenant, validateTenant }}>
      {children}
    </TenantContext.Provider>
  );
};

TenantProvider.defaultProps = {
  children: null,
};

TenantProvider.propTypes = {
  children: PropTypes.node,
};

export function useTenant() {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within an TenantProvider');
  }
  return context;
}
