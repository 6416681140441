/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { Pagination } from './styles';

const PaginateData = (props) => {
  const { pageCount, onPageChange } = props;

  return (
    <Pagination>
      <ReactPaginate
        previousLabel="Anterior"
        nextLabel="Próxima"
        breakLabel="..."
        breakClassName="break-me"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
    </Pagination>
  );
};

PaginateData.defaultProps = {
  onPageChange: () => {},
};

PaginateData.propTypes = {
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
};

export default PaginateData;
