import { all, takeLatest, put, call } from 'redux-saga/effects';

import types from './types';
import { propagateSetState } from './actions';
import { userSetState } from '../user/actions';
import {
  setIsLoading,
  setIsSuccess,
  setIsError,
} from '~/store/modules/loading/actions';
import history from '~/services/history';

import api from '../../../services/api';

export function* propagateRequest() {
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(api.get, `propagate`);
    yield put(propagateSetState({ propagateData: data }));
    // if (data && data.list.length === 0) {
    //   history.push('/propagate/first');
    // }
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsError(true));
  }
}

export function* propagateSave() {
  yield put(setIsLoading(true));
  try {
    yield put(userSetState({ firstAccess: false }));
    yield put(setIsSuccess(true, () => history.push('/propagate')));
  } catch (error) {
    yield put(setIsError(true));
  }
}

export function* propagateGet({ payload }) {
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(api.get, `propagate`);
    const campaignData = data.campaigns.find(
      (item) => item.id === payload.uuid
    );
    yield put(propagateSetState({ campaignData }));
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsError(true));
  }
}

export default all([
  takeLatest(types.PROPAGATE_DATA_REQUEST, propagateRequest),
  takeLatest(types.PROPAGATE_CAMPAIGN_SAVE, propagateSave),
  takeLatest(types.PROPAGATE_CAMPAIGN_GET, propagateGet),
]);
