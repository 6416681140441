import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import loadingFile from '~/lottiefiles/loading-microinteraction-animation.json';
import successFile from '~/lottiefiles/success-microinteraction-animation.json';
import errorFile from '~/lottiefiles/error-microinteraction-animation.json';

import {
  setIsError,
  setIsLoading,
  setIsSuccess,
} from '~/store/modules/loading/actions';

import { Container } from './styles';

function Loading() {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, callback } = useSelector(
    (state) => state.loading
  );

  const renderIsLoading = () => (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loadingFile,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={50}
      width={50}
    />
  );

  const renderIsSuccess = () => (
    <Lottie
      options={{
        loop: false,
        autoplay: true,
        animationData: successFile,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={50}
      width={50}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => {
            dispatch(setIsError(false));
            dispatch(setIsLoading(false));
            dispatch(setIsSuccess(false));
            callback();
          },
        },
      ]}
    />
  );

  const renderIsError = () => (
    <Lottie
      options={{
        loop: false,
        autoplay: true,
        animationData: errorFile,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={50}
      width={50}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => {
            dispatch(setIsError(false));
            dispatch(setIsLoading(false));
            dispatch(setIsSuccess(false));
            callback();
          },
        },
      ]}
    />
  );

  return (
    (isLoading || isSuccess || isError) && (
      <Container>
        {isLoading && !isSuccess && !isError && renderIsLoading()}
        {isLoading && isSuccess && renderIsSuccess()}
        {isLoading && isError && renderIsError()}
      </Container>
    )
  );
}

export default Loading;
