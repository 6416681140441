/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { withTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { FaChevronLeft, FaInfoCircle, FaRegCalendar } from 'react-icons/fa';

import { userSetState } from '~/store/modules/user/actions';
import history from '~/services/history';

import {
  WrapPage,
  Container,
  ResearchTitle,
  ResearchSubtitle,
  ResearchLogo,
  Label,
  InputName,
  InputData,
  InputWrap,
  InputBox,
  Legend,
  ButtonNext,
  ButtonCancel,
} from './styles';

function Step1() {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  useEffect(() => {
    dispatch(userSetState({ wizardProgress: 50, backgroundImage: null }));
  }, []);

  return (
    <WrapPage>
      <Container>
        <ResearchTitle>
          <Link to="/propagate">
            <FaChevronLeft size={14} />
          </Link>
          Pesquisa
        </ResearchTitle>
        <ResearchSubtitle>PASSO 1/2</ResearchSubtitle>
        <ResearchLogo />
        <InputWrap>
          <InputBox>
            <Label>
              Nome da campanha
              <FaInfoCircle
                data-tip="Escolha um nome que seja de fácil identificação da campanha."
                data-for="tooltip-provider"
              />
            </Label>
            <InputName
              name="name"
              type="text"
              placeholder="Digite o nome da campanha"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </InputBox>
        </InputWrap>
        <Legend style={{ marginBottom: 30 }}>
          Exemplo: "Promoções de Natal". Seu cliente não irá visualizar esse
          item.
        </Legend>
        <InputWrap style={{ marginBottom: 30 }}>
          <InputBox>
            <Label>
              Início da campanha:
              <FaInfoCircle
                data-tip="Data de início de veiculação da campanha."
                data-for="tooltip-provider"
              />
            </Label>
            <InputData
              name="init-date"
              type="text"
              placeholder="99/99/9999 às 00:00h"
              value={initialDate}
              onChange={setInitialDate}
              icon={FaRegCalendar}
              responseFormat="YYYY-MM-DD HH:mm:ss"
            />
          </InputBox>
        </InputWrap>
        <InputWrap style={{ marginBottom: 30 }}>
          <InputBox>
            <Label>
              Fim da campanha:
              <FaInfoCircle
                data-tip="Data de conclusão de veiculação da campanha."
                data-for="tooltip-provider"
              />
            </Label>
            <InputData
              name="final-date"
              type="text"
              placeholder="99/99/9999 às 00:00h"
              value={finalDate}
              onChange={setFinalDate}
              icon={FaRegCalendar}
              responseFormat="YYYY-MM-DD HH:mm:ss"
            />
          </InputBox>
        </InputWrap>
        <InputWrap style={{ marginBottom: 30 }}>
          <ButtonNext
            title="Avançar"
            onClick={() => history.push('/propagate/research/step2')}
          />
          <ButtonCancel
            title="Cancelar"
            onClick={() => history.push('/propagate')}
          />
        </InputWrap>

        <ReactTooltip
          place="right"
          backgroundColor="#FFFFFF"
          textColor="#323232"
          border
          borderColor="#d1d1d1"
          className="tooltip-wrap"
          id="tooltip-provider"
        />
      </Container>
    </WrapPage>
  );
}

export default withTheme(Step1);
