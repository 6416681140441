import React from 'react';
import uuidv4 from 'uuid/v4';
import ReactTooltip from 'react-tooltip';
import { AiFillInfoCircle, AiOutlineStar } from 'react-icons/ai';
import { BsCheckBox } from 'react-icons/bs';
import { TiSortNumerically } from 'react-icons/ti';
import { BiCalendarAlt } from 'react-icons/bi';
import { MdSortByAlpha, MdRadioButtonChecked } from 'react-icons/md';
import PropTypes from 'prop-types';

import { Container } from './styles';

function FormAddField({ onAdd }) {
  return (
    <Container className="tour-step-2">
      <span>Inserir item</span>
      <AiFillInfoCircle
        data-tip="Clique num botão da lista para inserir uma questão no formulário."
        data-for="tooltip-research"
      />
      <div className="form-buttons">
        <button
          type="button"
          data-tip="Inserir campo de CheckBox (Seleção de múltiplos itens)."
          data-for="tooltip-research"
          onClick={() =>
            onAdd({
              uuid: uuidv4(),
              question: '',
              type: 'check',
              options: [],
              isRequired: false,
            })
          }
          className="tour-step-3"
        >
          <BsCheckBox />
        </button>
        <button
          type="button"
          data-tip="Inserir campo de escolha (Seleção única)."
          data-for="tooltip-research"
          onClick={() =>
            onAdd({
              uuid: uuidv4(),
              question: '',
              type: 'radio',
              options: [],
              isRequired: false,
            })
          }
          className="tour-step-4"
        >
          <MdRadioButtonChecked />
        </button>
        <button
          type="button"
          data-tip="Inserir campo de texto"
          data-for="tooltip-research"
          onClick={() =>
            onAdd({
              uuid: uuidv4(),
              question: '',
              type: 'text',
              isRequired: false,
            })
          }
          className="tour-step-5"
        >
          <MdSortByAlpha />
        </button>
        <button
          type="button"
          data-tip="Inserir campo somente números."
          data-for="tooltip-research"
          onClick={() =>
            onAdd({
              uuid: uuidv4(),
              question: '',
              type: 'number',
              isRequired: false,
            })
          }
          className="tour-step-6"
        >
          <TiSortNumerically />
        </button>
        <button
          type="button"
          data-tip="Inserir campo de nota de 1 a 5."
          data-for="tooltip-research"
          onClick={() =>
            onAdd({
              uuid: uuidv4(),
              question: '',
              type: 'range',
              isRequired: false,
            })
          }
          className="tour-step-7"
        >
          <AiOutlineStar />
        </button>
        <button
          type="button"
          data-tip="Inserir campo de data."
          data-for="tooltip-research"
          onClick={() =>
            onAdd({
              uuid: uuidv4(),
              question: '',
              type: 'date',
              isRequired: false,
            })
          }
          className="tour-step-8"
        >
          <BiCalendarAlt />
        </button>
      </div>
      <ReactTooltip
        place="bottom"
        backgroundColor="#FFFFFF"
        textColor="#323232"
        border
        borderColor="#d1d1d1"
        className="tooltip-wrap"
        id="tooltip-research"
      />
    </Container>
  );
}

FormAddField.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default FormAddField;
