import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 56px;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  padding-left: 24px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 24px;
  margin-left: 24px;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'unset')};
`;

export const AlertMessage = styled.div`
  color: #0a0724;
  font-size: 14px;
`;

export const ButtonConfigure = styled.button.attrs({ type: 'button' })`
  color: #4a90e2;
  font-size: 14px;
  border: 0;
  background-color: transparent;
  margin-left: 10px;
`;
