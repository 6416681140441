import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { FiPlus } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';

import { Container, IconBox, Wrap, DeleteButton } from './styles';

function Dropzone({ onDropOk, onClear, value, disabled }) {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      onDropOk(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/png,image/jpg,image/jpeg',
    multiple: false,
    disabled,
  });

  return (
    (!disabled || !!value) && (
      <Wrap>
        {!!value && !disabled && (
          <DeleteButton onClick={onClear}>
            <IoMdClose />
          </DeleteButton>
        )}
        <Container {...getRootProps()} image={value}>
          <input {...getInputProps()} />
          {!value && (
            <>
              <IconBox>
                <FiPlus />
              </IconBox>
              {isDragActive ? (
                <p>Soltar imagem aqui</p>
              ) : (
                <p>
                  Selecione uma imagem para
                  <br /> o seu template de e-mail
                </p>
              )}
            </>
          )}
        </Container>
      </Wrap>
    )
  );
}

Dropzone.defaultProps = {
  onDropOk: () => {},
  onClear: () => {},
  value: '',
  disabled: false,
};

Dropzone.propTypes = {
  onDropOk: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Dropzone;
