import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;
`;

export const Base = styled.div`
  flex: 1;
  max-width: 500px;

  > h1 {
    margin: 25px 0;
  }

  > p {
    line-height: 30px;
    margin-bottom: 55px;
  }
`;

export const BaseImage = styled.div`
  flex: 2;

  img {
    max-width: 100%;
  }
`;
