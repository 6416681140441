import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoIosArrowForward } from 'react-icons/io';

import { supportDataRequest } from '~/store/modules/supports/actions';

import DashboardTitle from '~/components/DashboardTitle';
import SearchWithOrderBy from '~/components/SearchWithOrderBy';
import history from '~/services/history';

import {
  WrapPage,
  Container,
  Header,
  HeaderActions,
  Content,
  ListBox,
  ListItem,
  Footerbox,
  ButtonContactUs,
} from './styles';

function Support() {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.supports);
  const [valueOrder, setValueOrder] = useState({
    label: 'Recentes',
    value: 'recent',
  });
  const [valueSearch, setValueSearch] = useState('');

  useEffect(() => {
    dispatch(supportDataRequest());
  }, []);

  const renderHeader = () => {
    const options = [
      { label: 'Todos', value: 'all' },
      { label: 'Recentes', value: 'recent' },
      { label: 'Mais vistas', value: 'more-views' },
    ];
    return (
      <DashboardTitle title="Ajuda">
        <HeaderActions>
          <SearchWithOrderBy
            options={options}
            valueOrder={valueOrder}
            onChangeOrder={setValueOrder}
            valueSearch={valueSearch}
            onChangeSearch={setValueSearch}
          />
        </HeaderActions>
      </DashboardTitle>
    );
  };

  const renderContent = () => (
    <Content>
      <p className="description">Olá! Como podemos ajudar? :)</p>
      <p className="list-title">Dúvidas por tema:</p>
      <ListBox>
        {list.map((item) => (
          <ListItem
            key={item.id}
            onClick={() => {
              history.push(`/support/${item.id}`);
            }}
          >
            {item.title}
            <IoIosArrowForward />
          </ListItem>
        ))}
      </ListBox>
      <Footerbox>
        <p>Ainda tem alguma dúvida?</p>
        <ButtonContactUs
          title="Fale conosco"
          onClick={() => {
            history.push(`/support/contact-us`);
          }}
        />
      </Footerbox>
    </Content>
  );

  return (
    <WrapPage>
      <Container>
        <Header>{renderHeader()}</Header>
        {renderContent()}
      </Container>
    </WrapPage>
  );
}

export default Support;
