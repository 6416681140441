import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Proptypes from 'prop-types';

import { useTenant } from '../../hooks/tenant';
import Theme from '../../styles/theme';
import Header from '../../components/Header';
import MenuLeft from '../../components/MenuLeft';

import { Container, Pages, WrapContent } from './styles';

function Dashboard({ children }) {
  const [theme, setTheme] = useState(null);
  const { tenant, validateTenant } = useTenant();
  useEffect(() => {
    validateTenant();
  }, []);

  useEffect(() => {
    setTheme(Theme.getThemeByTenant(tenant));
  }, [tenant]);

  return (
    theme && (
      <ThemeProvider theme={theme}>
        <Container>
          <MenuLeft />
          <WrapContent>
            <Header />
            <Pages>{children}</Pages>
          </WrapContent>
        </Container>
      </ThemeProvider>
    )
  );
}

Dashboard.propTypes = {
  children: Proptypes.element.isRequired,
};

export default Dashboard;
