import types from './types';

export function headerSetState(state) {
  return {
    type: types.HEADER_SET_STATE,
    payload: { state },
  };
}

export function headerNotificationsRequest() {
  return {
    type: types.HEADER_NOTIFICATIONS_REQUEST,
  };
}
