import { all } from 'redux-saga/effects';

import user from './user/sagas';
import menuLeft from './menuLeft/sagas';
import dashboard from './dashboard/sagas';
import visitors from './visitors/sagas';
import header from './header/sagas';
import supports from './supports/sagas';
import propagate from './propagate/sagas';
import loading from './loading/sagas';

export default function* rootSaga() {
  return yield all([
    user,
    menuLeft,
    dashboard,
    visitors,
    header,
    supports,
    propagate,
    loading,
  ]);
}
