import React from 'react';
import PropTypes from 'prop-types';

import { TenantProvider } from './tenant';

const AppProvider = ({ children }) => (
  <TenantProvider>{children}</TenantProvider>
);

AppProvider.defaultProps = {
  children: null,
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default AppProvider;
