import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.steps.link};
  font-family: Poppins;
  font-weight: bold;
  font-size: 14px;

  a {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    svg {
      fill: ${(props) => props.theme.colors.steps.link};
    }
  }
`;
