/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { FaChevronLeft, FaWpforms, FaInfoCircle } from 'react-icons/fa';
import { BiVideo, BiMessageDots, BiCalendarAlt } from 'react-icons/bi';
import { BsFillEnvelopeFill, BsWifi, BsCheckBox } from 'react-icons/bs';
import { MdRadioButtonChecked, MdSortByAlpha } from 'react-icons/md';
import { TiSortNumerically } from 'react-icons/ti';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { AiOutlineStar, AiOutlineFileImage } from 'react-icons/ai';

import { propagateCampaignGet } from '~/store/modules/propagate/actions';

import ResultEmail from './ResultEmail';
import ChartGender from './components/Gender';
import ChartAgeRange from './components/AgeRange';
import ChartDevices from './components/Devices';
import ChartPeakhours from './components/Peakhours';
import ChartCheckboxType from './components/ChartCheckboxType';
import ChartRadioboxType from './components/ChartRadioboxType';
import ChartTextType from './components/ChartTextType';
import ChartNumberType from './components/ChartNumberType';
import ChartRangeType from './components/ChartRangeType';
import ChartDateType from './components/ChartDateType';

import {
  WrapPage,
  Container,
  PageTitle,
  CampaignBox,
  CampaignBoxHeader,
  CampaignIcon,
  CampaignBoxSection,
  CampaignBoxResultCard,
  CampaignBoxDemographicCard,
  ResearchItemFormBox,
} from './styles';

function CampaignResult({ match: { params } }) {
  const dispatch = useDispatch();
  const [minimizedBoxes, setMinimizedBoxes] = useState([]);
  const { campaignData } = useSelector((state) => state.propagate);
  const formResearchData = [
    {
      uuid: '69b19a20-ad41-4bb4-91e9-8587f000e9f1',
      question: 'Campo check',
      type: 'check',
      options: [
        {
          uuid: 'a2403d75-0757-431f-88bb-5367cb7c9577',
          option: 'Check 1',
          answers: 8,
        },
        {
          uuid: '1d2d73a6-4c14-4718-a154-f58cc6c93fdf',
          option: 'Check 2',
          answers: 5,
        },
        {
          uuid: 'f8b08102-0702-4a8f-9d81-799988dc9f79',
          option: 'Check 3',
          answers: 15,
        },
        {
          uuid: 'f8b08102-0702-4a8f-9d81-799988dc9f77',
          option: 'Check 4',
          answers: 10,
        },
      ],
      isRequired: false,
    },
    {
      uuid: 'd676b413-f6e2-4e7b-b5d2-fb9ce262a860',
      question: 'Campo radio',
      type: 'radio',
      options: [
        {
          uuid: 'a2403d75-0757-431f-88bb-5367cb7c9577',
          option: 'Radio 1',
          answers: 2,
        },
        {
          uuid: '1d2d73a6-4c14-4718-a154-f58cc6c93fdf',
          option: 'Radio 2',
          answers: 5,
        },
        {
          uuid: 'f8b08102-0702-4a8f-9d81-799988dc9f79',
          option: 'Radio 3',
          answers: 2,
        },
        {
          uuid: 'f8b08102-0702-4a8f-9d81-799988dc9f52',
          option: 'Radio 4',
          answers: 8,
        },
      ],
      isRequired: true,
    },
    {
      uuid: '8e13cafe-eb16-45f3-83c9-aa52f3118079',
      question: 'Campo texto',
      type: 'text',
      isRequired: true,
      answers: [
        {
          id: '8e13cafe-1',
          answer:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. In quisquam, aut nobis molestiae tempora laborum ex exercitationem officiis quis enim eum dolores eveniet voluptatem corporis',
        },
        {
          id: '8e13cafe-2',
          answer:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. In quisquam, aut nobis molestiae tempora laborum ex exercitationem officiis quis enim eum dolores eveniet voluptatem corporis',
        },
        {
          id: '8e13cafe-3',
          answer:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. In quisquam, aut nobis molestiae tempora laborum ex exercitationem officiis quis enim eum dolores eveniet voluptatem corporis',
        },
        {
          id: '8e13cafe-4',
          answer:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. In quisquam, aut nobis molestiae tempora laborum ex exercitationem officiis quis enim eum dolores eveniet voluptatem corporis',
        },
        {
          id: '8e13cafe-5',
          answer:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. In quisquam, aut nobis molestiae tempora laborum ex exercitationem officiis quis enim eum dolores eveniet voluptatem corporis',
        },
      ],
    },
    {
      uuid: 'a797d7bd-f22b-49f2-b1bd-e0bf6c265b5c',
      question: 'Campo número',
      type: 'number',
      isRequired: false,
      answers: [
        {
          id: '8e13cafe-1',
          answer: '1234567890',
        },
        {
          id: '8e13cafe-2',
          answer: '1234567890',
        },
        {
          id: '8e13cafe-3',
          answer: '1234567890',
        },
        {
          id: '8e13cafe-4',
          answer: '1234567890',
        },
        {
          id: '8e13cafe-5',
          answer: '1234567890',
        },
      ],
    },
    {
      uuid: 'b99dc6cb-7795-4d7a-a200-0d142a482925',
      question: 'Campo nota',
      type: 'range',
      isRequired: false,
      answers: [
        {
          uuid: 'a2403d75-0757-431f-88bb-5367cb7c9577',
          option: '1',
          answers: 8,
        },
        {
          uuid: '1d2d73a6-4c14-4718-a154-f58cc6c93fdf',
          option: '2',
          answers: 5,
        },
        {
          uuid: 'f8b08102-0702-4a8f-9d81-799988dc9f79',
          option: '3',
          answers: 15,
        },
        {
          uuid: 'f8b08102-0702-4a8f-9d81-799988dc9f77',
          option: '4',
          answers: 12,
        },
        {
          uuid: 'f8b08102-0702-4a8f-9d81-852988dc9f77',
          option: '5',
          answers: 7,
        },
      ],
    },
    {
      uuid: '1403d6dc-1b41-479b-93c1-08d481cd1ec8',
      question: 'Campo data',
      type: 'date',
      isRequired: false,
      answers: [
        {
          id: '8e13cafe-1',
          answer: '00/00/0000',
        },
        {
          id: '8e13cafe-2',
          answer: '00/00/0000',
        },
        {
          id: '8e13cafe-3',
          answer: '00/00/0000',
        },
        {
          id: '8e13cafe-4',
          answer: '00/00/0000',
        },
        {
          id: '8e13cafe-5',
          answer: '00/00/0000',
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(propagateCampaignGet(parseInt(params.id, 10)));
  }, []);

  const renderHeaderIconByType = () => {
    switch (campaignData.type) {
      case 'video':
        return (
          <CampaignIcon color="#4a90e2">
            <BiVideo />
          </CampaignIcon>
        );
      case 'research':
        return (
          <CampaignIcon color="#ffc542">
            <FaWpforms />
          </CampaignIcon>
        );
      case 'banner':
        return (
          <CampaignIcon color="#ff9ad5">
            <AiOutlineFileImage />
          </CampaignIcon>
        );
      case 'sms':
        return (
          <CampaignIcon color="#3dd598">
            <BiMessageDots />
          </CampaignIcon>
        );
      case 'email':
        return (
          <CampaignIcon color="#ff974a">
            <BsFillEnvelopeFill />
          </CampaignIcon>
        );
      case 'wifi':
        return (
          <CampaignIcon color="#E3262E">
            <BsWifi />
          </CampaignIcon>
        );
      default:
        return null;
    }
  };

  const renderResultBox = () => {
    const getContentByType = () => {
      switch (campaignData.type) {
        case 'sms':
          return (
            <CampaignBoxResultCard flex={1}>
              <span>
                SMS enviados
                <FaInfoCircle
                  data-tip="SMS enviados."
                  data-for="tooltip-campaign-results"
                />
              </span>
              <h2>3.060</h2>
            </CampaignBoxResultCard>
          );
        default:
          return (
            <>
              <CampaignBoxResultCard flex={1}>
                <span>
                  Pessoas alcançadas
                  <FaInfoCircle
                    data-tip="Pessoas alcançadas são referentes a CPF único. Uma mesma pessoa pode ser impactada por um banner, pesquisa, vídeo, sms ou e-mail."
                    data-for="tooltip-campaign-results"
                  />
                </span>
                <h2>3.060</h2>
              </CampaignBoxResultCard>
              <CampaignBoxResultCard flex={1}>
                <span>
                  Visualizações
                  <FaInfoCircle
                    data-tip="Soma total da visualização de campanhas exibidas para seus clientes conectados na Rede Wi-fi."
                    data-for="tooltip-campaign-results"
                  />
                </span>
                <h2>3.060</h2>
              </CampaignBoxResultCard>
              <CampaignBoxResultCard flex={2}>
                <span>
                  Horários
                  <FaInfoCircle
                    data-tip="Estimativa de horário que o usuário foi impactado pela campanha."
                    data-for="tooltip-campaign-results"
                  />
                </span>
                <ChartPeakhours data={campaignData.reportData.peakhours} />
              </CampaignBoxResultCard>
            </>
          );
      }
    };

    return (
      <CampaignBoxSection>
        <h2>Resultados</h2>
        <div className="campaign-box-section-content">{getContentByType()}</div>
        <ReactTooltip
          place="bottom"
          backgroundColor="#FFFFFF"
          textColor="#323232"
          border
          borderColor="#d1d1d1"
          className="tooltip-wrap"
          id="tooltip-campaign-results"
        />
      </CampaignBoxSection>
    );
  };

  const renderDemographicData = () => {
    const getContentByType = () => {
      switch (campaignData.type) {
        case 'sms':
          return (
            <>
              <CampaignBoxDemographicCard flex={1}>
                <span>Gênero</span>
                <ChartGender data={campaignData.reportData.gender} />
              </CampaignBoxDemographicCard>
              <CampaignBoxDemographicCard flex={2}>
                <span>Faixa etária</span>
                <ChartAgeRange data={campaignData.reportData.ageRange} />
              </CampaignBoxDemographicCard>
            </>
          );
        default:
          return (
            <>
              <CampaignBoxDemographicCard flex={1}>
                <span>Gênero</span>
                <ChartGender data={campaignData.reportData.gender} />
              </CampaignBoxDemographicCard>
              <CampaignBoxDemographicCard flex={1}>
                <span>Faixa etária</span>
                <ChartAgeRange data={campaignData.reportData.ageRange} />
              </CampaignBoxDemographicCard>
              <CampaignBoxDemographicCard flex={1}>
                <span>Dispositivos</span>
                <ChartDevices data={campaignData.reportData.devices} />
              </CampaignBoxDemographicCard>
            </>
          );
      }
    };

    return (
      <CampaignBoxSection>
        <h2>
          Dados demográficos
          <FaInfoCircle
            data-tip="Perfil de pessoas no relatório tem base na segmentação da campanha."
            data-for="tooltip-campaign-results"
          />
        </h2>
        <div className="campaign-box-section-content">{getContentByType()}</div>
      </CampaignBoxSection>
    );
  };

  const renderResearchFormItem = (item, index) => {
    const getIconByType = (type) => {
      switch (type) {
        case 'check':
          return <BsCheckBox />;
        case 'radio':
          return <MdRadioButtonChecked />;
        case 'text':
          return <MdSortByAlpha />;
        case 'number':
          return <TiSortNumerically />;
        case 'range':
          return <AiOutlineStar />;
        case 'date':
          return <BiCalendarAlt />;
        default:
          return null;
      }
    };
    const getChartByType = (data) => {
      switch (data.type) {
        case 'check':
          return <ChartCheckboxType data={data} />;
        case 'radio':
          return <ChartRadioboxType data={data} />;
        case 'text':
          return <ChartTextType data={data} />;
        case 'number':
          return <ChartNumberType data={data} />;
        case 'range':
          return <ChartRangeType data={data} />;
        case 'date':
          return <ChartDateType data={data} />;
        default:
          return null;
      }
    };
    const isMinimized = minimizedBoxes.includes(item.uuid);
    return (
      <ResearchItemFormBox key={item.uuid}>
        <button
          type="button"
          className="research-form-item-minimize"
          onClick={() => {
            if (isMinimized) {
              setMinimizedBoxes(minimizedBoxes.filter((i) => i !== item.uuid));
            } else {
              setMinimizedBoxes([...minimizedBoxes, item.uuid]);
            }
          }}
        >
          {!isMinimized && <IoIosArrowUp />}
          {isMinimized && <IoIosArrowDown />}
        </button>
        <div className="research-item-title">
          <b>{`${index + 1} -`}</b>
          <span>{item.question}</span>
        </div>
        <div className="research-item-type">
          Tipo de pesquisa:
          {getIconByType(item.type)}
        </div>
        {!isMinimized && getChartByType(item)}
      </ResearchItemFormBox>
    );
  };

  const renderResearchContent = () => {
    return (
      <CampaignBoxSection>
        <h2>Respostas do questionário</h2>
        {formResearchData.map(renderResearchFormItem)}
      </CampaignBoxSection>
    );
  };

  const renderContent = () => {
    switch (campaignData.type) {
      case 'email':
        return <ResultEmail campaign={campaignData} />;
      default:
        return (
          <CampaignBox>
            <CampaignBoxHeader>
              {renderHeaderIconByType()}
              <div className="campaign-box-header-content">
                <h2>{campaignData.name}</h2>
                <span>{`Status: ${campaignData.status}`}</span>
                <span>
                  {`Início da campanha: ${moment(campaignData.startDate).format(
                    'DD/MM/YYYY HH:mm'
                  )}`}
                </span>
                <span>
                  {`Fim da campanha: ${moment(campaignData.endDate).format(
                    'DD/MM/YYYY HH:mm'
                  )}`}
                </span>
              </div>
            </CampaignBoxHeader>
            {renderResultBox()}
            {renderDemographicData()}
            {campaignData.type === 'research' && renderResearchContent()}
          </CampaignBox>
        );
    }
  };

  return (
    <WrapPage>
      <Container>
        <PageTitle>
          <Link to="/propagate">
            <FaChevronLeft size={14} />
          </Link>
          Relatório da campanha
        </PageTitle>
        {!!campaignData && renderContent()}
      </Container>
    </WrapPage>
  );
}

CampaignResult.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default CampaignResult;
