import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';

import Theme from '~/styles/theme';
import Header from '~/components/Header';
import { useTenant } from '~/hooks/tenant';

import { Container, Pages, Body } from './styles';
import ProgressBarStepOnBoarding from './components/ProgressBarStepOnBoarding';

function Onboarding({ children }) {
  const { wizardProgress, backgroundImage } = useSelector(
    (state) => state.user
  );
  const [theme, setTheme] = useState(null);
  const { tenant, validateTenant } = useTenant();

  useEffect(() => {
    validateTenant();
  }, []);

  useEffect(() => {
    setTheme(Theme.getThemeByTenant(tenant));
  }, [tenant]);

  return (
    theme && (
      <ThemeProvider theme={theme}>
        <Container>
          <Header wizard />
          <ProgressBarStepOnBoarding progress={wizardProgress} />
          <Pages backgroundImage={backgroundImage}>
            <Body>{children}</Body>
          </Pages>
        </Container>
      </ThemeProvider>
    )
  );
}

Onboarding.propTypes = {
  children: Proptypes.element.isRequired,
};

export default Onboarding;
