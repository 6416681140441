import React from 'react';
import PropTypes from 'prop-types';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import pt from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';

import { Container, InputText } from './styles';

registerLocale('pt-BR', pt);

function Input({
  maxWidth,
  className,
  icon: Icon,
  placeholder,
  placeHolderAlign,
  value,
  disabled,
  responseFormat,
  maskFormat,
  onChange,
  ...rest
}) {
  return (
    <Container maxWidth={maxWidth} disabled={disabled} className={className}>
      {Icon && <Icon className="icon" />}
      <InputText
        selected={value ? moment(value).toDate() : null}
        disabled={disabled}
        placeholderText={placeholder}
        customInput={
          <ReactInputMask
            mask={maskFormat}
            value={value ? moment(value).format('DD/MM/YYYY HH:mm') : null}
          />
        }
        onChange={(date) => onChange(moment(date).format(responseFormat))}
        locale="pt-BR"
        dateFormat="dd/MM/yyyy HH:mm"
        showTimeSelect
        timeCaption="Hora"
        style={{
          width: '100%',
        }}
        {...rest}
      />
    </Container>
  );
}

Input.defaultProps = {
  maxWidth: null,
  placeHolderAlign: 'start',
  icon: null,
  placeholder: '',
  rest: null,
  value: null,
  disabled: false,
  onChange: () => {},
  responseFormat: 'YYYY-MM-DD',
  maskFormat: '99/99/9999 99:99',
};

Input.propTypes = {
  maxWidth: PropTypes.number,
  placeHolderAlign: PropTypes.string,
  className: PropTypes.string.isRequired,
  icon: PropTypes.func,
  placeholder: PropTypes.string,
  rest: PropTypes.shape(),
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  responseFormat: PropTypes.string,
  maskFormat: PropTypes.string,
};

export default Input;
