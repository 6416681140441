/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { FaCircle } from 'react-icons/fa';

import {
  Container,
  LegendBox,
  LegendBoxItem,
  LegendBoxItemLabel,
  LegendBoxItemPercentage,
} from './styles';

function AgeRange({ data }) {
  const colors = ['#FFC542', '#FF974A', '#0062FF', '#3DD598'];
  const renderChart = () => {
    const dataChart = {
      options: {
        chart: {
          zoom: { enabled: false },
          toolbar: { tools: { download: false } },
        },
        colors,
        fill: {
          colors,
          opacity: 1,
          type: 'solid',
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: data.map((item) => item.label),
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
      },
      series: [
        {
          name: 'Visitantes',
          data: data.map((item) => item.value),
        },
      ],
    };
    return (
      <Chart
        options={dataChart.options}
        series={dataChart.series}
        type="bar"
        width="100%"
        height="120px"
        className="custom-chart"
      />
    );
  };

  return (
    <Container>
      {renderChart()}
      <LegendBox>
        {data.map((item, index) => (
          <LegendBoxItem key={index}>
            <LegendBoxItemLabel>
              <FaCircle color={colors[index]} />
              {item.label}
            </LegendBoxItemLabel>
            <LegendBoxItemPercentage>{`${item.value}%`}</LegendBoxItemPercentage>
          </LegendBoxItem>
        ))}
      </LegendBox>
    </Container>
  );
}
AgeRange.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default AgeRange;
