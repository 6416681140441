import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #4a90e2;
  color: #4a90e2;
  background-color: #ffffff;
  padding: 0 40px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
      border: 1px solid #d1d1d1;
      color: #e0e0e0;
    `} {
  }
`;
