import styled from 'styled-components';

import InputComponent from '~/components/Input';
import InputDateComponent from '~/components/InputDate';
import Button from '~/components/Button';
import videoStep1 from '~/assets/images/video-step-1.png';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
`;

export const VideoTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  font-family: 'Quicksand';
  font-weight: 400;
  color: #0a0724;
  position: relative;
  a {
    position: absolute;
    left: -30px;
    display: flex;
    align-items: center;
    > svg {
      height: 22px;
      color: #fa6115;
    }
  }
`;

export const VideoSubtitle = styled.div`
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 400;
  color: #fa6115;
  margin-top: 5px;
`;

export const VideoLogo = styled.img.attrs({
  src: videoStep1,
  alt: 'Logo video step 1',
})`
  width: 293px;
  margin: 30px 0;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.span`
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  color: #0f2522;
  margin-top: 10px;
`;

export const Label = styled.label`
  font-family: Poppins;
  font-weight: bold;
  font-size: 14px;
  color: #323236;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
`;

export const InputName = styled(InputComponent)`
  width: 380px;
`;

export const InputData = styled(InputDateComponent)`
  width: 380px;
`;

export const ButtonNext = styled(Button)``;

export const ButtonCancel = styled(Button)`
  color: #fa6115;
  background-color: transparent;
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  border: 0;
`;
