import React from 'react';
import PropTypes from 'prop-types';

import { Container, NotebookBox } from './styles';

function WrapDesktop({ children }) {
  return (
    <Container>
      <NotebookBox>
        <div className="notebook-box-content">{children}</div>
      </NotebookBox>
    </Container>
  );
}

WrapDesktop.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WrapDesktop;
