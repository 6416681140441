import styled, { css } from 'styled-components';

export const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  min-width: 20px;
  max-width: fit-content;
  height: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .check-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid ${(props) => props.color || props.theme.colors.primary};
  }

  &:hover input ~ .check-box {
    background-color: #fff;
  }

  input:checked ~ .check-box {
    background-color: ${(props) => props.color || props.theme.colors.primary};
    border-color: ${(props) => props.color || props.theme.colors.primary};
  }

  .check-box:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .check-box:after {
    display: block;
  }

  .check-box:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 600 !important;
  color: ${(props) => props.theme.colors.black};
  margin-left: 25px;
`;
