/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  list: [],
  details: null,
};

export default function reducer(baseState = INITIAL_STATE, action) {
  return produce(baseState, (draftState) => {
    switch (action.type) {
      case types.SUPPORTS_SET_STATE: {
        Object.keys(action.payload.state).forEach((objectKey) => {
          draftState[objectKey] = action.payload.state[objectKey];
        });
        break;
      }
      default:
    }
  });
}
