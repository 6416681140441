import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiTarget } from 'react-icons/fi';
import { GrGroup } from 'react-icons/gr';

import history from '~/services/history';
import community from '~/assets/images/community.png';
import gettingStarted from '~/assets/images/gettingStarted.png';
import { userSetState } from '~/store/modules/user/actions';
import { Container, Row1, WrapIcon, Row2 } from './styles';

function Blank() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userSetState({ wizardProgress: 0, backgroundImage: null }));
  }, []);

  const renderRow1 = () => (
    <Row1>
      <h3>Que tal se comunicar com seus clientes?</h3>
      <span>Use a criatividade para aumentar as suas vendas.</span>
      <ul>
        <li>
          <WrapIcon color="#fcedd6">
            <FiTarget />
          </WrapIcon>
          <p>
            Antes de liberar a conexão grátis para os usuários, que tal exibir
            alguma oferta, desconto ou novo produto?
          </p>
        </li>
        <li>
          <WrapIcon color="#50e3c2">
            <GrGroup />
          </WrapIcon>
          <p>
            Você também pode enviar SMS ou e-mail para seus clientes
            cadastrados.
          </p>
        </li>
      </ul>
    </Row1>
  );

  const renderRow2 = () => (
    <Row2>
      <div className="row2-col-box" style={{ marginRight: 27 }}>
        <img src={community} alt="Em tempo real" />
        <h4>Em tempo real</h4>
        <p>
          <b>
            Comunicar-se com quem irá utilizar a sua rede WiFi no seu
            estabelecimento, exibindo:
          </b>
          <br />
          Banner, vídeo ou pesquisa de satisfação, antes de liberar a conexão
          grátis
        </p>
        <span className="span-normal" style={{ marginTop: 70 }}>
          Você pode usar a rede para promover seu negócio através de vídeo ou
          banner. Além disso, pode saber a opinião do seu cliente através de uma
          pesquisa.
        </span>
        <div className="row2-col-box-footer">
          <button
            type="button"
            onClick={() => history.push('/propagate/banner/step1')}
          >
            Banner
          </button>
          <button
            type="button"
            onClick={() => history.push('/propagate/video/step1')}
          >
            Vídeo
          </button>
          <button
            type="button"
            onClick={() => history.push('/propagate/research/step1')}
          >
            Pesquisa
          </button>
        </div>
      </div>
      <div className="row2-col-box">
        <img src={gettingStarted} alt="Mantenha contato" />
        <h4>Mantenha contato</h4>
        <p>
          <b>
            Comunicar-se com clientes que já estiveram no seu estabelecimento
            via:
          </b>
          <br />
          SMS e e-mail
        </p>
        <span className="span-danger" style={{ marginTop: 26 }}>
          Você pode usar a rede para promover seu negócio através de vídeo ou
          banner. Além disso, pode saber a opinião do seu cliente através de uma
          pesquisa.
        </span>
        <span className="span-normal" style={{ marginTop: 26 }}>
          Função disponível apenas se você já tiver cadastros de clientes na sua
          conta, ou seja, se já tiver veiculado alguma campanha.
        </span>
        <div className="row2-col-box-footer">
          <button disabled type="button">
            SMS
          </button>
          <button disabled type="button">
            E-mail
          </button>
        </div>
      </div>
    </Row2>
  );

  return (
    <Container>
      <h2>Divulgue seu estabelecimento</h2>
      {renderRow1()}
      <h2>Criar campanha</h2>
      {renderRow2()}
    </Container>
  );
}

export default Blank;
