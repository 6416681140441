import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 35px;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  .field-item-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 12px;
    .field-item-content-question {
      display: flex;
      align-items: center;
      b {
        font-family: Poppins;
        font-weight: bold;
        font-size: 12px;
      }
      input {
        display: flex;
        flex: 1;
        height: 32px;
        border-radius: 4px;
        border: solid 1px #d1d1d1;
        background-color: #fafafb;
        margin-left: 5px;
        font-family: Poppins;
        font-size: 10px;
        font-weight: normal;
        color: #0a0724;
        padding: 0 12px;
        outline: none;
        &::placeholder {
          font-family: Poppins;
          font-size: 10px;
          font-weight: normal;
          color: #0a0724;
        }
      }
    }
  }
  .field-item-footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    align-items: center;
    padding: 0 12px;
    border-top: 1px solid #d1d1d1;
    button.field-item-footer-action {
      width: 24px;
      height: 24px;
      border-radius: 3px;
      border: solid 0.6px #055cff;
      background-color: #ffffff;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #055cff;
      padding: 0;
      font-size: 12px;
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
    button.field-item-footer-required {
      background-color: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      font-family: Poppins;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.07px;
      color: #0a0724;
      > span {
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;
        max-height: 24px;
        border-radius: 3px;
        border: solid 0.6px #055cff;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
        > svg {
          font-size: 12px;
          color: #055cff;
        }
      }
      > span.is-required {
        border: solid 0.6px #055cff;
        background-color: #055cff;
        > svg {
          color: #ffffff;
        }
      }
    }
  }
`;

export const CheckTypeBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  > button {
    display: flex;
    padding: 0;
    width: max-content;
    align-items: center;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 10px;
    font-weight: bold;
    color: #0a0724;
    > svg {
      margin-right: 5px;
      font-size: 18px;
      color: #0a0724;
    }
  }
  .options-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    > svg {
      font-size: 18px;
      color: #0a0724;
    }
    > button {
      padding: 0;
      margin: 0;
      height: fit-content;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background-color: transparent;
      margin-left: 10px;
      > svg {
        color: #0a0724;
        font-size: 16px;
      }
      &:hover {
        opacity: 0.6;
      }
    }
    > input {
      display: flex;
      flex: 1;
      height: 32px;
      border-radius: 4px;
      border: solid 1px transparent;
      background-color: transparent;
      margin-left: 10px;
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
      padding: 0;
      outline: none;
      &::placeholder {
        font-family: Poppins;
        font-size: 10px;
        font-weight: normal;
        color: #0a0724;
      }
      &:focus {
        padding: 0 12px;
        border: solid 1px #d1d1d1;
        background-color: #fafafb;
      }
    }
  }
`;

export const RadioTypeBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  > button {
    display: flex;
    padding: 0;
    width: max-content;
    align-items: center;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 10px;
    font-weight: bold;
    color: #0a0724;
    > svg {
      margin-right: 5px;
      font-size: 18px;
      color: #0a0724;
    }
  }
  .options-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    > svg {
      font-size: 18px;
      color: #0a0724;
    }
    > button {
      padding: 0;
      margin: 0;
      height: fit-content;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background-color: transparent;
      margin-left: 10px;
      > svg {
        color: #0a0724;
        font-size: 16px;
      }
      &:hover {
        opacity: 0.6;
      }
    }
    > input {
      display: flex;
      flex: 1;
      height: 32px;
      border-radius: 4px;
      border: solid 1px transparent;
      background-color: transparent;
      margin-left: 10px;
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
      padding: 0;
      outline: none;
      &::placeholder {
        font-family: Poppins;
        font-size: 10px;
        font-weight: normal;
        color: #0a0724;
      }
      &:focus {
        padding: 0 12px;
        border: solid 1px #d1d1d1;
        background-color: #fafafb;
      }
    }
  }
`;

export const TextTypeBox = styled.div`
  display: flex;
  margin-top: 10px;
  input {
    display: flex;
    flex: 1;
    height: 32px;
    border-radius: 4px;
    padding: 0 12px;
    border: solid 1px #d1d1d1;
    background-color: #fafafb;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    color: #0a0724;
    outline: none;
    &::placeholder {
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
`;

export const NumberTypeBox = styled.div`
  display: flex;
  margin-top: 10px;
  input {
    display: flex;
    flex: 1;
    height: 32px;
    border-radius: 4px;
    padding: 0 12px;
    border: solid 1px #d1d1d1;
    background-color: #fafafb;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    color: #0a0724;
    outline: none;
    &::placeholder {
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
`;

export const RangeTypeBox = styled.div`
  display: flex;
  margin-top: 10px;
  svg {
    color: #0a0724;
    font-size: 30px;
    margin-right: 10px;
  }
`;

export const DateTypeBox = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  position: relative;
  width: 220px;
  input {
    display: flex;
    flex: 1;
    height: 32px;
    border-radius: 4px;
    padding: 0 12px;
    border: solid 1px #d1d1d1;
    background-color: #fafafb;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    color: #0a0724;
    outline: none;
    &::placeholder {
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
  svg {
    position: absolute;
    right: 12px;
  }
`;
