import { all, takeLatest, put } from 'redux-saga/effects';

import history from '../../../services/history';
import types from './types';
import {
  setIsLoading,
  setIsSuccess,
  setIsError,
} from '~/store/modules/loading/actions';
import { userSetState } from './actions';

export function* saveSetup() {
  yield put(setIsLoading(true));
  try {
    yield put(
      userSetState({ firstAccess: false, modalBusinessPropagate: true })
    );
    yield put(setIsSuccess(true, () => history.push('/')));
  } catch (error) {
    yield put(setIsError(true));
  }
}

export default all([takeLatest(types.USER_SETUP_SAVE, saveSetup)]);
