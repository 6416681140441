import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';

import GlobalStyled from './styles/global';
import Bootstrap from './Bootstrap';

import { store, persistor } from './store';
import history from './services/history';
import AppProvider from './hooks';
import Loading from '~/components/Loading';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <GlobalStyled />
          <AppProvider>
            <Bootstrap />
          </AppProvider>
        </Router>
        <Loading />
      </PersistGate>
    </Provider>
  );
}

export default App;
