/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  callback: () => {},
};

export default function reducer(baseState = INITIAL_STATE, action) {
  return produce(baseState, (draftState) => {
    switch (action.type) {
      case types.SET_IS_LOADING: {
        draftState.isLoading = action.payload.isLoading;
        draftState.callback = () => {};
        break;
      }
      case types.SET_IS_SUCCESS: {
        draftState.isSuccess = action.payload.isSuccess;
        draftState.callback = action.payload.callback;
        break;
      }
      case types.SET_IS_ERROR: {
        draftState.isError = action.payload.isError;
        draftState.callback = action.payload.callback;
        break;
      }
      default:
    }
  });
}
