import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  min-height: 60px;

  display: flex;
  align-items: center;
`;

export const TextArea = styled.textarea.attrs({
  rows: 6,
})`
  padding: 16px;
  flex: 1;
  background: transparent;
  border: 0;
  resize: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;

  &::placeholder {
    color: #d1d1d1;
  }
`;
