import styled from 'styled-components';
import smartphone from '~/assets/images/smartphone.png';

export const Container = styled.div`
  width: 350px;
  height: 658px;
  background-image: url(${smartphone});
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  top: 91px;
  left: 41px;
  right: 39px;
  bottom: 96px;
  display: flex;
  flex-direction: column;
`;
