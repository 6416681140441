import React from 'react';
import PropTypes from 'prop-types';

import { Container, TextArea } from './styles';

function TextAreaComponent({ className, ...rest }) {
  return (
    <Container className={className}>
      <TextArea {...rest} />
    </Container>
  );
}

TextAreaComponent.defaultProps = {};

TextAreaComponent.propTypes = {
  className: PropTypes.string.isRequired,
};

export default TextAreaComponent;
