import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { FaChevronLeft } from 'react-icons/fa';

import history from '~/services/history';
import bgtemplate1 from './images/template1.svg';
import bgtemplate2 from './images/template2.svg';
import cafeteria from '~/assets/images/avatar/cafeteria.svg';
import GroupButtons from '~/components/GroupButtons';
import WrapDesktop from './components/Wrap/Desktop';
import WrapSmartphone from './components/Wrap/Smartphone';
import Template1 from './components/Template1';
import Template2 from './components/Template2';
import {
  WrapPage,
  Container,
  VideoTitle,
  VideoSubtitle,
  ContentBox,
  InputWrap,
  ButtonNext,
  ButtonCancel,
  Info,
  TemplateButtonsBox,
  TemplateButton,
  TemplateEditorBox,
} from './styles';

function Step2() {
  const [template, setTemplate] = useState('');
  const [viewMode, setViewMode] = useState('desktop');
  const [templateConfig, setTemplateConfig] = useState({
    profilePic: cafeteria,
    title: 'Inserir título',
    styleTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      textAlign: 'center',
      fontFamily: 'Poppins',
      textDecoration: 'none',
    },
    subtitle: 'Inserir subtítulo',
    styleSubtitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      textAlign: 'left',
      fontFamily: 'Poppins',
      textDecoration: 'none',
    },
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
    styleContent: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      textAlign: 'justify',
      fontFamily: 'Poppins',
      textDecoration: 'none',
    },
    contentImage: '',
    actionLabel: '',
    actionLink: 'https://google.com',
    actionColor: '#e3262e',
  });

  const renderTemplateButtonsBox = () => (
    <TemplateButtonsBox>
      <Info>Escolha o template para a sua campanha de e-mail.</Info>
      <div className="template-buttons-box">
        <div className="template-buttons-item">
          <TemplateButton
            onClick={() => setTemplate('template1')}
            image={bgtemplate1}
          />
          <span>Template 1</span>
        </div>
        <div className="template-buttons-item">
          <TemplateButton
            onClick={() => setTemplate('template2')}
            image={bgtemplate2}
          />
          <span>Template 2</span>
        </div>
      </div>
    </TemplateButtonsBox>
  );

  const renderTemplateEditorsBox = () => (
    <TemplateEditorBox>
      <div className="template-editor-box-viewmode">
        <GroupButtons
          label="Modo de visualização:"
          options={[
            { label: 'Desktop', value: 'desktop' },
            { label: 'Mobile', value: 'mobile' },
          ]}
          onChange={setViewMode}
          value={viewMode}
        />
      </div>
      <div className="template-editor-box-device">
        {viewMode === 'desktop' && (
          <WrapDesktop>
            {template === 'template1' && (
              <Template1
                mode={viewMode}
                value={templateConfig}
                onChange={setTemplateConfig}
              />
            )}
            {template === 'template2' && (
              <Template2
                mode={viewMode}
                value={templateConfig}
                onChange={setTemplateConfig}
              />
            )}
          </WrapDesktop>
        )}
        {viewMode === 'mobile' && (
          <WrapSmartphone>
            {template === 'template1' && (
              <Template1
                mode={viewMode}
                value={templateConfig}
                onChange={setTemplateConfig}
              />
            )}
            {template === 'template2' && (
              <Template2
                mode={viewMode}
                value={templateConfig}
                onChange={setTemplateConfig}
              />
            )}
          </WrapSmartphone>
        )}
      </div>
    </TemplateEditorBox>
  );

  return (
    <WrapPage>
      <Container>
        <VideoTitle>
          <Link to="/propagate/email/step1">
            <FaChevronLeft size={14} />
          </Link>
          E-mail
        </VideoTitle>
        <VideoSubtitle>PASSO 2/3</VideoSubtitle>
        <ContentBox>
          {!template && renderTemplateButtonsBox()}
          {!!template && renderTemplateEditorsBox()}
        </ContentBox>
        <InputWrap style={{ marginBottom: 30 }}>
          <ButtonNext
            title="Avançar"
            onClick={() => history.push('/propagate/email/step3')}
          />
          <ButtonCancel
            title="Cancelar"
            onClick={() => history.push('/propagate')}
          />
        </InputWrap>
      </Container>
    </WrapPage>
  );
}

export default withTheme(Step2);
