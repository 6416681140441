/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001'
      : 'https://demo0159905.mockable.io/apolo-pme',
});

export default api;
