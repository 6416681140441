/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { FaChevronLeft, FaRegCalendar, FaInfoCircle } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import history from '~/services/history';
import SmartphonePreview from '~/components/SmartphonePreview';

import {
  WrapPage,
  Container,
  VideoTitle,
  VideoSubtitle,
  ContentBox,
  FormBox,
  PreviewBox,
  Info,
  PreviewDescription,
  PreviewDescriptionIcon,
  PreviewDescriptionContent,
  SmartphoneContent,
  Radiobox,
  InputWrap,
  ButtonNext,
  ButtonCancel,
  InputMask,
  InputData,
} from './styles';

function Step2() {
  const [telephone, setTelephone] = useState('');
  const [smsType, setSmsType] = useState('scheduled');
  const [initialDate, setInitialDate] = useState(null);

  return (
    <WrapPage>
      <Container>
        <VideoTitle>
          <Link to="/propagate/sms/step2">
            <FaChevronLeft size={14} />
          </Link>
          SMS
        </VideoTitle>
        <VideoSubtitle>PASSO 3/3</VideoSubtitle>
        <ContentBox>
          <FormBox>
            <Info>Configure o SMS que será enviado para os seus clientes.</Info>
            <div className="form-section-box">
              <span className="form-section-box-title">SMS de teste</span>
              <Info style={{ marginBottom: 5 }}>Insira o telefone</Info>
              <div className="form-section-box-sms">
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(00) 00000-0000"
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                />
                <button type="button">Enviar</button>
              </div>
            </div>
            <span className="form-section-title">Configurar envio</span>
            <div className="form-section-box">
              <Radiobox
                label="SMS programada"
                value={smsType === 'scheduled'}
                onChange={() => setSmsType('scheduled')}
              />
              <Info>Duração da campanha</Info>
              <div className="form-section-box-scheduled">
                <span>
                  Início do envio:
                  <FaInfoCircle
                    data-tip="Início do envio"
                    data-for="tooltip-provider"
                  />
                </span>
                <InputData
                  name="init-date"
                  type="text"
                  placeholder="99/99/9999 às 00:00h"
                  value={initialDate}
                  onChange={setInitialDate}
                  icon={FaRegCalendar}
                  responseFormat="YYYY-MM-DD HH:mm:ss"
                  disabled={smsType === 'submit'}
                />
              </div>
            </div>
            <div className="form-section-box">
              <Radiobox
                label="Enviar"
                value={smsType === 'submit'}
                onChange={() => setSmsType('submit')}
              />
            </div>
            <InputWrap style={{ marginTop: 70 }}>
              <ButtonNext
                title="Concluir"
                onClick={() => history.push('/propagate')}
              />
              <ButtonCancel title="Salvar como rascunho" onClick={() => {}} />
              <ButtonCancel
                title="Cancelar"
                onClick={() => history.push('/propagate')}
              />
            </InputWrap>
          </FormBox>
          <PreviewBox>
            <PreviewDescription>
              <PreviewDescriptionIcon>
                <AiFillEye />
              </PreviewDescriptionIcon>
              <PreviewDescriptionContent>
                Simulação de como seu cliente irá visualizar a página de acesso
              </PreviewDescriptionContent>
            </PreviewDescription>
            <SmartphonePreview className="tour-step-4">
              <SmartphoneContent>
                <div className="messagte-text-box">
                  <b>334-00</b>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas porttitor nec ligula et facilisis. Aliquam
                    ullamcorper bibendum leo, in tristique neque bibend
                  </p>
                </div>
                <div className="messagte-hour-box">10:00</div>
              </SmartphoneContent>
            </SmartphonePreview>
          </PreviewBox>
        </ContentBox>
        <ReactTooltip
          place="right"
          backgroundColor="#FFFFFF"
          textColor="#323232"
          border
          borderColor="#d1d1d1"
          className="tooltip-wrap"
          id="tooltip-provider"
        />
      </Container>
    </WrapPage>
  );
}

export default withTheme(Step2);
