import types from './types';

export function dashboardSetState(state) {
  return {
    type: types.DASHBOARD_SET_STATE,
    payload: { state },
  };
}

export function dashboardDataRequest() {
  return {
    type: types.DASHBOARD_DATA_REQUEST,
  };
}
