/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { FaChevronLeft } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import Tour from '~/components/Tour';
import { userSetState } from '~/store/modules/user/actions';
import { propagateCampaignSave } from '~/store/modules/propagate/actions';
import history from '~/services/history';
import SmartphonePreview from '~/components/SmartphonePreview';
import restaurant from '~/assets/images/avatar/restaurante.svg';
import Form from './components/Form';
import Preview from './components/Preview';

import {
  WrapPage,
  Container,
  ResearchTitle,
  ResearchSubtitle,
  InputWrap,
  ButtonNext,
  ButtonCancel,
  ContentBox,
  FormBox,
  PreviewBox,
  Info,
  PreviewResearch,
  PreviewTitle,
  PreviewLogo,
  PreviewForm,
  PreviewDescription,
  PreviewDescriptionIcon,
  PreviewDescriptionContent,
} from './styles';

function Step2({ theme }) {
  const dispatch = useDispatch();
  // TOUR STEPS
  const [tourIsOpen, setTourIsOpen] = useState(false);
  const tourSteps = [
    {
      selector: '.tour-step-1',
      content:
        'A página de campanha de pesquisa aparecerá com um onboarding e um box de pergunta múltipla escolha já criado. Após o onboarding o box sai da tela.',
    },
    {
      selector: '.tour-step-2',
      content: 'Aqui você pode selecionar itens para a campanha de pesquisa.',
    },
    {
      selector: '.tour-step-3',
      content: 'Inserir campo de múltipla escolha.',
    },
    {
      selector: '.tour-step-4',
      content: 'Inserir campo com seleção de resposta única.',
    },
    {
      selector: '.tour-step-5',
      content: 'Inserir campo de texto.',
    },
    {
      selector: '.tour-step-6',
      content: 'Inserir campo numérico.',
    },
    {
      selector: '.tour-step-7',
      content: 'Inserir campo com nota de 1 a 5.',
    },
    {
      selector: '.tour-step-8',
      content: 'Inserir campo de data.',
    },
    {
      selector: '.tour-step-9',
      content: 'Você pode personalizar a sua pesquisa.',
    },
    {
      selector: '.tour-step-10',
      content: 'Como editar a pergunta a ser respondida.',
    },
    {
      selector: '.tour-step-11',
      content: 'Adicionar alternativas.',
    },
    {
      selector: '.tour-step-12',
      content: 'Eliminar um bloco de perguntas.',
    },
    {
      selector: '.tour-step-13',
      content: 'Duplicar um bloco de perguntas.',
    },
    {
      selector: '.tour-step-14',
      content:
        'Alternar a ordem que seus clientes irão visualizar as perguntas.',
    },
    {
      selector: '.tour-step-15',
      content: 'E tornar a resposta de um bloco como obrigatória.',
    },
    {
      selector: '.tour-step-16',
      content:
        'Pronto! Depois que personalizar seus itens é só publicar a sua campanha de pesquisa.      ',
    },
  ];

  const [formItems, setFormItems] = useState([
    {
      uuid: '69b19a20-ad41-4bb4-91e9-8587f000e9f1',
      question: 'Campo check',
      type: 'check',
      options: [
        { uuid: 'a2403d75-0757-431f-88bb-5367cb7c9577', option: 'Check 1' },
        { uuid: '1d2d73a6-4c14-4718-a154-f58cc6c93fdf', option: 'Check 2' },
        { uuid: 'f8b08102-0702-4a8f-9d81-799988dc9f79', option: 'Check 3' },
      ],
      isRequired: false,
    },
    {
      uuid: 'd676b413-f6e2-4e7b-b5d2-fb9ce262a860',
      question: 'Campo radio',
      type: 'radio',
      options: [
        { uuid: 'a2403d75-0757-431f-88bb-5367cb7c9577', option: 'Radio 1' },
        { uuid: '1d2d73a6-4c14-4718-a154-f58cc6c93fdf', option: 'Radio 2' },
        { uuid: 'f8b08102-0702-4a8f-9d81-799988dc9f79', option: 'Radio 3' },
      ],
      isRequired: true,
    },
    {
      uuid: '8e13cafe-eb16-45f3-83c9-aa52f3118079',
      question: 'Campo texto',
      type: 'text',
      isRequired: true,
    },
    {
      uuid: 'a797d7bd-f22b-49f2-b1bd-e0bf6c265b5c',
      question: 'Campo número',
      type: 'number',
      isRequired: false,
    },
    {
      uuid: 'b99dc6cb-7795-4d7a-a200-0d142a482925',
      question: 'Campo nota',
      type: 'range',
      isRequired: false,
    },
    {
      uuid: '1403d6dc-1b41-479b-93c1-08d481cd1ec8',
      question: 'Campo data',
      type: 'date',
      isRequired: false,
    },
  ]);

  useEffect(() => {
    dispatch(userSetState({ wizardProgress: 100, backgroundImage: null }));
    setTimeout(() => setTourIsOpen(true), 500);
  }, []);

  return (
    <WrapPage>
      <Container>
        <ResearchTitle className="tour-step-1">
          <Link to="/propagate/research/step1">
            <FaChevronLeft size={14} />
          </Link>
          Pesquisa
        </ResearchTitle>
        <ResearchSubtitle>PASSO 2/2</ResearchSubtitle>
        <ContentBox>
          <FormBox>
            <Info>
              Configure a pesquisa que vai ser exibido quando seu cliente entrar
              no seu portal.
            </Info>
            <Form
              formItems={formItems}
              onChange={setFormItems}
              isInTourMode={tourIsOpen}
            />
            <InputWrap>
              <ButtonNext
                className="tour-step-16"
                title="Publicar"
                onClick={() => dispatch(propagateCampaignSave())}
              />
              <ButtonCancel
                title="Cancelar"
                onClick={() => history.push('/propagate')}
              />
            </InputWrap>
          </FormBox>
          <PreviewBox>
            <PreviewDescription>
              <PreviewDescriptionIcon>
                <AiFillEye />
              </PreviewDescriptionIcon>
              <PreviewDescriptionContent>
                Simulação de como seu cliente irá visualizar a página de acesso
              </PreviewDescriptionContent>
            </PreviewDescription>
            <SmartphonePreview overflowY="unset">
              <PreviewResearch>
                <PreviewTitle>Amelie Wifi</PreviewTitle>
                <PreviewLogo isDefault>
                  <img alt="Logo" src={restaurant} />
                </PreviewLogo>
              </PreviewResearch>
              <PreviewForm>
                <span>
                  Participe da pesquisa abaixo e ganhe 00 minutos de conexão
                </span>
                <Preview formItems={formItems} />
              </PreviewForm>
            </SmartphonePreview>
          </PreviewBox>
        </ContentBox>
      </Container>
      <Tour
        steps={tourSteps}
        isOpen={tourIsOpen}
        onRequestClose={() => setTourIsOpen(false)}
        closeWithMask={false}
        accentColor={theme.colors.primary}
        className=""
        startAt={0}
      />
    </WrapPage>
  );
}

Step2.propTypes = {
  theme: PropTypes.shape().isRequired,
};

export default withTheme(Step2);
