import types from './types';

export function userSetState(state) {
  return {
    type: types.USER_SET_STATE,
    payload: { state },
  };
}

export function userSetupSave() {
  return {
    type: types.USER_SETUP_SAVE,
  };
}
