import styled from 'styled-components';
import supportBg from '~/assets/images/support-bg.png';

import InputComponent from '~/components/Input';
import TextAreaComponent from '~/components/TextArea';
import ButtonComponent from '~/components/Button';
import DropZoneButtonComponent from '~/components/DropZoneButton';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
  background-image: url(${supportBg});
  background-repeat: no-repeat;
  background-size: 522px auto;
  background-position-x: 100%;
  background-position-y: 70px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > a {
    margin-right: 10px;
    svg {
      color: #fc5a5a;
      font-size: 20px;
    }
  }
  > h3 {
    font-size: 32px;
    font-weight: normal;
    font-family: Quicksand;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 537px;
  > label {
    font-family: Poppins;
    font-size: 14px;
    color: #323236;
    font-weight: bold;
  }
  > legend {
    font-family: Quicksand;
    font-size: 12px;
    color: #102622;
    font-weight: normal;
  }
`;

export const HolderBox = styled.div`
  height: 95px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ebebee;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 16px;
  .holder-icon {
    width: 57px;
    height: 57px;
    background-color: #fc5a5a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 500;
    font-family: Poppins;
    font-size: 16px;
    margin-right: 20px;
  }
  .holder-content {
    display: flex;
    flex-direction: column;
    b {
      color: #323236;
      font-weight: bold;
      font-family: Poppins;
      font-size: 16px;
    }
    span {
      color: #323236;
      font-weight: normal;
      font-family: Poppins;
      font-size: 12px;
      margin-top: 5px;
    }
  }
`;

export const DropZoneButton = styled(DropZoneButtonComponent)`
  margin-top: 15px;
  margin-bottom: 35px;
  background-color: rgba(74, 144, 226, 0.03);
`;

export const Input = styled(InputComponent)`
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const TextArea = styled(TextAreaComponent)`
  margin-top: 5px;
`;

export const Button = styled(ButtonComponent)`
  width: 190px;
  background-color: ${(props) => props.theme.colors.primary};
  color: #ffffff;
  margin-top: 40px;
  :disabled {
    background-color: #e0e0e0;
  }
`;

export const AttachmentBox = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 14px;
`;

export const AttachmentBoxItem = styled.li`
  display: flex;
  align-items: center;
  padding: 4px 0;
  > svg {
    color: #0a0724;
    font-size: 16px;
    margin-right: 5px;
  }
  > button {
    padding: 0;
    margin: 0;
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border: 0;
    background-color: transparent;
    > svg {
      color: #0a0724;
      font-size: 16px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  > span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
  }
`;
