import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;

export const ListItemIcon = styled.div`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  color: #ffffff;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const ListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;
`;

export const ListItemContentName = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #171725;
`;

export const ListItemContentEmail = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #92929d;
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid #d8d8d8;
`;

export const ShowMoreButton = styled.button.attrs({ type: 'button' })`
  width: 100%;
  border: 0;
  background-color: transparent;
  color: #0062ff;
  font-size: 15px;
  font-weight: normal;
  margin-top: 15px;
`;
