import acougue from '~/assets/images/avatar/acougue.svg';
import bar from '~/assets/images/avatar/bar.svg';
import barbearia from '~/assets/images/avatar/barbearia.svg';
import cafeteria from '~/assets/images/avatar/cafeteria.svg';
import casaNoturna from '~/assets/images/avatar/casaNoturna.svg';
import cervejaria from '~/assets/images/avatar/cervejaria.svg';
import concessionariaDeAutomoveis from '~/assets/images/avatar/concessionariaDeAutomoveis.svg';
import consultorioMedico from '~/assets/images/avatar/consultorioMedico.svg';
import dentistaEConsultorioOdontologico from '~/assets/images/avatar/dentistaEConsultorioOdontologico.svg';
import doceria from '~/assets/images/avatar/doceria.svg';
import estudioDeTatuagemEPiercing from '~/assets/images/avatar/estudioDeTatuagemEPiercing.svg';
import foodTruck from '~/assets/images/avatar/foodTruck.svg';
import lanchonete from '~/assets/images/avatar/lanchonete.svg';
import livraria from '~/assets/images/avatar/livraria.svg';
import localParaEventosEApresentacoes from '~/assets/images/avatar/localParaEventosEApresentacoes.svg';
import loja from '~/assets/images/avatar/loja.svg';
import lojaDeConveniencia from '~/assets/images/avatar/lojaDeConveniencia.svg';
import mercearia from '~/assets/images/avatar/mercearia.svg';
import outro from '~/assets/images/avatar/outro.svg';
import padaria from '~/assets/images/avatar/padaria.svg';
import restaurante from '~/assets/images/avatar/restaurante.svg';
import salaoDeBeleza from '~/assets/images/avatar/salaoDeBeleza.svg';
import servicoAutomotivo from '~/assets/images/avatar/servicoAutomotivo.svg';
import supermercado from '~/assets/images/avatar/supermercado.svg';

export default {
  acougue,
  bar,
  barbearia,
  cafeteria,
  casaNoturna,
  cervejaria,
  concessionariaDeAutomoveis,
  consultorioMedico,
  dentistaEConsultorioOdontologico,
  doceria,
  estudioDeTatuagemEPiercing,
  foodTruck,
  lanchonete,
  livraria,
  localParaEventosEApresentacoes,
  loja,
  lojaDeConveniencia,
  mercearia,
  outro,
  padaria,
  restaurante,
  salaoDeBeleza,
  servicoAutomotivo,
  supermercado,
};
