import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .custom-chart {
    min-height: unset !important;
    .apexcharts-canvas {
      > svg {
        margin-top: -15px !important;
      }
    }
  }
`;

export const LegendBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-top: -20px;
`;

export const LegendBoxItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const LegendBoxItemLabel = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.76;
  letter-spacing: 0.13px;
  color: #44444f;
  svg {
    font-size: 15px;
    margin-right: 10px;
  }
`;

export const LegendBoxItemPercentage = styled.div`
  display: flex;
  flex: 1;
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.76;
  letter-spacing: normal;
  color: #44444f;
`;
