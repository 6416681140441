/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Proptypes from 'prop-types';
import { Route } from 'react-router-dom';

import DashboardLayout from '~/pages/Dashboard';
import OnboardingLayout from '~/pages/Onboarding';

export default function RouteWrapper({
  component: Component,
  layout,
  ...rest
}) {
  let Layout = null;

  switch (layout) {
    case 'dashboard': {
      Layout = DashboardLayout;
      break;
    }
    case 'wizard': {
      Layout = OnboardingLayout;
      break;
    }
    default:
      break;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  layout: Proptypes.string,
  component: Proptypes.oneOfType([Proptypes.element, Proptypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  layout: 'dashboard',
};
