import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .progress {
    background: ${(props) => props.theme.colors.steps.progress};
    width: ${(props) => props.progress || 0}%;
    height: 4px;
  }
`;
