/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';

import Theme from '../../styles/theme';

import {
  Container,
  ButtonClose,
  Title,
  Message,
  ButtonConfirm,
} from './styles';

function Confirm({
  theme = Theme.getThemeByTenant(''),
  title,
  message,
  fontFamily,
  fontWeight,
  close,
  button: { label, onClick },
}) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <Container theme={theme}>
          {close && (
            <ButtonClose onClick={onClose} theme={theme}>
              <IoMdClose />
            </ButtonClose>
          )}
          <AiOutlineExclamationCircle className="icon-modal" />
          <Title theme={theme} fontFamily={fontFamily} fontWeight={fontWeight}>
            {title}
          </Title>
          <Message
            theme={theme}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <ButtonConfirm
            title={label}
            theme={theme}
            onClick={() => {
              onClick();
              onClose();
            }}
          />
        </Container>
      );
    },
  });
}

Confirm.defaultProps = {
  fontFamily: '',
  fontWeight: 'bold',
  close: true,
};

Confirm.propTypes = {
  theme: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
  close: PropTypes.bool,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

export default Confirm;
