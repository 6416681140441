import styled from 'styled-components';

import InputComponent from '~/components/Input';
import CheckToggleComponent from '~/components/CheckToggle';
import InputDateComponent from '~/components/InputDate';
import Button from '~/components/Button';
import CheckboxComponent from '~/components/Checkbox';
import videoStep1 from '~/assets/images/video-step-1.png';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
`;

export const VideoTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  font-family: 'Quicksand';
  font-weight: 400;
  color: #0a0724;
  position: relative;
  a {
    position: absolute;
    left: -30px;
    display: flex;
    align-items: center;
    > svg {
      height: 22px;
      color: #fa6115;
    }
  }
`;

export const VideoSubtitle = styled.div`
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 400;
  color: #fa6115;
  margin-top: 5px;
`;

export const VideoLogo = styled.img.attrs({
  src: videoStep1,
  alt: 'Logo video step 1',
})`
  width: 293px;
  margin: 30px 0;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  > .input-wrap-body {
    display: flex;
    flex-direction: column;
    > span {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.86;
      color: #0f2522;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
      }
    }
    > .toggle-input-box {
      width: 440px;
      height: 48px;
      border-radius: 4px;
      border: solid 1px #d1d1d1;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      > span {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.07px;
        color: #0a0724;
        display: flex;
        align-items: center;
        svg {
          margin-left: 10px;
        }
      }
      &[disabled] {
        opacity: 0.7;
      }
    }
    > .toggle-slider-box {
      width: 440px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      span.toggle-slider-legend {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.07px;
        color: #0a0724;
        white-space: nowrap;
      }
      span.slider-component {
        margin: 0 15px;
        color: #adcdff !important;
        .MuiSlider-thumb {
          background-color: #0062ff !important;
          border: 1px solid #0062ff !important;
          width: 18px;
          height: 18px;
          margin-top: -9px;
          margin-left: -9px;
        }
        &.Mui-disabled {
          opacity: 0.7;
          color: #d1d1d1 !important;
          .MuiSlider-thumb {
            background-color: #d1d1d1 !important;
            border: 1px solid #d1d1d1 !important;
            width: 18px;
            height: 18px;
            margin-top: -9px;
            margin-left: -9px;
          }
        }
      }
    }
    > .toggle-checklist-box {
      width: 500px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .toggle-checklist-box-item {
        height: 48px;
        padding: 0 10px;
        border-radius: 4px;
        border: solid 1px #d1d1d1;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        margin: 0 20px 20px 0;
      }
    }
    > .toggle-message-box {
      width: 526px;
      height: 111px;
      border-radius: 4px;
      box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      border-left: 4px solid #fc5a5a;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;
      h2 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.44;
        color: #0f2522;
      }
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.5;
        color: #0f2522;
      }
    }
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.span`
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  color: #0f2522;
  margin-top: 10px;
`;

export const Label = styled.label`
  font-family: Poppins;
  font-weight: bold;
  font-size: 14px;
  color: #323236;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
`;

export const SubLabel = styled.label`
  opacity: 0.53;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: 0.1px;
  text-align: left;
  color: #323236;
`;

export const InputName = styled(InputComponent)`
  width: 380px;
`;

export const InputData = styled(InputDateComponent)`
  width: 380px;
`;

export const CheckToggle = styled(CheckToggleComponent)`
  .react-custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: #0062ff;
  }
`;

export const ButtonNext = styled(Button)``;

export const ButtonCancel = styled(Button)`
  color: #fa6115;
  background-color: transparent;
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  border: 0;
`;

export const SectionSeparator = styled.div`
  display: flex;
  flex-direction: column;
  b {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #323236;
  }
  span {
    opacity: 0.53;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: 0.1px;
    color: #323236;
    margin-top: 10px;
  }
`;

export const Checkbox = styled(CheckboxComponent).attrs({
  color: '#0a0724',
})`
  height: 15px;
  .check-box {
    height: 15px;
    width: 15px;
  }
  .check-label {
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: normal !important;
    letter-spacing: 0.07px !important;
    color: #0a0724 !important;
    white-space: nowrap;
  }
  .check-box:after {
    left: 4px;
    top: 0px;
    width: 3px;
    height: 8px;
  }
`;
