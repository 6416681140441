import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Modal from '~/components/Modal';
import { getInitialsFromName } from '~/utils';
import { visitorsSetState } from '~/store/modules/visitors/actions';

import {
  ModalHeader,
  ModalBody,
  IconBox,
  UserBox,
  Icon,
  UserBoxName,
  UserBoxDocument,
  UserBoxEmail,
  UserBoxPhone,
  ButtonBlockAccess,
  ModalBodyTitle,
  ModalBodyList,
  ModalBodyListItem,
} from './styles';
import confirm from '~/components/Confirm';

function ModalVisitorDetails({ theme }) {
  const dispatch = useDispatch();
  const { visitorDetails } = useSelector((state) => state.visitors);
  const colorList = ['#FF9AD5', '#50B5FF', '#3DD598', '#92929D'];

  const handleBlockUser = (user) => {
    dispatch(
      visitorsSetState({
        visitorDetails: { ...visitorDetails, status: !visitorDetails.status },
      })
    );
  };

  const handleModalConfirmBlockUser = () => {
    dispatch(visitorsSetState({ visitorDetails: null }));
    confirm({
      theme,
      title: `${visitorDetails.status ? 'Bloquear' : 'Desbloquear'} usuário`,
      fontFamily: 'Quicksand',
      fontWeight: 'normal',
      message: `Você está ${
        visitorDetails.status ? 'bloqueando' : 'desbloqueando'
      } o acesso de <b>${visitorDetails.firstName}</b>. Deseja continuar?`,
      button: {
        label: 'Sim, eu quero',
        onClick: () => {
          handleBlockUser(visitorDetails);
        },
      },
    });
  };

  return (
    !!visitorDetails && (
      <Modal
        visible
        width="580"
        onClose={() => {
          dispatch(visitorsSetState({ visitorDetails: null }));
        }}
      >
        <ModalHeader>
          <IconBox>
            <Icon
              color={colorList[Math.floor(Math.random() * colorList.length)]}
            >
              {getInitialsFromName(
                `${visitorDetails.firstName} ${visitorDetails.lastName}`
              )}
            </Icon>
          </IconBox>
          <UserBox>
            <UserBoxName>{`${visitorDetails.firstName} ${visitorDetails.lastName}`}</UserBoxName>
            <UserBoxDocument>
              <b>CPF/Passaporte: </b>
              {visitorDetails.document}
            </UserBoxDocument>
            <UserBoxEmail>
              <b>E-mail: </b>
              {visitorDetails.email}
            </UserBoxEmail>
            <UserBoxPhone>
              <b>Celular: </b>
              {visitorDetails.phone}
            </UserBoxPhone>
            <ButtonBlockAccess
              title={
                visitorDetails.status
                  ? 'Bloquear usuário'
                  : 'Desbloquear usuário'
              }
              onClick={handleModalConfirmBlockUser}
            />
          </UserBox>
        </ModalHeader>
        <ModalBody>
          <ModalBodyTitle>Histórico</ModalBodyTitle>
          <ModalBodyList>
            {visitorDetails.history.map((item) => (
              <ModalBodyListItem key={item.id}>
                <span>{moment(item.date).format('DD/MM/YYYY [-] HH:mm')}</span>
                <span>{item.description}</span>
              </ModalBodyListItem>
            ))}
          </ModalBodyList>
        </ModalBody>
      </Modal>
    )
  );
}

ModalVisitorDetails.propTypes = {
  theme: PropTypes.shape().isRequired,
};

export default withTheme(ModalVisitorDetails);
