import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-top: 10px;
  .custom-chart {
    .apexcharts-canvas {
      > svg {
        margin-top: -7px !important;
        margin-left: -20px !important;
      }
    }
  }
`;

export const LegendBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-top: 10px !important;
`;

export const LegendBoxItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const LegendBoxItemLabel = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.76;
  letter-spacing: 0.13px;
  color: #44444f;
  svg {
    font-size: 15px;
    margin-right: 10px;
  }
`;
