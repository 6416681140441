import React from 'react';
import PropTypes from 'prop-types';
import { FiTarget } from 'react-icons/fi';
import { GrGroup } from 'react-icons/all';
import Modal from '~/components/Modal';
import history from '~/services/history';
import {
  ModalForm,
  ModalHeader,
  ModalHeaderDescription,
  ModalHeaderTitle,
  ModalFormHeader,
  ModalFormBody,
  Row,
  Column,
  WrapperImage,
  WrapperButtons,
  ButtonCampaign,
} from './styles';

function ModalConfigureEmail({ onClose }) {
  return (
    <Modal visible width="930" height="495" onClose={onClose}>
      <ModalHeader>
        <ModalHeaderTitle>Criar campanha</ModalHeaderTitle>
        <ModalHeaderDescription>
          Criar campanha Você pode usar a rede para promover o seu negócio,
          através de vídeo ou banner, ou ainda realizar uma pesquisa de
          satisfação.
        </ModalHeaderDescription>
      </ModalHeader>
      <ModalForm>
        <ModalFormHeader>
          <Row>
            <Column>
              <WrapperImage color="#fcedd6">
                <FiTarget />
              </WrapperImage>
              <span>Comunique-se com quem irá utilizar sua rede</span>
            </Column>
            <Column>
              <WrapperImage color="#50e3c2">
                <GrGroup />
              </WrapperImage>
              <span>
                Comunique-se com quem já esteve em seu estabelecimento
              </span>
            </Column>
          </Row>
        </ModalFormHeader>
        <ModalFormBody>
          <Row>
            <Column direction="column">
              <p>
                Você pode usar a rede para promover seu negócio através de vídeo
                ou banner. Além disso, pode saber a opinião do seu cliente
                através de uma pesquisa.
              </p>
              <WrapperButtons>
                <Row>
                  <Column>
                    <ButtonCampaign
                      title="Banner"
                      onClick={() => history.push('/propagate/banner/step1')}
                    />
                  </Column>
                  <Column style={{ marginLeft: 17 }}>
                    <ButtonCampaign
                      title="Vídeo"
                      onClick={() => history.push('/propagate/video/step1')}
                    />
                  </Column>
                  <Column style={{ marginLeft: 17 }}>
                    <ButtonCampaign
                      title="Pesquisa"
                      onClick={() => history.push('/propagate/research/step1')}
                    />
                  </Column>
                </Row>
              </WrapperButtons>
            </Column>
            <Column direction="column">
              <p>
                Através dos cadastros dos clientes que já utilizaram a rede,
                você pode enviar email ou SMS com promoções, descontos ou
                novidades.
              </p>
              <WrapperButtons>
                <Row>
                  <Column>
                    <ButtonCampaign
                      title="SMS"
                      onClick={() => history.push('/propagate/sms/step1')}
                    />
                  </Column>
                  <Column style={{ marginLeft: 17 }}>
                    <ButtonCampaign
                      title="E-mail"
                      onClick={() => history.push('/propagate/email/step1')}
                    />
                  </Column>
                </Row>
              </WrapperButtons>
            </Column>
          </Row>
        </ModalFormBody>
      </ModalForm>
    </Modal>
  );
}

ModalConfigureEmail.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalConfigureEmail;
