import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content } from './styles';

function SmartphonePreview({
  children,
  className,
  classNameContent,
  overflowY,
}) {
  return (
    <Container className={className}>
      <Content style={{ overflowY }} className={classNameContent}>
        {children}
      </Content>
    </Container>
  );
}

SmartphonePreview.defaultProps = {
  className: '',
  classNameContent: '',
  overflowY: 'auto',
};

SmartphonePreview.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  overflowY: PropTypes.string,
};

export default SmartphonePreview;
