import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiPaperclip } from 'react-icons/fi';

import { getInitialsFromName } from '~/utils';

import {
  WrapPage,
  Container,
  Header,
  Content,
  Input,
  TextArea,
  HolderBox,
  Button,
  DropZoneButton,
  AttachmentBox,
  AttachmentBoxItem,
} from './styles';

function Details() {
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);

  const renderHeader = () => (
    <>
      <Link to="/support">
        <IoIosArrowBack />
      </Link>
      <h3>Ainda tem alguma dúvida?</h3>
    </>
  );

  const renderContent = () => (
    <Content>
      <label>Dados do titular da conta</label>
      <HolderBox>
        <div className="holder-icon">
          {getInitialsFromName('Nome do usuário')}
        </div>
        <div className="holder-content">
          <b>Nome do usuário</b>
          <span>nome@gmail.com</span>
          <span>(00) 00000-0000</span>
        </div>
      </HolderBox>
      <label>Assunto</label>
      <Input
        name="inputNomeRede"
        type="text"
        placeholder="Insira aqui o assunto"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <label>Anexar</label>
      <legend>Adicione até 5 arquivos de imagem (PNG, JPG, GIF, PDF)</legend>
      {attachments.length > 0 && (
        <AttachmentBox>
          {attachments.map((item, index) => (
            <AttachmentBoxItem key={`${index + 1}-${item.name}`}>
              <FiPaperclip />
              <button
                type="button"
                onClick={() => {
                  setAttachments(
                    attachments.filter((att, attIndex) => attIndex !== index)
                  );
                }}
              >
                <FaRegTrashAlt />
              </button>
              <span>{item.name}</span>
            </AttachmentBoxItem>
          ))}
        </AttachmentBox>
      )}
      <DropZoneButton
        multiple
        title="Anexar"
        onChange={(files) => {
          const newFiles = [...attachments, ...files];
          setAttachments(newFiles.filter((att, attIndex) => attIndex < 5));
        }}
        accept=".jpeg,.jpg,.png,.gif,.pdf"
        disabled={attachments.length >= 5}
      />
      <label>Descrição</label>
      <TextArea
        name="inputNomeRede"
        placeholder="Insira aqui a descrição"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button title="Enviar" disabled={!subject || !description} />
    </Content>
  );

  return (
    <WrapPage>
      <Container>
        <Header>{renderHeader()}</Header>
        {renderContent()}
      </Container>
    </WrapPage>
  );
}

Details.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
};

export default Details;
