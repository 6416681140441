import { all, takeLatest, put, call } from 'redux-saga/effects';
import moment from 'moment';

import types from './types';
import { headerSetState } from './actions';

import api from '~/services/api';

export function* notificationsRequest() {
  try {
    const { data } = yield call(api.get, 'notifications');
    const newDate = new Date();

    yield put(
      headerSetState({
        notifications: data.map((item) => ({
          ...item,
          past:
            moment.duration(moment(newDate).diff(moment(item.date))).asDays() >=
            1,
        })),
      })
    );
  } catch (error) {
    //
  }
}

export default all([
  takeLatest(types.HEADER_NOTIFICATIONS_REQUEST, notificationsRequest),
]);
