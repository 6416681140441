/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { FaCircle } from 'react-icons/fa';

import {
  Container,
  LegendBox,
  LegendBoxItem,
  LegendBoxItemLabel,
} from './styles';

function gender({ data }) {
  const colors = ['#FC5A5A', '#FFC542', '#4A90E2'];
  const renderChart = () => {
    const dataChart = {
      series: data.map((item) => item.value),
      options: {
        chart: {
          zoom: { enabled: false },
          toolbar: { tools: { download: false } },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        fill: {
          colors,
          opacity: 1,
          type: 'solid',
        },
        legend: {
          show: false,
        },
        labels: data.map((item) => item.label),
      },
    };
    return (
      <Chart
        options={dataChart.options}
        series={dataChart.series}
        type="donut"
        width={150}
        height={150}
        className="custom-chart"
      />
    );
  };

  return (
    <Container>
      {renderChart()}
      <LegendBox>
        {data.map((item, index) => (
          <LegendBoxItem key={index}>
            <LegendBoxItemLabel>
              <FaCircle color={colors[index]} />
              {item.label}
            </LegendBoxItemLabel>
          </LegendBoxItem>
        ))}
      </LegendBox>
    </Container>
  );
}
gender.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default gender;
