import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import GroupButtons from '../../../../components/GroupButtons';
import DashboardTitle from '../../../../components/DashboardTitle';
import DashboardCard from '../../../../components/DashboardCard';
// import AlertBox from '../../../../components/AlertBox';
import TotalAccess from './components/TotalAccess';
import ConnectionTime from './components/ConnectionTime';
import TopVisitors from './components/TopVisitors';
import InRealTime from './components/InRealTime';
import DataTrafficPerCustomer from './components/DataTrafficPerCustomer';
import Devices from './components/Devices';
import Peakhours from './components/Peakhours';
import AgeRange from './components/AgeRange';
import NetworkAccessByCustomers from './components/NetworkAccessByCustomers';
import Gender from './components/Gender';
import ModalBussinessPropagate from '~/pages/Dashboard/pages/Propagate/components/ModalBusinessPropagate';

import {
  dashboardDataRequest,
  dashboardSetState,
} from '../../../../store/modules/dashboard/actions';

import { userSetState } from '../../../../store/modules/user/actions';

import {
  Container,
  Row,
  Column,
  Header,
  // AlertMessage,
  // ButtonConfigure,
} from './styles';

function Main() {
  const dispatch = useDispatch();
  const { filter, dashboardData } = useSelector((state) => state.dashboard);
  const { modalBusinessPropagate } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(dashboardDataRequest());
  }, [filter]);

  const renderHeader = () => (
    <DashboardTitle title="Visão geral">
      <GroupButtons
        label="Visualizar últimos:"
        options={[
          { label: '7 dias', value: 7 },
          { label: '30 dias', value: 30 },
        ]}
        onChange={(period) => {
          dispatch(dashboardSetState({ filter: { ...filter, period } }));
        }}
        value={filter.period}
      />
    </DashboardTitle>
  );

  const renderRow1 = () => {
    const {
      totalAccess,
      connectionTime,
      networkAccessByCustomers,
    } = dashboardData;
    return (
      <Row>
        <Column spaceRight={24} minWidth={500}>
          <DashboardCard title="Clientes que acessaram sua rede" flex={2}>
            <NetworkAccessByCustomers data={networkAccessByCustomers} />
          </DashboardCard>
        </Column>
        <Column minWidth={500}>
          <DashboardCard title="Total de acessos" flex={1} spaceRight={24}>
            <TotalAccess data={totalAccess} />
          </DashboardCard>
          <DashboardCard title="Tempo de conexão" flex={1}>
            <ConnectionTime data={connectionTime} />
          </DashboardCard>
        </Column>
      </Row>
    );
  };

  const renderRow2 = () => {
    const { topVisitors, inRealTime, peakhours } = dashboardData;
    return (
      <Row>
        <Column spaceRight={24} minWidth={500}>
          <DashboardCard title="Horários de pico" flex={2}>
            <Peakhours data={peakhours} />
          </DashboardCard>
        </Column>
        <Column minWidth={500}>
          <DashboardCard title="Top Visitantes" flex={1} spaceRight={24}>
            <TopVisitors data={topVisitors} />
          </DashboardCard>
          <DashboardCard title="Em tempo real" flex={1}>
            <InRealTime data={inRealTime} />
          </DashboardCard>
        </Column>
      </Row>
    );
  };

  const renderRow3 = () => {
    const { dataTrafficPerCustomer, devices, ageRange, gender } = dashboardData;
    return (
      <Row>
        <Column spaceRight={24} minWidth={500}>
          <DashboardCard title="Gênero" flex={1} spaceRight={24}>
            <Gender data={gender} />
          </DashboardCard>
          <DashboardCard title="Faixa etária" flex={1}>
            <AgeRange data={ageRange} />
          </DashboardCard>
        </Column>
        <Column minWidth={500}>
          <DashboardCard title="Dispositivos" flex={1} spaceRight={24}>
            <Devices data={devices} />
          </DashboardCard>
          <DashboardCard title="Tráfego de dados por cliente" flex={1}>
            <DataTrafficPerCustomer data={dataTrafficPerCustomer} />
          </DashboardCard>
        </Column>
      </Row>
    );
  };

  // const renderAlert = () => {
  //   return (
  //     <AlertBox style={{ marginTop: 24, marginLeft: 24 }} onClose={() => {}}>
  //       <AlertMessage>
  //         Mantenha-se informado(a)! Lembre-se de inserir seu email para receber
  //         relatórios semanais de desempenho da sua rede WiFi.
  //         <ButtonConfigure>Configurar</ButtonConfigure>
  //       </AlertMessage>
  //     </AlertBox>
  //   );
  // };

  const renderContent = () => (
    <Container>
      <Header>{renderHeader()}</Header>
      {/* {renderAlert()} */}
      {!!dashboardData && renderRow1()}
      {!!dashboardData && renderRow2()}
      {!!dashboardData && renderRow3()}
      {modalBusinessPropagate && (
        <ModalBussinessPropagate
          onClose={() =>
            dispatch(userSetState({ modalBusinessPropagate: false }))
          }
        />
      )}
    </Container>
  );

  return <>{renderContent()}</>;
}

export default Main;
