import ReactSelect from 'react-select';
import styled from 'styled-components';

export const Select = styled(ReactSelect)`
  width: 100%;
  height: 60px;
  .custom-select__control {
    height: 60px;
    border-radius: 5px;
    border: 1px solid #d1d1d1;
    box-shadow: none;
    .custom-select__value-container {
      padding: 0;
      padding-left: 16px;
      .custom-select__placeholder,
      .custom-select__single-value,
      .custom-select__input input {
        font-size: 14px;
        font-weight: bold;
        color: #323236;
      }
    }
    .custom-select__indicators {
      .custom-select__indicator-separator {
        display: none;
      }
    }
  }
  .custom-select__control:hover {
    border-color: #d1d1d1;
  }
`;
