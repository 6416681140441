/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { FaChevronLeft, FaInfoCircle } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import history from '~/services/history';
import SmartphonePreview from '~/components/SmartphonePreview';

import {
  WrapPage,
  Container,
  VideoTitle,
  VideoSubtitle,
  InputWrap,
  ButtonNext,
  ButtonCancel,
  ContentBox,
  FormBox,
  PreviewBox,
  Legend,
  Description,
  Info,
  PreviewDescription,
  PreviewDescriptionIcon,
  PreviewDescriptionContent,
  TextArea,
  Input,
  InputBox,
  Label,
  CheckToggle,
  SmartphoneContent,
} from './styles';

function Step2() {
  const [smsText, setSmsText] = useState('');
  const [url, setUrl] = useState('');
  const [hasUrl, setHasUrl] = useState(false);

  return (
    <WrapPage>
      <Container>
        <VideoTitle>
          <Link to="/propagate/sms/step1">
            <FaChevronLeft size={14} />
          </Link>
          SMS
        </VideoTitle>
        <VideoSubtitle>PASSO 2/3</VideoSubtitle>
        <ContentBox>
          <FormBox>
            <Info>Configure o SMS que será enviado para os seus clientes.</Info>
            <Legend>Seu conteúdo</Legend>
            <Description style={{ marginBottom: 30 }}>
              O texto da SMS deve ter no máximo 160 caracteres e não é permitido
              o uso de caracteres especiais.
            </Description>
            <InputWrap style={{ marginBottom: 40 }}>
              <div className="input-textarea-box">
                <TextArea
                  value={smsText}
                  onChange={(e) => setSmsText(e.target.value)}
                  placeholder="Insira aqui o texto da sua campanha de SMS"
                  maxLength={160}
                />
                <span>{`${smsText.trim().length} de 160`}</span>
              </div>
            </InputWrap>
            <InputWrap>
              <InputBox>
                <Label>
                  <span>
                    Inserir link
                    <FaInfoCircle
                      data-tip="Inserir link"
                      data-for="tooltip-provider"
                    />
                  </span>
                  <CheckToggle
                    value={hasUrl}
                    onChange={(e) => setHasUrl(e.target.checked)}
                  />
                </Label>
                <Input
                  disabled={!hasUrl}
                  name="url"
                  type="text"
                  placeholder="Digite o link"
                  value={url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
              </InputBox>
            </InputWrap>
            <Description style={{ marginBottom: 35, marginTop: 5 }}>
              Exemplo: Seu site, sua rede social.
            </Description>
            <InputWrap>
              <ButtonNext
                title="Avançar"
                disabled={!smsText || (hasUrl && !url)}
                onClick={() => history.push('/propagate/sms/step3')}
              />
              <ButtonCancel
                title="Cancelar"
                onClick={() => history.push('/propagate')}
              />
            </InputWrap>
          </FormBox>
          <PreviewBox>
            <PreviewDescription>
              <PreviewDescriptionIcon>
                <AiFillEye />
              </PreviewDescriptionIcon>
              <PreviewDescriptionContent>
                Simulação de como seu cliente irá visualizar a página de acesso
              </PreviewDescriptionContent>
            </PreviewDescription>
            <SmartphonePreview className="tour-step-4">
              <SmartphoneContent>
                <div className="messagte-text-box">
                  <b>334-00</b>
                  <p>
                    {smsText ||
                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas porttitor nec ligula et facilisis. Aliquam ullamcorper bibendum leo, in tristique neque bibend'}
                  </p>
                </div>
                <div className="messagte-hour-box">10:00</div>
              </SmartphoneContent>
            </SmartphonePreview>
          </PreviewBox>
        </ContentBox>
        <ReactTooltip
          place="right"
          backgroundColor="#FFFFFF"
          textColor="#323232"
          border
          borderColor="#d1d1d1"
          className="tooltip-wrap"
          id="tooltip-provider"
        />
      </Container>
    </WrapPage>
  );
}

export default withTheme(Step2);
