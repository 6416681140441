import acougue from '~/assets/images/bg/acougue.jpg';
import bar from '~/assets/images/bg/bar.jpg';
import barbearia from '~/assets/images/bg/barbearia.jpg';
import cafeteria from '~/assets/images/bg/cafeteria.jpg';
import casaNoturna from '~/assets/images/bg/casaNoturna.jpg';
import cervejaria from '~/assets/images/bg/cervejaria.jpg';
import concessionariaDeAutomoveis from '~/assets/images/bg/concessionariaDeAutomoveis.jpg';
import consultorioMedico from '~/assets/images/bg/consultorioMedico.jpg';
import dentistaEConsultorioOdontologico from '~/assets/images/bg/dentistaEConsultorioOdontologico.jpg';
import doceria from '~/assets/images/bg/doceria.jpg';
import estudioDeTatuagemEPiercing from '~/assets/images/bg/estudioDeTatuagemEPiercing.jpg';
import foodTruck from '~/assets/images/bg/foodTruck.jpg';
import lanchonete from '~/assets/images/bg/lanchonete.jpg';
import livraria from '~/assets/images/bg/livraria.jpg';
import localParaEventosEApresentacoes from '~/assets/images/bg/localParaEventosEApresentacoes.jpg';
import loja from '~/assets/images/bg/loja.jpg';
import lojaDeConveniencia from '~/assets/images/bg/lojaDeConveniencia.jpg';
import mercearia from '~/assets/images/bg/mercearia.jpg';
import outro from '~/assets/images/bg/outro.jpg';
import padaria from '~/assets/images/bg/padaria.jpg';
import restaurante from '~/assets/images/bg/restaurante.jpg';
import salaoDeBeleza from '~/assets/images/bg/salaoDeBeleza.jpg';
import servicoAutomotivo from '~/assets/images/bg/servicoAutomotivo.jpg';
import supermercado from '~/assets/images/bg/supermercado.jpg';

export default {
  acougue,
  bar,
  barbearia,
  cafeteria,
  casaNoturna,
  cervejaria,
  concessionariaDeAutomoveis,
  consultorioMedico,
  dentistaEConsultorioOdontologico,
  doceria,
  estudioDeTatuagemEPiercing,
  foodTruck,
  lanchonete,
  livraria,
  localParaEventosEApresentacoes,
  loja,
  lojaDeConveniencia,
  mercearia,
  outro,
  padaria,
  restaurante,
  salaoDeBeleza,
  servicoAutomotivo,
  supermercado,
};
