/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-plusplus */
import React from 'react';
import uuidv4 from 'uuid/v4';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FaTrashAlt, FaRegCopy, FaRegTrashAlt } from 'react-icons/fa';
import { BiSquareRounded, BiCalendarAlt } from 'react-icons/bi';
import { FiPlusSquare } from 'react-icons/fi';
import { AiOutlineStar } from 'react-icons/ai';
import { IoMdCheckmark } from 'react-icons/io';
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdRadioButtonUnchecked,
} from 'react-icons/md';

import {
  Container,
  FieldItem,
  CheckTypeBox,
  RadioTypeBox,
  TextTypeBox,
  NumberTypeBox,
  RangeTypeBox,
  DateTypeBox,
} from './styles';

// eslint-disable-next-line no-unused-vars
function FormItems({ formItems, onChange, isInTourMode }) {
  const handleDelete = (uuid) => {
    onChange(formItems.filter((item) => item.uuid !== uuid));
  };

  const arrayMovePosition = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    onChange([...arr]);
  };

  const handleCopy = (uuid) => {
    const findItem = formItems.find((item) => item.uuid === uuid);
    if (findItem) {
      onChange([
        ...formItems,
        {
          ...findItem,
          uuid: uuidv4(),
        },
      ]);
    }
  };

  const handleSortUp = (index) => {
    arrayMovePosition(formItems, index, index - 1);
  };

  const handleSortDown = (index) => {
    arrayMovePosition(formItems, index, index + 1);
  };

  const handleChangeQuestion = (uuid, question) => {
    onChange(
      formItems.map((i) => {
        if (i.uuid === uuid) {
          return {
            ...i,
            question,
          };
        }
        return i;
      })
    );
  };

  const handleChangeQuestionRequired = (uuid) => {
    onChange(
      formItems.map((i) => {
        if (i.uuid === uuid) {
          return {
            ...i,
            isRequired: !i.isRequired,
          };
        }
        return i;
      })
    );
  };

  const handleChangeQuestionOption = (uuidQuestion, uuidOption, option) => {
    onChange(
      formItems.map((i) => {
        if (i.uuid === uuidQuestion) {
          return {
            ...i,
            options: i.options.map((o) => {
              if (o.uuid === uuidOption) {
                return {
                  ...o,
                  option,
                };
              }
              return o;
            }),
          };
        }
        return i;
      })
    );
  };

  const handleAddQuestionOption = (uuidQuestion) => {
    onChange(
      formItems.map((i) => {
        if (i.uuid === uuidQuestion) {
          return {
            ...i,
            options: [...i.options, { uuid: uuidv4(), option: '' }],
          };
        }
        return i;
      })
    );
  };

  const handleRemoveQuestionOption = (uuidQuestion, uuidOption) => {
    onChange(
      formItems.map((i) => {
        if (i.uuid === uuidQuestion) {
          return {
            ...i,
            options: i.options.filter((o) => o.uuid !== uuidOption),
          };
        }
        return i;
      })
    );
  };

  const renderCheckType = (field) => (
    <CheckTypeBox>
      {field.options.map((i) => (
        <div className="options-item" key={i.uuid}>
          <BiSquareRounded />
          <button
            type="button"
            onClick={() => handleRemoveQuestionOption(field.uuid, i.uuid)}
          >
            <FaRegTrashAlt />
          </button>
          <input
            type="text"
            value={i.option}
            onChange={(e) =>
              handleChangeQuestionOption(field.uuid, i.uuid, e.target.value)
            }
            autoFocus={!i.option}
          />
        </div>
      ))}
      <button type="button" onClick={() => handleAddQuestionOption(field.uuid)}>
        <FiPlusSquare />
        <b>Novo item</b>
      </button>
    </CheckTypeBox>
  );

  const renderRadioType = (field) => (
    <RadioTypeBox>
      {field.options.map((i) => (
        <div className="options-item" key={i.uuid}>
          <MdRadioButtonUnchecked />
          <button
            type="button"
            onClick={() => handleRemoveQuestionOption(field.uuid, i.uuid)}
          >
            <FaRegTrashAlt />
          </button>
          <input
            type="text"
            value={i.option}
            onChange={(e) =>
              handleChangeQuestionOption(field.uuid, i.uuid, e.target.value)
            }
            autoFocus={!i.option}
          />
        </div>
      ))}
      <button type="button" onClick={() => handleAddQuestionOption(field.uuid)}>
        <FiPlusSquare />
        <b>Novo item</b>
      </button>
    </RadioTypeBox>
  );

  const renderTextType = () => (
    <TextTypeBox>
      <input type="text" value="Abc" disabled />
    </TextTypeBox>
  );

  const renderNumberType = () => (
    <NumberTypeBox>
      <input type="text" value="1234567890" disabled />
    </NumberTypeBox>
  );

  const renderRangeType = () => (
    <RangeTypeBox>
      <AiOutlineStar />
      <AiOutlineStar />
      <AiOutlineStar />
      <AiOutlineStar />
      <AiOutlineStar />
    </RangeTypeBox>
  );

  const renderDateType = () => (
    <DateTypeBox>
      <input type="text" value="99/99/9999" disabled />
      <BiCalendarAlt />
    </DateTypeBox>
  );

  const renderOptionsByType = (field) => {
    switch (field.type) {
      case 'check':
        return renderCheckType(field);
      case 'radio':
        return renderRadioType(field);
      case 'text':
        return renderTextType(field);
      case 'number':
        return renderNumberType(field);
      case 'range':
        return renderRangeType(field);
      case 'date':
        return renderDateType(field);
      default:
        return null;
    }
  };

  if (isInTourMode) {
    return (
      <FieldItem className="tour-step-9">
        <div className="field-item-content">
          <div className="field-item-content-question tour-step-10">
            <b>1 -</b>
            <input
              type="text"
              value="Texto da pergunta Texto da pergunta Texto da pergunta Texto da pergunta"
              placeholder="Digite o título da questão"
              onChange={() => {}}
            />
          </div>
          <RadioTypeBox>
            {[1, 2, 3].map((i) => (
              <div className="options-item" key={i}>
                <MdRadioButtonUnchecked />
                <button type="button" onClick={() => {}}>
                  <FaRegTrashAlt />
                </button>
                <input
                  type="text"
                  value="Alternativa"
                  onChange={() => {}}
                  autoFocus={!i.option}
                />
              </div>
            ))}
            <button type="button" className="tour-step-11" onClick={() => {}}>
              <FiPlusSquare />
              <b>Novo item</b>
            </button>
          </RadioTypeBox>
        </div>
        <div className="field-item-footer">
          <button
            className="field-item-footer-action tour-step-12"
            type="button"
            onClick={() => {}}
            data-tip="Excluir"
            data-for="tooltip-research-form"
          >
            <FaTrashAlt />
          </button>
          <button
            className="field-item-footer-action tour-step-13"
            type="button"
            onClick={() => {}}
            data-tip="Duplicar"
            data-for="tooltip-research-form"
          >
            <FaRegCopy />
          </button>
          <button
            className="field-item-footer-action tour-step-14"
            type="button"
            onClick={() => {}}
            data-tip="Posicionar para cima"
            data-for="tooltip-research-form"
          >
            <MdKeyboardArrowUp />
          </button>
          <button
            className="field-item-footer-action tour-step-15"
            type="button"
            onClick={() => {}}
            data-tip="Posicionar para baixo"
            data-for="tooltip-research-form"
          >
            <MdKeyboardArrowDown />
          </button>
          <button
            className="field-item-footer-required"
            type="button"
            onClick={() => {}}
            data-tip="Campo para marcar se a resposta for obrigatória"
            data-for="tooltip-research-form"
          >
            <span className="is-required">
              <IoMdCheckmark />
            </span>
            Resposta obrigatória
          </button>
        </div>
      </FieldItem>
    );
  }

  return (
    <Container>
      {formItems.map((field, index) => (
        <FieldItem key={field.uuid}>
          <div className="field-item-content">
            <div className="field-item-content-question">
              <b>{`${index + 1} -`}</b>
              <input
                type="text"
                value={field.question}
                placeholder="Digite o título da questão"
                onChange={(e) =>
                  handleChangeQuestion(field.uuid, e.target.value)
                }
                autoFocus={!field.question}
              />
            </div>
            {renderOptionsByType(field)}
          </div>
          <div className="field-item-footer">
            <button
              className="field-item-footer-action"
              type="button"
              onClick={() => handleDelete(field.uuid)}
              data-tip="Excluir"
              data-for="tooltip-research-form"
            >
              <FaTrashAlt />
            </button>
            <button
              className="field-item-footer-action"
              type="button"
              onClick={() => handleCopy(field.uuid)}
              data-tip="Duplicar"
              data-for="tooltip-research-form"
            >
              <FaRegCopy />
            </button>
            <button
              className="field-item-footer-action"
              type="button"
              onClick={() => handleSortUp(index)}
              disabled={index === 0}
              data-tip="Posicionar para cima"
              data-for="tooltip-research-form"
            >
              <MdKeyboardArrowUp />
            </button>
            <button
              className="field-item-footer-action"
              type="button"
              onClick={() => handleSortDown(index)}
              disabled={index === formItems.length - 1}
              data-tip="Posicionar para baixo"
              data-for="tooltip-research-form"
            >
              <MdKeyboardArrowDown />
            </button>
            <button
              className="field-item-footer-required"
              type="button"
              onClick={() => handleChangeQuestionRequired(field.uuid)}
              data-tip="Campo para marcar se a resposta for obrigatória"
              data-for="tooltip-research-form"
            >
              <span className={field.isRequired ? 'is-required' : ''}>
                {field.isRequired && <IoMdCheckmark />}
              </span>
              Resposta obrigatória
            </button>
          </div>
        </FieldItem>
      ))}
      <ReactTooltip
        place="bottom"
        backgroundColor="#FFFFFF"
        textColor="#323232"
        border
        borderColor="#d1d1d1"
        className="tooltip-wrap"
        id="tooltip-research-form"
      />
    </Container>
  );
}

FormItems.defaultProps = {
  isInTourMode: false,
};

FormItems.propTypes = {
  formItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  isInTourMode: PropTypes.bool,
};

export default FormItems;
