import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import types from './types';
import { visitorsSetState } from './actions';
import {
  setIsLoading,
  // setIsSuccess,
  setIsError,
} from '~/store/modules/loading/actions';

import api from '../../../services/api';

export function* dataRequest() {
  const {
    visitors: { filter },
  } = yield select();
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(
      api.get,
      `visitors?_page=${filter.page}&_limit=6`
    );
    yield put(visitorsSetState({ list: data }));
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsError(true));
  }
}

export default all([takeLatest(types.VISITORS_DATA_REQUEST, dataRequest)]);
