import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  width: fit-content;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  width: fit-content;
  background-color: #4a90e2;
  border: 1px solid #4a90e2;
  border-radius: 10px;
  overflow: hidden;
`;

export const Label = styled.label`
  color: #696974;
  font-size: 14px;
  margin-right: 10px;
`;

export const ButtonItem = styled.button.attrs({ type: 'button' })`
  background-color: ${(props) => (props.active ? '#0062FF' : '#ffffff')};
  color: ${(props) => (props.active ? '#ffffff' : '#0062FF')};
  border: 0;
  min-height: 38px;
  max-height: 38px;
  padding: 0 20px;
  margin-right: 1px;
  font-size: 14px;
  &:last-child {
    margin-right: 0;
  }
`;
