import types from './types';

export function propagateSetState(state) {
  return {
    type: types.PROPAGATE_SET_STATE,
    payload: { state },
  };
}

export function propagateDataRequest() {
  return {
    type: types.PROPAGATE_DATA_REQUEST,
  };
}

export function propagateCampaignSave() {
  return {
    type: types.PROPAGATE_CAMPAIGN_SAVE,
  };
}

export function propagateCampaignGet(uuid) {
  return {
    type: types.PROPAGATE_CAMPAIGN_GET,
    payload: { uuid },
  };
}
