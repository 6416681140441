/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { FaChevronLeft } from 'react-icons/fa';
import { AiFillPlayCircle, AiFillEye } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import { userSetState } from '~/store/modules/user/actions';
import { propagateCampaignSave } from '~/store/modules/propagate/actions';
import history from '~/services/history';
import { fileToBase64 } from '~/utils';
import DropZoneUpload from '~/components/DropZoneUpload';
import SmartphonePreview from '~/components/SmartphonePreview';
import restaurant from '~/assets/images/avatar/restaurante.svg';

import {
  WrapPage,
  Container,
  VideoTitle,
  VideoSubtitle,
  InputWrap,
  ButtonNext,
  ButtonCancel,
  ContentBox,
  FormBox,
  PreviewBox,
  Legend,
  Description,
  Info,
  PreviewVideo,
  PreviewTitle,
  PreviewLogo,
  PreviewForm,
  PreviewDescription,
  PreviewDescriptionIcon,
  PreviewDescriptionContent,
} from './styles';

function Step2() {
  const [video, setVideo] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userSetState({ wizardProgress: 100, backgroundImage: null }));
  }, []);

  const handleSetVideo = async (file) => {
    const base64File = file ? await fileToBase64(file) : null;
    setVideo(base64File);
  };

  return (
    <WrapPage>
      <Container>
        <VideoTitle>
          <Link to="/propagate/video/step1">
            <FaChevronLeft size={14} />
          </Link>
          Vídeo
        </VideoTitle>
        <VideoSubtitle>PASSO 2/2</VideoSubtitle>
        <ContentBox>
          <FormBox>
            <Info>
              Configure o vídeo que vai ser exibido quando seu cliente entrar no
              seu portal.
            </Info>
            <Legend>Seu conteúdo</Legend>
            <Description style={{ marginBottom: 35 }}>
              o vídeo deve ter a proporção de 16:9. Tamanho máximo: 2MB.
            </Description>
            <DropZoneUpload
              label="Selecione seu vídeo"
              title="Seu vídeo"
              modalTitle="Vídeo da sua campanha"
              modalLabel="Arraste e solte sua imagem aqui"
              fileUrl={video}
              onChange={handleSetVideo}
              className="tour-step-2"
              accept="video/mp4"
              maxSize={2000000}
              typeThumb="video"
            />
            <Description style={{ marginTop: 35 }}>
              Sua campanha poderá ser editada futuramente na página principal.
            </Description>
            <InputWrap>
              <ButtonNext
                title="Publicar"
                onClick={() => dispatch(propagateCampaignSave())}
              />
              <ButtonCancel
                title="Cancelar"
                onClick={() => history.push('/propagate')}
              />
            </InputWrap>
          </FormBox>
          <PreviewBox>
            <PreviewDescription>
              <PreviewDescriptionIcon>
                <AiFillEye />
              </PreviewDescriptionIcon>
              <PreviewDescriptionContent>
                Simulação de como seu cliente irá visualizar a página de acesso
              </PreviewDescriptionContent>
            </PreviewDescription>
            <SmartphonePreview className="tour-step-4">
              <PreviewVideo>
                <PreviewTitle>Amelie Wifi</PreviewTitle>
                <PreviewLogo isDefault>
                  <img alt="Logo" src={restaurant} />
                </PreviewLogo>
              </PreviewVideo>
              <PreviewForm>
                <span>
                  Assista o vídeo a seguir para ganhar 00 minutos de acesso
                </span>
                <div className="preview-form-video">
                  {!video && <AiFillPlayCircle />}
                  {!!video && (
                    <video controls>
                      <source src={video} type="video/mp4" />
                      Seu browser não suporta o preview de vídeos
                    </video>
                  )}
                </div>
                <button type="button">Continuar</button>
              </PreviewForm>
            </SmartphonePreview>
          </PreviewBox>
        </ContentBox>
      </Container>
    </WrapPage>
  );
}

export default withTheme(Step2);
