import React from 'react';
import PropTypes from 'prop-types';
import { MdAndroid } from 'react-icons/md';
import { FaApple, FaCircle } from 'react-icons/fa';

import {
  Container,
  DeviceItem,
  DeviceItemIcon,
  DeviceItemProgress,
  DeviceItemProgressBar,
  DeviceItemPergentage,
} from './styles';

function Devices({ data }) {
  const renderAndroid = () => (
    <DeviceItem>
      <DeviceItemIcon>
        <MdAndroid color="#171725" />
      </DeviceItemIcon>
      <DeviceItemProgress>
        <DeviceItemProgressBar pergentage={data.android} color="#77C285" />
      </DeviceItemProgress>
      <DeviceItemPergentage>{`${data.android}%`}</DeviceItemPergentage>
    </DeviceItem>
  );
  const renderIos = () => (
    <DeviceItem>
      <DeviceItemIcon>
        <FaApple color="#171725" />
      </DeviceItemIcon>
      <DeviceItemProgress>
        <DeviceItemProgressBar pergentage={data.ios} color="#F5A623" />
      </DeviceItemProgress>
      <DeviceItemPergentage>{`${data.ios}%`}</DeviceItemPergentage>
    </DeviceItem>
  );
  const renderOthers = () => (
    <DeviceItem>
      <DeviceItemIcon>
        <FaCircle color="#171725" />
      </DeviceItemIcon>
      <DeviceItemProgress>
        <DeviceItemProgressBar pergentage={data.others} color="#1785D5" />
      </DeviceItemProgress>
      <DeviceItemPergentage>{`${data.others}%`}</DeviceItemPergentage>
    </DeviceItem>
  );
  return (
    <Container>
      {renderAndroid()}
      {renderIos()}
      {renderOthers()}
    </Container>
  );
}
Devices.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Devices;
