import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { IoIosArrowDown } from 'react-icons/io';

import Button from '../Button';

import {
  Container,
  ColorBox,
  ColorIcon,
  Modal,
  ModalContent,
  ModalFooter,
  ModalFooterColorView,
} from './styles';

function ColorPicker({ value, onChange, style, className }) {
  const [color, setColor] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setColor(value);
  }, [value]);

  const renderModal = () => (
    <Modal
      visible={modalOpen}
      width="320"
      height="430"
      effect="fadeInUp"
      onClickAway={() => {}}
    >
      <ModalContent>
        <SketchPicker
          color={color || ''}
          disableAlpha
          width={300}
          onChange={({ hex }) => onChange(hex)}
          className="custom-picker"
        />
      </ModalContent>
      <ModalFooter>
        <ModalFooterColorView color={color} />
        <Button title="Ok" onClick={() => setModalOpen(false)} />
      </ModalFooter>
    </Modal>
  );
  return (
    <>
      <Container
        onClick={() => setModalOpen(true)}
        style={style}
        className={className}
      >
        <ColorBox color={color} />
        <ColorIcon>
          <IoIosArrowDown />
        </ColorIcon>
      </Container>
      {renderModal()}
    </>
  );
}

ColorPicker.defaultProps = {
  value: '',
  style: null,
};

ColorPicker.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape(),
};

export default ColorPicker;
