import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AmountBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > svg {
    font-size: 32px;
    color: #171725;
  }
`;

export const Amount = styled.span`
  font-size: 28px;
  font-weight: 600;
  color: #171725;
  margin-left: 5px;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: #696974;
  margin-top: 10px;
`;

export const VisitorsTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #424249;
  margin-top: 24px;
`;

export const ListVisitors = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-left: -10px;
`;

export const ListVisitorsItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
`;

export const ListVisitorsItemIcon = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  color: #ffffff;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
