import styled from 'styled-components';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
`;

export const PageTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  font-family: 'Quicksand';
  font-weight: 400;
  color: #0a0724;
  position: relative;
  a {
    position: absolute;
    left: -30px;
    display: flex;
    align-items: center;
    > svg {
      height: 22px;
      color: #fa6115;
    }
  }
`;

export const CampaignBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CampaignBoxHeader = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  padding: 52px 18px;
  > div.campaign-box-header-content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    h2 {
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      letter-spacing: 0.11px;
      color: #323236;
      margin-bottom: 5px;
    }
    span {
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.83;
      letter-spacing: 0.09px;
      color: #323236;
    }
  }
`;

export const CampaignIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  svg {
    font-size: 25px;
    color: #0a0724;
  }
`;

export const CampaignBoxSection = styled.div`
  display: flex;
  flex-direction: column;
  > h2 {
    display: flex;
    align-items: center;
    font-family: Quicksand;
    font-size: 24px;
    font-weight: normal;
    line-height: 1;
    color: #0a0724;
    margin: 30px 0 20px 0;
    svg {
      font-size: 20px;
      margin-left: 10px;
    }
  }
  > div.campaign-box-section-content {
    display: flex;
    width: 100%;
  }
`;

export const CampaignBoxResultCard = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  flex-direction: column;
  height: 205px;
  padding: 30px 20px 20px 20px;
  border-radius: 6px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  > span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    color: #0f2522;
    display: flex;
    align-items: center;
    svg {
      cursor: pointer;
      margin-left: 10px;
      color: #323236;
      font-size: 17px;
    }
  }
  > h2 {
    font-family: Poppins;
    font-size: 58px;
    font-weight: 600;
    line-height: 0.8;
    color: #0f2522;
    margin-top: 50px;
  }
`;

export const CampaignBoxDemographicCard = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  flex-direction: column;
  height: 350px;
  padding: 30px 20px 20px 20px;
  border-radius: 6px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  > span {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    color: #171725;
    letter-spacing: 0.13px;
    display: flex;
    align-items: center;
  }
`;

export const ResearchItemFormBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 30px 20px 20px 20px;
  border-radius: 6px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-bottom: 60px;
  position: relative;
  button.research-form-item-minimize {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    padding: 0;
    border: 0;
    svg {
      color: #171725;
      font-size: 50px;
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
  .research-item-title {
    display: flex;
    align-items: center;
    b {
      font-family: Poppins;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.07px;
      color: #0a0724;
    }
    span {
      min-width: 380px;
      height: 32px;
      border-radius: 3.4px;
      border: solid 1px #d1d1d1;
      background-color: #fafafb;
      padding: 0 12px;
      display: flex;
      align-items: center;
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      letter-spacing: 0.07px;
      color: #0a0724;
      margin-left: 5px;
    }
  }
  .research-item-type {
    display: flex;
    width: 100%;
    margin-top: 15px;
    align-items: center;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.07px;
    color: #0a0724;
    svg {
      margin-left: 5px;
      font-size: 15px;
    }
  }
`;
