import { all, takeLatest, put, call } from 'redux-saga/effects';

import types from './types';
import { supportSetState } from './actions';
import {
  setIsLoading,
  // setIsSuccess,
  setIsError,
} from '~/store/modules/loading/actions';

import api from '../../../services/api';

export function* dataRequest() {
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(api.get, `supports`);
    yield put(supportSetState({ list: data }));
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsError(true));
  }
}

export function* dataGet({ payload }) {
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(api.get, `supports/${payload.id}`);
    yield put(supportSetState({ details: data }));
    yield put(setIsLoading(false));
  } catch (error) {
    //
  }
}

export default all([
  takeLatest(types.SUPPORTS_DATA_REQUEST, dataRequest),
  takeLatest(types.SUPPORTS_GET_REQUEST, dataGet),
]);
