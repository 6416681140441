import { TENANT_CLARO, TENANT_MOBICARE } from '../hooks/tenant';

const themeDefault = {
  colors: {
    white: '#FFFFFF',
    lighterWhite: '#F2F2F2',
    lighter: '#D8D8D8',
    light: 'rgba(151, 151, 151, 0.35)',
    regular: '#929292',
    dark: '#5B5B5B',
    darker: '#212121',
    black: '#0A0724',

    primary: 'rgb(0,98,255)',
    primaryHex: '#0062FF',
    danger: 'rgb(166,39,30)',
    danger2: 'rgb(255, 59, 45)',
    bgBody: '#FFFFFF',
    checked: '#008000',
    blue: '#339af0',
    blue2: '#0062FF',
    warning: '#ffc107',
    warning2: '#e0a800',

    steps: {
      progress: '#fa6115',
      link: '#fa6115',
    },
  },
  button: {
    radius: 25,
  },
};
const themeClaro = {
  colors: {
    white: '#FFFFFF',
    lighterWhite: '#F2F2F2',
    lighter: '#D8D8D8',
    light: 'rgba(151, 151, 151, 0.35)',
    regular: '#929292',
    dark: '#5B5B5B',
    darker: '#212121',
    black: '#0A0724',

    primary: 'rgb(252, 90, 90)',
    primaryHex: '#fc5a5a',
    danger: 'rgb(166,39,30)',
    danger2: 'rgb(255, 59, 45)',
    bgBody: '#FFFFFF',
    checked: '#008000',
    blue: '#339af0',
    blue2: '#0062FF',
    warning: '#ffc107',
    warning2: '#e0a800',

    steps: {
      progress: '#fa6115',
      link: '#fa6115',
    },
  },
  button: {
    radius: 25,
  },
};

const getThemeByTenant = (tenant) => {
  switch (tenant) {
    case TENANT_CLARO:
      return themeClaro;
    case TENANT_MOBICARE:
      return themeDefault;
    default:
      return themeDefault;
  }
};

export default {
  getThemeByTenant,
};
