/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';
import GroupButtons from '~/components/GroupButtons';
import WrapDesktop from './components/Wrap/Desktop';
import WrapSmartphone from './components/Wrap/Smartphone';
import DropZoneUpload from '~/components/DropZoneUpload';
import wallpaper from '~/assets/images/step4-preview-bg.jpg';
import { fileToBase64 } from '~/utils';
import avatars from '~/pages/Onboarding/pages/Step4/avatars';

import history from '~/services/history';
import {
  WrapPage,
  Container,
  Header,
  PreviewLogo,
  PreviewTitle,
  FormBox,
  SelectType,
  InputName,
  InputEmail,
  InputWrap,
  CheckToggle,
  InputEmailBox,
  Label,
  InputEmailList,
  InputEmailListItem,
  ButtonCancel,
  ButtonSave,
} from './styles';

function Settings() {
  const [viewMode, setViewMode] = useState('desktop');
  const [boxNameOpen, setBoxNameOpen] = useState(false);
  const [boxBusinesTypeOpen, setBoxBusinesTypeOpen] = useState(false);
  const [boxReportOpen, setBoxReportOpen] = useState(false);
  const [businessType, setBusinessType] = useState('cafeteria');
  const [businessName, setBusinessName] = useState('Amelie Wifi');
  const [emailItem, setEmailItem] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [bgPic, setBgPic] = useState(null);
  const [emailList, setEmailList] = useState([
    'item@email.com',
    'item@email.com',
    'item@email.com',
  ]);
  const [receiveReport, setReceiveReport] = useState(true);
  const optionsBusiness = [
    { value: 'acougue', label: 'Açougue' },
    { value: 'bar', label: 'Bar' },
    { value: 'barbearia', label: 'Barbearia' },
    { value: 'cafeteria', label: 'Cafeteria' },
    { value: 'casaNoturna', label: 'Casa noturna' },
    { value: 'cervejaria', label: 'Cervejaria' },
    {
      value: 'concessionariaDeAutomoveis',
      label: 'Concessionária de automóveis',
    },
    { value: 'consultorioMedico', label: 'Consultório médico' },
    {
      value: 'dentistaEConsultorioOdontologico',
      label: 'Dentista e consultório odontológico',
    },
    { value: 'doceria', label: 'Doceria' },
    {
      value: 'estudioDeTatuagemEPiercing',
      label: 'Estúdio de tatuagem e piercing',
    },
    { value: 'foodTruck', label: 'Food truck' },
    { value: 'lanchonete', label: 'Lanchonete' },
    { value: 'livraria', label: 'Livraria' },
    {
      value: 'localParaEventosEApresentacoes',
      label: 'Local para eventos e apresentações',
    },
    { value: 'loja', label: 'Loja' },
    { value: 'lojaDeConveniencia', label: 'Loja de conveniência' },
    { value: 'mercearia', label: 'Mercearia' },
    { value: 'padaria', label: 'Padaria' },
    { value: 'restaurante', label: 'Restaurante' },
    { value: 'salaoDeBeleza', label: 'Salão de beleza' },
    { value: 'servicoAutomotivo', label: 'Serviço automotivo' },
    { value: 'supermercado', label: 'Supermercado' },
    { value: 'outro', label: 'Outro' },
  ];

  const renderHeader = () => (
    <Header>
      <h2>Configurações</h2>
      <div className="header-box-viewmode">
        <GroupButtons
          label="Modo de visualização:"
          options={[
            { label: 'Desktop', value: 'desktop' },
            { label: 'Mobile', value: 'mobile' },
          ]}
          onChange={setViewMode}
          value={viewMode}
        />
      </div>
      <b>Personalize a sua página</b>
      <span>
        Você pode modificar seu plano de fundo, foto de perfil e nome da rede.
      </span>
    </Header>
  );

  const ButtonEditPicBg = ({ ...rest }) => (
    <button className="button-edit" type="button" {...rest}>
      <FaPencilAlt />
    </button>
  );

  const ButtonEditPicProfile = ({ ...rest }) => (
    <button className="button-edit" type="button" {...rest}>
      <FaPencilAlt />
    </button>
  );

  const ButtonRemovePicProfile = () => (
    <button
      className="button-edit"
      type="button"
      onClick={() => setProfilePic(null)}
    >
      <FaTimes />
    </button>
  );

  const renderContent = () => (
    <Container mode={viewMode} bgPic={bgPic || wallpaper}>
      <DropZoneUpload
        onChange={async (file) => {
          const base64File = file ? await fileToBase64(file) : null;
          setBgPic(base64File);
        }}
        modalTitle="Imagem de fundo"
        modalLabel="Arraste e solte sua imagem aqui"
        cropImage="rect"
        aspect={16 / 9}
        component={ButtonEditPicBg}
      />
      <div className="content-header">
        <PreviewTitle>{businessName}</PreviewTitle>
      </div>
      <div className="content-box">
        <PreviewLogo isDefault={!profilePic}>
          {(!!profilePic || !!businessType) && (
            <img alt="Cafeteria" src={profilePic || avatars[businessType]} />
          )}
          {profilePic ? (
            <ButtonRemovePicProfile />
          ) : (
            <DropZoneUpload
              onChange={async (file) => {
                const base64File = file ? await fileToBase64(file) : null;
                setProfilePic(base64File);
              }}
              cropImage="round"
              modalTitle="Imagem da sua marca"
              modalLabel="Arraste e solte sua imagem aqui"
              component={ButtonEditPicProfile}
            />
          )}
        </PreviewLogo>
        <span>Acesso à rede</span>
        <label>Preencha com seu CPF ou Passaporte para se conectar</label>
        <input />
        <button type="button">Conectar</button>
      </div>
    </Container>
  );

  const renderDesktop = () => <WrapDesktop>{renderContent()}</WrapDesktop>;

  const renderMobile = () => <WrapSmartphone>{renderContent()}</WrapSmartphone>;

  const renderForm = () => (
    <FormBox>
      <div className="form-box-item">
        <h2>Nome no seu negócio</h2>
        <button
          className="button-open-close"
          type="button"
          onClick={() => setBoxNameOpen(!boxNameOpen)}
        >
          {boxNameOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
        {boxNameOpen && (
          <InputWrap>
            <InputName
              name="inputNomeRede"
              type="text"
              placeholder="Digite o nome do seu negócio"
              value={businessName}
              onChange={(e) => {
                setBusinessName(e.target.value);
              }}
              maxLength={32}
              className="tour-step-1"
            />
          </InputWrap>
        )}
      </div>
      <div className="form-box-item">
        <h2>Tipo de negócio</h2>
        <button
          className="button-open-close"
          type="button"
          onClick={() => setBoxBusinesTypeOpen(!boxBusinesTypeOpen)}
        >
          {boxBusinesTypeOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
        {boxBusinesTypeOpen && (
          <InputWrap>
            <SelectType
              placeholder="Escolha seu tipo de negócio"
              isClearable
              value={optionsBusiness.find((i) => i.value === businessType)}
              options={optionsBusiness}
              onChange={(i) => setBusinessType(i ? i.value : null)}
            />
          </InputWrap>
        )}
      </div>
      <div className="form-box-item">
        <h2>Relatórios</h2>
        <button
          className="button-open-close"
          type="button"
          onClick={() => setBoxReportOpen(!boxReportOpen)}
        >
          {boxReportOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
        {boxReportOpen && (
          <>
            <InputWrap>
              <div className="input-wrap-body">
                <span>
                  Receba relatórios semanais com desempenho da sua rede wi-fi
                  diretamente no seu e-mail
                </span>
                <div className="toggle-input-box">
                  <span>
                    {receiveReport
                      ? 'Desativar recebimento de relatório'
                      : 'Ativar recebimento de relatório'}
                  </span>
                  <CheckToggle
                    value={receiveReport}
                    onChange={(e) => setReceiveReport(e.target.checked)}
                  />
                </div>
              </div>
            </InputWrap>
            {receiveReport && (
              <>
                <Label style={{ marginTop: 30, marginBottom: 10 }}>
                  Inserir e-mail
                </Label>
                <InputEmailBox>
                  <InputEmail
                    name="inputNomeRede"
                    type="text"
                    placeholder="Digite o e-mail"
                    value={emailItem}
                    onChange={(e) => {
                      setEmailItem(e.target.value);
                    }}
                    maxLength={32}
                    className="tour-step-1"
                  />
                  <button
                    type="button"
                    disabled={!emailItem}
                    onClick={() => {
                      setEmailList([...emailList, emailItem]);
                      setEmailItem('');
                    }}
                  >
                    Adicionar
                  </button>
                </InputEmailBox>
                {emailList.length > 0 && (
                  <InputEmailList>
                    {emailList.map((email, index) => (
                      <InputEmailListItem key={index}>
                        <span>{email}</span>
                        <button
                          type="button"
                          onClick={() => {
                            setEmailList(
                              emailList.filter((it, idx) => idx !== index)
                            );
                          }}
                        >
                          Excluir
                        </button>
                      </InputEmailListItem>
                    ))}
                  </InputEmailList>
                )}
              </>
            )}
          </>
        )}
      </div>
    </FormBox>
  );

  return (
    <WrapPage>
      {renderHeader()}
      {viewMode === 'desktop' && renderDesktop()}
      {viewMode === 'mobile' && renderMobile()}
      {renderForm()}
      <InputWrap style={{ marginBottom: 30, padding: '0 80px' }}>
        <ButtonSave title="Salvar" onClick={() => history.push('/')} />
        <ButtonCancel title="Cancelar" onClick={() => history.push('/')} />
      </InputWrap>
    </WrapPage>
  );
}

export default Settings;
