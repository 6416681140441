import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-tiny-popover';
import {
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
} from 'react-icons/fa';
import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineUnderline,
} from 'react-icons/ai';

import { EditorBox, Input, TextArea, Select, EditorBoxButton } from './styles';

function Editor({
  multiple,
  textValue,
  onChangeTextValue,
  textStyle,
  onChangeTextStyle,
  buttonsShow,
  disabled,
}) {
  const [editorIsOpen, setEditorIsOpen] = useState(false);
  const [valueContent, setValueContent] = useState('');
  const fontList = [
    { label: 'Quicksand', value: 'Quicksand' },
    { label: 'Poppins', value: 'Poppins' },
    { label: 'Montserrat', value: 'Montserrat' },
  ];
  useEffect(() => {
    if (!valueContent) {
      setValueContent(textValue);
    }
  }, []);
  return (
    <Popover
      isOpen={editorIsOpen}
      position="top"
      padding={5}
      onClickOutside={() => setEditorIsOpen(false)}
      containerStyle={{
        overflow: 'visible',
      }}
      content={() => (
        <EditorBox>
          {buttonsShow.hasFont && (
            <Select
              placeholder="Fonte"
              value={fontList.find((i) => i.value === textStyle.fontFamily)}
              onChange={(item) => {
                onChangeTextStyle({
                  ...textStyle,
                  fontFamily: item.value,
                });
              }}
              options={fontList}
            />
          )}
          {(buttonsShow.hasBold ||
            buttonsShow.hasItalic ||
            buttonsShow.hasUnderlined) && (
            <div className="editor-box-separator" />
          )}
          {buttonsShow.hasBold && (
            <EditorBoxButton
              active={textStyle.fontWeight === 'bold'}
              onClick={() =>
                onChangeTextStyle({
                  ...textStyle,
                  fontWeight:
                    textStyle.fontWeight === 'bold' ? 'normal' : 'bold',
                })
              }
            >
              <AiOutlineBold />
            </EditorBoxButton>
          )}
          {buttonsShow.hasItalic && (
            <EditorBoxButton
              active={textStyle.fontStyle === 'italic'}
              onClick={() =>
                onChangeTextStyle({
                  ...textStyle,
                  fontStyle:
                    textStyle.fontStyle === 'italic' ? 'normal' : 'italic',
                })
              }
            >
              <AiOutlineItalic />
            </EditorBoxButton>
          )}
          {buttonsShow.hasUnderlined && (
            <EditorBoxButton
              active={textStyle.textDecoration === 'underline'}
              onClick={() =>
                onChangeTextStyle({
                  ...textStyle,
                  textDecoration:
                    textStyle.textDecoration === 'underline'
                      ? 'none'
                      : 'underline',
                })
              }
            >
              <AiOutlineUnderline />
            </EditorBoxButton>
          )}
          {buttonsShow.hasAlign && (
            <>
              <div className="editor-box-separator" />
              <EditorBoxButton
                active={textStyle.textAlign === 'left'}
                onClick={() =>
                  onChangeTextStyle({ ...textStyle, textAlign: 'left' })
                }
              >
                <FaAlignLeft />
              </EditorBoxButton>
              <EditorBoxButton
                active={textStyle.textAlign === 'center'}
                onClick={() =>
                  onChangeTextStyle({ ...textStyle, textAlign: 'center' })
                }
              >
                <FaAlignCenter />
              </EditorBoxButton>
              <EditorBoxButton
                active={textStyle.textAlign === 'right'}
                onClick={() =>
                  onChangeTextStyle({ ...textStyle, textAlign: 'right' })
                }
              >
                <FaAlignRight />
              </EditorBoxButton>
              <EditorBoxButton
                active={textStyle.textAlign === 'justify'}
                onClick={() =>
                  onChangeTextStyle({ ...textStyle, textAlign: 'justify' })
                }
              >
                <FaAlignJustify />
              </EditorBoxButton>
            </>
          )}
        </EditorBox>
      )}
    >
      {multiple ? (
        <TextArea
          contentEditable={disabled ? 'false' : 'true'}
          onInput={(e) => onChangeTextValue(e.currentTarget.textContent)}
          style={textStyle}
          onFocus={() => setEditorIsOpen(!disabled)}
        >
          {valueContent}
        </TextArea>
      ) : (
        <Input
          contentEditable={disabled ? 'false' : 'true'}
          onInput={(e) => onChangeTextValue(e.currentTarget.textContent)}
          style={textStyle}
          onFocus={() => setEditorIsOpen(!disabled)}
        >
          {valueContent}
        </Input>
      )}
    </Popover>
  );
}

Editor.defaultProps = {
  multiple: false,
  textValue: '',
  disabled: false,
  buttonsShow: {
    hasFont: true,
    hasAlign: true,
    hasBold: true,
    hasItalic: true,
    hasUnderlined: true,
  },
};

Editor.propTypes = {
  multiple: PropTypes.bool,
  buttonsShow: PropTypes.shape(),
  textValue: PropTypes.string,
  onChangeTextValue: PropTypes.func.isRequired,
  textStyle: PropTypes.shape().isRequired,
  onChangeTextStyle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Editor;
