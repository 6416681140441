import styled from 'styled-components';

export const Container = styled.button`
  height: 60px;
  min-height: 60px;
  border: none;

  color: ${(props) => props.theme.colors.white};
  padding: 5px 50px;

  background-color: ${(props) =>
    props.bordered
      ? 'transparent'
      : props.theme.colors[props.color ? props.color : 'primary']};

  border: ${(props) =>
    props.bordered
      ? `solid 1px ${props.theme.colors[props.color ? props.color : 'primary']}`
      : 'none'};
  border-radius: 30px;
  color: ${(props) =>
    props.bordered
      ? props.theme.colors[props.color ? props.color : 'primary']
      : props.theme.colors.white};

  :disabled {
    opacity: 0.7;
  }
`;
