import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  > svg {
    font-size: 32px;
    color: #171725;
  }
`;

export const Percentage = styled.span`
  color: ${(props) => (props.isPositive ? '#0FB691' : '#FF604E')};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
`;

export const Amount = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: #4a90e2;
  margin-top: 10px;
  margin-bottom: 40px;
`;
