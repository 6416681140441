import styled from 'styled-components';
import SelectComponent from 'react-select';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 332px;
  height: 37px;
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  border-radius: 3px;
  > input {
    height: 35px;
    flex: 1;
    border: 0;
    padding: 0 0 0 10px;
    background-color: #ffffff;
  }
  > button {
    height: 35px;
    width: 35px;
    border: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    > svg {
      color: #fc5a5a;
      font-size: 20px;
    }
  }
`;

export const Select = styled(SelectComponent).attrs({
  noOptionsMessage: () => 'Lista vazia',
  className: 'custom-select',
  classNamePrefix: 'custom-select',
  placeholder: 'Ordenar',
})`
  width: 120px;
  height: 35px;
  background-color: #ffffff;
  .custom-select__control {
    min-height: 35px;
    max-height: 35px;
    border-radius: 0;
    border: 0;
    border-right: 1px solid #d1d1d1;
    box-shadow: none;
    .custom-select__value-container {
      padding: 0;
      padding-left: 10px;
      height: 35px;
      .custom-select__placeholder,
      .custom-select__single-value,
      .custom-select__input input {
        font-size: 12px;
        font-weight: bold;
        color: #323236;
        font-family: Poppins;
      }
    }
    .custom-select__indicators {
      .custom-select__indicator-separator {
        display: none;
      }
    }
  }
  .custom-select__control:hover {
    border-color: #d1d1d1;
  }
  .custom-select__menu {
    .custom-select__menu-list {
      .custom-select__option {
        font-size: 12px;
        font-weight: normal;
        color: #323236;
        font-family: Poppins;
      }
      .custom-select__option--is-focused {
      }
      .custom-select__option--is-selected {
        color: #ffffff;
      }
    }
  }
`;
