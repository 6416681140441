import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  .custom-chart {
    .apexcharts-canvas {
      > svg {
        margin-left: -25px !important;
      }
    }
  }
`;

export const LegendBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: -25px !important;
  justify-content: center;
`;

export const LegendBoxItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 10px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  > svg {
    font-size: 14px;
    position: absolute;
    top: 5px;
    left: -15px;
  }
`;

export const LegendBoxItemPercentage = styled.div`
  color: ${(props) => (props.isPositive ? '#0FB691' : '#FF604E')};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
`;

export const LegendBoxItemAmount = styled.div`
  color: #0a0724;
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  small {
    font-weight: normal;
    margin-left: 5px;
  }
`;
