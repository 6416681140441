import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .chart-box-legend-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    > h2 {
      font-family: Poppins;
      font-size: 100px;
      font-weight: 500;
      line-height: 1;
      color: #171725;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-top: 20px;
        margin-right: 20px;
        color: #0a0724;
        font-size: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
