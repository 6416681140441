import React from 'react';

import PropTypes from 'prop-types';
import history from '~/services/history';
import {
  Base,
  Col,
  Row,
  Container,
  Label,
  WrapperButtons,
  ButtonCampain,
  Card,
  CardImage,
  CardContent,
  CardTitle,
  CardSubTitle,
  CardDescription,
} from './styles';

import Modal from '~/components/Modal';

import community from '~/assets/images/community.png';
import gettingStarted from '~/assets/images/gettingStarted.png';

function ModalBussinessPropagate({ onClose }) {
  const render = () => (
    <Modal visible width="1028" height="580" onClose={onClose}>
      <Container>
        <Col>
          <Base>
            <h1>
              Que tal divulgar <br />
              seu estabelecimento?
            </h1>

            <Label>
              Aproveite sua rede wifi para se comunicar com seus clientes!
            </Label>

            <p className="info">
              Antes de liberar a conexão grátis para os usuários, que tal exibir
              alguma oferta, desconto ou novo produto?
            </p>

            <p className="info">
              Use a sua criatividade para aumentar as vendas.
            </p>

            <WrapperButtons>
              <ButtonCampain
                title="Criar campanha"
                onClick={() => history.push('/propagate')}
              />
              <a href="#" onClick={onClose}>
                Agora não
              </a>
            </WrapperButtons>
          </Base>
        </Col>

        <Col>
          <Card>
            <CardImage src={community} />
            <CardContent>
              <CardTitle>Em tempo real</CardTitle>
              <CardSubTitle>
                Comunicar-se com quem irá utilizar a sua rede WiFi no seu
                estabelecimento, exibindo:
              </CardSubTitle>
              <CardDescription>
                Banner, vídeo ou pesquisa de satisfação, antes de liberar a
                conexão grátis
              </CardDescription>
            </CardContent>
          </Card>
        </Col>

        <Col>
          <Card>
            <CardImage src={gettingStarted} />
            <CardContent>
              <CardTitle>Mantenha contato</CardTitle>
              <CardSubTitle>
                Comunicar-se com clientes que já estiveram no seu
                estabelecimento via:
              </CardSubTitle>
              <CardDescription>SMS e e-mail</CardDescription>
            </CardContent>
          </Card>
        </Col>
      </Container>
    </Modal>
  );

  return render();
}

ModalBussinessPropagate.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalBussinessPropagate;
