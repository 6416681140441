import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FaChevronLeft } from 'react-icons/fa';

import history from '~/services/history';
import Button from '~/components/Button';
import { userSetState } from '~/store/modules/user/actions';
import ImageBackgroundStep3 from '~/assets/images/step-3.png';

import StepLink from '../../components/StepLink';

import { Container, Base, Select, Label, Input } from './styles';

function Step3() {
  const { onBoarding } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      userSetState({
        wizardProgress: 75,
        backgroundImage: ImageBackgroundStep3,
      })
    );
  }, []);

  const options = [
    { value: 'acougue', label: 'Açougue' },
    { value: 'bar', label: 'Bar' },
    { value: 'barbearia', label: 'Barbearia' },
    { value: 'cafeteria', label: 'Cafeteria' },
    { value: 'casaNoturna', label: 'Casa noturna' },
    { value: 'cervejaria', label: 'Cervejaria' },
    {
      value: 'concessionariaDeAutomoveis',
      label: 'Concessionária de automóveis',
    },
    { value: 'consultorioMedico', label: 'Consultório médico' },
    {
      value: 'dentistaEConsultorioOdontologico',
      label: 'Dentista e consultório odontológico',
    },
    { value: 'doceria', label: 'Doceria' },
    {
      value: 'estudioDeTatuagemEPiercing',
      label: 'Estúdio de tatuagem e piercing',
    },
    { value: 'foodTruck', label: 'Food truck' },
    { value: 'lanchonete', label: 'Lanchonete' },
    { value: 'livraria', label: 'Livraria' },
    {
      value: 'localParaEventosEApresentacoes',
      label: 'Local para eventos e apresentações',
    },
    { value: 'loja', label: 'Loja' },
    { value: 'lojaDeConveniencia', label: 'Loja de conveniência' },
    { value: 'mercearia', label: 'Mercearia' },
    { value: 'padaria', label: 'Padaria' },
    { value: 'restaurante', label: 'Restaurante' },
    { value: 'salaoDeBeleza', label: 'Salão de beleza' },
    { value: 'servicoAutomotivo', label: 'Serviço automotivo' },
    { value: 'supermercado', label: 'Supermercado' },
    { value: 'outro', label: 'Outro' },
  ];

  const render = () => (
    <Container>
      <Base>
        <StepLink>
          <Link to="/wizard/step2">
            <FaChevronLeft size={14} />
          </Link>
          <span>PASSO 3/4</span>
        </StepLink>
        <h1>Qual seu segmento de negócio?</h1>
        <div className="baseSegment">
          <Select
            placeholder="Escolha seu tipo de negócio"
            options={options}
            isClearable
            value={options.find(
              (item) => item.value === onBoarding.businessType
            )}
            onChange={(item) => {
              dispatch(
                userSetState({
                  onBoarding: {
                    ...onBoarding,
                    businessType: item.value,
                  },
                })
              );
            }}
          />
        </div>
        {onBoarding.businessType && onBoarding.businessType === 'Outro' && (
          <>
            <Label>Segmento</Label>
            <Input
              name="inputNomeRede"
              type="text"
              placeholder="Informe o segmento do seu negócio"
            />
          </>
        )}
        <p className="info">
          Você poderá alterar o tipo de negócio posteriormente em
          &quot;Configurações&quot;.
        </p>

        <Button
          title="Avançar"
          disabled={!onBoarding.businessType}
          onClick={() => history.push('/wizard/step4')}
        />
      </Base>
    </Container>
  );

  return render();
}

export default Step3;
