/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { FaChevronLeft } from 'react-icons/fa';
import { AiOutlineFileImage, AiFillEye } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import { userSetState } from '~/store/modules/user/actions';
import { propagateCampaignSave } from '~/store/modules/propagate/actions';
import history from '~/services/history';
import { fileToBase64 } from '~/utils';
import DropZoneUpload from '~/components/DropZoneUpload';
import SmartphonePreview from '~/components/SmartphonePreview';
import restaurant from '~/assets/images/avatar/restaurante.svg';

import {
  WrapPage,
  Container,
  BannerTitle,
  BannerSubtitle,
  InputWrap,
  ButtonNext,
  ButtonCancel,
  ContentBox,
  FormBox,
  PreviewBox,
  Legend,
  Description,
  Info,
  PreviewBanner,
  PreviewTitle,
  PreviewLogo,
  PreviewForm,
  PreviewDescription,
  PreviewDescriptionIcon,
  PreviewDescriptionContent,
} from './styles';

function Step2() {
  const [banner, setBanner] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userSetState({ wizardProgress: 100, backgroundImage: null }));
  }, []);

  const handleSetBanner = async (file) => {
    const base64File = file ? await fileToBase64(file) : null;
    setBanner(base64File);
  };

  return (
    <WrapPage>
      <Container>
        <BannerTitle>
          <Link to="/propagate/banner/step1">
            <FaChevronLeft size={14} />
          </Link>
          Banner
        </BannerTitle>
        <BannerSubtitle>PASSO 2/2</BannerSubtitle>
        <ContentBox>
          <FormBox>
            <Info>
              Configure o banner que vai ser exibido quando seu cliente entrar
              no seu portal.
            </Info>
            <Legend>Seu conteúdo</Legend>
            <Description style={{ marginBottom: 35 }}>
              A imagem deve ter a proporção de 16:9, com no mínimo 500px de
              largura nos formatos JPG, Gif, SVG ou PNG. Tamanho máximo: 2MB.
            </Description>
            <DropZoneUpload
              label="Selecione seu banner"
              title="Seu banner"
              modalTitle="Banner da sua campanha"
              modalLabel="Arraste e solte sua imagem aqui"
              fileUrl={banner}
              onChange={handleSetBanner}
              className="tour-step-2"
              cropImage="rect"
              aspect={16 / 9}
            />
            <Description style={{ marginTop: 35 }}>
              Sua campanha poderá ser editada futuramente na página principal.
            </Description>
            <InputWrap>
              <ButtonNext
                title="Publicar"
                onClick={() => dispatch(propagateCampaignSave())}
              />
              <ButtonCancel
                title="Cancelar"
                onClick={() => history.push('/propagate')}
              />
            </InputWrap>
          </FormBox>
          <PreviewBox>
            <PreviewDescription>
              <PreviewDescriptionIcon>
                <AiFillEye />
              </PreviewDescriptionIcon>
              <PreviewDescriptionContent>
                Simulação de como seu cliente irá visualizar a página de acesso
              </PreviewDescriptionContent>
            </PreviewDescription>
            <SmartphonePreview className="tour-step-4">
              <PreviewBanner>
                <PreviewTitle>Amelie Wifi</PreviewTitle>
                <PreviewLogo isDefault>
                  <img alt="Logo" src={restaurant} />
                </PreviewLogo>
              </PreviewBanner>
              <PreviewForm>
                <span>
                  Assista o vídeo a seguir para ganhar 00 minutos de acesso
                </span>
                <div className="preview-form-banner">
                  {!banner && <AiOutlineFileImage />}
                  {!!banner && <img src={banner} alt="Banner" />}
                </div>
                <button type="button">Continuar</button>
              </PreviewForm>
            </SmartphonePreview>
          </PreviewBox>
        </ContentBox>
      </Container>
    </WrapPage>
  );
}

export default withTheme(Step2);
