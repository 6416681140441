import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

function TourComponent({ ...rest }) {
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  return (
    <Tour
      {...rest}
      className="react-tour"
      rounded={5}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
    />
  );
}

TourComponent.propTypes = {
  theme: PropTypes.shape().isRequired,
};

export default withTheme(TourComponent);
