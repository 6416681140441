/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label } from './styles';

const Checkbox = ({
  label,
  value,
  onChange,
  disabled,
  color,
  style,
  styleLabel,
  className,
}) => (
  <Container
    disabled={disabled}
    style={style}
    hasLabel={!!label}
    color={color}
    className={className}
  >
    {!!label && (
      <Label className="check-label" style={styleLabel}>
        {label}
      </Label>
    )}
    <input
      type="checkbox"
      disabled={disabled}
      checked={value}
      onChange={() => {
        if (!disabled) {
          onChange(!value);
        }
      }}
    />
    <span className="check-box" />
  </Container>
);

Checkbox.defaultProps = {
  disabled: false,
  style: null,
  styleLabel: null,
  value: false,
  color: '',
};

Checkbox.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
  styleLabel: PropTypes.shape(),
  color: PropTypes.string,
};

export default Checkbox;
