import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  background-color: ${(props) => props.theme.colors.primary};
  width: 380px;
  height: 52px;
  border-radius: 4px;
  padding: 0 20px;
  > span {
    font-family: Poppins;
    font-weight: bold;
    font-size: 13px;
  }
  > svg {
  }
  .form-buttons {
    height: 38px;
    border-radius: 3px;
    border: solid 1px #ffffff;
    display: flex;
    button {
      width: 38px;
      height: 38px;
      border: 0;
      background-color: transparent;
      color: #ffffff;
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 18px;
      }
    }
  }
`;
