import styled from 'styled-components';
import ButtonComponent from '~/components/Button';

export const Container = styled.div`
  padding: 90px 70px;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;
`;

export const Col = styled.div`
  display: flex;
  margin-right: 20px;
`;

export const Base = styled.div`
  flex: 1;
  max-width: 400px;

  > h1 {
    font-size: 32px;
    line-height: 1.56;
  }

  .info {
    max-width: 380px;
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #323236;
    margin: 15px 0 30px 0;
  }
`;

export const Label = styled.p`
  font-family: Poppins;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  font-weight: bold;
  color: #323236;
  margin: 18px 0;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 45px 0;

  > a {
    color: #0062ff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    margin-left: 60px;
  }
`;

export const ButtonCampain = styled(ButtonComponent).attrs({
  style: {
    height: 60,
    minHeight: 60,
    paddingLeft: 20,
    paddingRight: 20,
    width: 157,
  },
})``;

export const Card = styled.div`
  width: 220px;
  height: 400px;
  border: 1px solid #e2e2ea;
  border-radius: 20px;
`;

export const CardImage = styled.img`
  width: 100%;
  max-width: 210px;
  height: 160px;
  object-fit: contain;
  object-position: center;
`;

export const CardContent = styled.div`
  padding: 0 17px 17px 17px;
`;

export const CardTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 18px;
`;

export const CardSubTitle = styled.span`
  font-family: Quicksand;
  font-size: 12px;
  color: #7f7f80;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0.09px;
  margin-top: 18px;
`;

export const CardDescription = styled.p`
  font-family: Quicksand;
  font-size: 12px;
  color: #7f7f80;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0.09px;
  margin-top: 20px;
`;
