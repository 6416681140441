import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  > svg {
    font-size: 32px;
    color: #92929d;
  }
`;

export const DeviceItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const DeviceItemIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  svg {
    font-size: 28px;
  }
`;

export const DeviceItemProgress = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  height: 14px;
  background-color: #8f92a120;
  border-radius: 7px;
`;

export const DeviceItemProgressBar = styled.div`
  display: flex;
  height: 14px;
  width: ${(props) => props.pergentage}%;
  background-color: ${(props) => props.color};
  border-radius: 7px;
`;

export const DeviceItemPergentage = styled.div`
  color: #1e1f20;
  font-size: 14px;
  font-weight: bold;
  width: 50px;
  padding-left: 10px;
`;
