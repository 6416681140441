import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { AiOutlineStar } from 'react-icons/ai';

import { Container } from './styles';

function ChartRangeType({ data }) {
  const config = {
    series: [
      {
        name: 'Respostas',
        data: data.answers.map((item) => item.answers),
      },
    ],
    options: {
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: data.answers.map((item) => item.option),
        labels: {
          show: false,
        },
      },
      fill: {
        colors: '#EB4335',
      },
    },
  };

  return (
    <Container>
      <div className="chart-box-legend-stars">
        <h2>0,0</h2>
        <div>
          <AiOutlineStar />
          <AiOutlineStar />
          <AiOutlineStar />
          <AiOutlineStar />
          <AiOutlineStar />
        </div>
      </div>
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="bar"
        height={300}
        width={600}
      />
    </Container>
  );
}

ChartRangeType.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ChartRangeType;
