import styled from 'styled-components';

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  > ul {
    display: flex;
    flex-direction: row;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      height: 37px;
      width: 37px;
      margin-right: 10px;
      a {
        cursor: pointer;
        color: ${(props) => props.theme.colors.blue2};
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 18px;
      }
    }
    .previous,
    .next {
      display: none;
    }
    .active {
      background-color: ${(props) => props.theme.colors.primary};
      border: 1px solid ${(props) => props.theme.colors.primary};
      border-radius: 50%;
      a {
        cursor: pointer;
        color: ${(props) => props.theme.colors.white};
      }
    }
    .disabled {
      display: none;
    }
  }
`;
