import styled from 'styled-components';
import ModalComponent from 'react-awesome-modal';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  width: 70px;
  height: 35px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColorBox = styled.div`
  width: 35px;
  height: 35px;
  background-color: ${(props) => props.color || props.theme.colors.white};
`;

export const ColorIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-left: 1px solid #d1d1d1;
  svg {
    color: #4a90e2;
    font-size: 20px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px 10px 10px;
`;

export const ModalFooterColorView = styled.div`
  display: flex;
  background: ${(props) => props.color || props.theme.colors.white};
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  width: 75px;
  height: 48px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  .custom-picker {
    background-color: ${(props) => props.theme.colors.white} !important;
    box-shadow: none !important;
  }
`;

export const Modal = styled(ModalComponent)`
  background-color: ${(props) => props.theme.colors.white};
`;
