import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  height: 48px;
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : '60')};
  /* color: #666360; */
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  display: flex;
  align-items: center;
  position: relative;

  .icon {
    color: ${(props) => props.theme.colors.black};
    font-size: 16px;
    position: absolute;
    left: 10px;
  }
`;

export const InputText = styled.input`
  height: 48px;
  padding: 0 16px;
  flex: 1;
  background: transparent;
  border: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};

  &::placeholder {
    color: #d1d1d1;
    text-align: ${(props) =>
      props.placeHolderAlign ? `${props.placeHolderAlign}` : 'start'};
  }
  ${(props) =>
    props.hasIcon
      ? css`
          padding-left: 30px;
        `
      : css`
          padding-left: 10px;
        `}
`;
