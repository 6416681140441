import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f2eb;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 220px;
  width: 100%;
  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    `}
`;

export const IconBox = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ffc54230;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  svg {
    color: #ffc542;
    font-size: 30px;
  }
`;

export const Wrap = styled.div`
  position: relative;
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DeleteButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 1px 1px 5px 0px #737373;
  border-radius: 50%;
  border: 0;
  z-index: 9;
  svg {
    color: #4d91ff;
    font-size: 20px;
  }
`;
