import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Percentage = styled.span`
  color: ${(props) => (props.isPositive ? '#0FB691' : '#FF604E')};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
`;

export const Amount = styled.span`
  font-size: 16px;
  font-weight: normal;
  color: #171725;
  margin-top: 10px;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: #696974;
  margin-top: 10px;
`;

export const IconBox = styled.div`
  display: flex;
  > svg {
    font-size: 32px;
    color: #171725;
  }
`;
