import styled from 'styled-components';
import color from 'color';
import ColorPickerComponent from '~/components/ColorPicker';
import step4PreviewBg from '~/assets/images/step4-preview-bg.jpg';

export const Container = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;
`;

export const PreviewBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 60px;
`;

export const Form = styled.div`
  display: flex;
  max-width: 480px;
  flex-direction: column;
  padding-bottom: 50px;
`;

export const BoxForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > h1 {
    font-size: 40px;
    margin: 20px 0;
  }

  .info {
    line-height: 30px;
    margin: 20px 0 0 0;
  }
`;

export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Sublabel = styled.p`
  font-weight: normal;
  margin-bottom: 5px;
`;

export const ColorBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > a {
    font-family: 'Poppins';
    font-size: 12px;
    line-height: 50px;
    color: #fa6115;
    text-decoration: none;
    margin: 20px 0 0 60px;
  }
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
`;

export const ColorPicker = styled(ColorPickerComponent).attrs({
  style: {
    marginLeft: 20,
  },
})``;

// start preview
export const BoxPreview = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const PreviewBanner = styled.div`
  background-image: url(${(props) => props.image || step4PreviewBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 125px;
  position: relative;
`;

export const PreviewForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 330px;
  max-height: 330px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -10px;
  background-color: #ffffff;
  padding: 20px;

  span {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: bold;
    color: #696974;
    margin-top: 60px;
    width: 100%;
  }

  label {
    font-family: 'Poppins';
    font-size: 11px;
    font-weight: bold;
    color: #191531;
    margin-top: 20px;
    width: 100%;
  }

  input {
    border: 1px solid #dbdbdb;
    background-color: #ffffff;
    font-size: 16px;
    color: #4e4e52;
    border-radius: 5px;
    margin-top: 5px;
    height: 40px;
    padding: 0 10px;
    width: 100%;
  }

  button {
    border: 0;
    background-color: ${(props) => props.color};
    color: ${(props) => (color(props.color).isLight() ? '#0A0724' : '#ffffff')};
    font-size: 14px;
    border-radius: 5px;
    margin-top: 50px;
    height: 35px;
    width: 150px;
    box-shadow: 1px 1px 5px 0px #737373;
  }
`;
// end preview
export const PreviewTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
`;

export const PreviewLogo = styled.div`
  width: 80px;
  height: 80px;
  bottom: -40px;
  padding: ${(props) => (props.isDefault ? 10 : 0)}px;

  border-radius: 50%;
  position: absolute;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    color: black;
  }
`;

export const PreviewDescription = styled.div`
  display: flex;
  width: 350px;
`;

export const PreviewDescriptionIcon = styled.div`
  display: flex;
  margin-right: 10px;
  > svg {
    font-size: 20px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const PreviewDescriptionContent = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.3;
  font-weight: 600;
  display: flex;
  flex: 1;
  text-align: justify;
`;
