import React from 'react';
import PropTypes from 'prop-types';
import { FiHome, FiUser } from 'react-icons/fi';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';

import { Container, Percentage, Amount, SubTitle, IconBox } from './styles';

function TotalAccess({ data }) {
  return (
    <Container>
      <IconBox>
        <FiHome />
        <FiUser />
      </IconBox>
      <Percentage isPositive={data.percentage >= 0}>
        {data.percentage >= 0 && '+'}
        {`${data.percentage}%`}
        {data.percentage >= 0 && <MdArrowUpward />}
        {data.percentage < 0 && <MdArrowDownward />}
      </Percentage>
      <Amount>{data.amount}</Amount>
      <SubTitle>Todos os acessos</SubTitle>
    </Container>
  );
}
TotalAccess.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default TotalAccess;
