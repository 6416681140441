import React from 'react';
import { components } from 'react-select';
import { IoIosArrowDown, IoMdClose } from 'react-icons/io';

import { Select } from './styles';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IoIosArrowDown color="#FA6115" size={20} />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <IoMdClose color="#FA6115" size={20} />
    </components.ClearIndicator>
  );
};

function SelectComponent(props) {
  return (
    <Select
      components={{ DropdownIndicator, ClearIndicator }}
      noOptionsMessage={() => 'Lista vazia'}
      className="custom-select"
      classNamePrefix="custom-select"
      {...props}
    />
  );
}

export default SelectComponent;
