import styled from 'styled-components';
import ButtonComponent from '~/components/Button';

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #d8d8d8;
  padding: 40px 30px 0 30px;
`;

export const ModalBody = styled.div``;

export const IconBox = styled.div`
  margin-right: 20px;
`;

export const UserBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.div`
  min-width: 57px;
  max-width: 57px;
  min-height: 57px;
  max-height: 57px;
  color: #ffffff;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserBoxName = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const UserBoxDocument = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
`;

export const UserBoxEmail = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
`;

export const UserBoxPhone = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 20px;
`;

export const ButtonBlockAccess = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    minHeight: 35,
    paddingLeft: 30,
    paddingRight: 30,
  },
})``;

export const ModalBodyTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin: 30px 0;
  padding-left: 30px;
`;

export const ModalBodyList = styled.div`
  padding: 0 30px;
  max-height: 145px;
  overflow-x: auto;
`;

export const ModalBodyListItem = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d8d8d8;
  position: relative;
  padding-left: 20px;
  padding-bottom: 30px;
  &:last-child {
    border: 0;
  }
  &:after {
    content: '';
    position: absolute;
    left: -6px;
    top: -1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.blue2};
    background-color: ${(props) => props.theme.colors.white};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  span {
    font-size: 13px;
    font-weight: 600;
    color: #102622;
  }
`;
