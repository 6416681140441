/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { FaChevronLeft, FaInfoCircle } from 'react-icons/fa';

import { AiFillEye } from 'react-icons/ai/index';
import ReactTooltip from 'react-tooltip';
import history from '~/services/history';
import Button from '~/components/Button';
import confirm from '~/components/Confirm';
import { userSetState } from '~/store/modules/user/actions';

import StepLink from '../../components/StepLink';

import {
  Container,
  Col,
  Base,
  Label,
  WrapperInput,
  Input,
  PreviewBanner,
  PreviewBox,
  PreviewDescription,
  PreviewDescriptionContent,
  PreviewDescriptionIcon,
  PreviewTitle,
  TooltipTitle,
  TooltipContent,
} from './styles';

import SmartphonePreview from '~/components/SmartphonePreview';
import smartphoneContent from './smartphone-content.svg';

function Step2({ theme }) {
  const { onBoarding } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userSetState({ wizardProgress: 50, backgroundImage: null }));
  }, []);

  const renderPreview = () => {
    return (
      <PreviewBox>
        <PreviewDescription>
          <PreviewDescriptionIcon>
            <AiFillEye />
          </PreviewDescriptionIcon>
          <PreviewDescriptionContent>
            Simulação de como seu cliente irá visualizar o nome da sua rede
          </PreviewDescriptionContent>
        </PreviewDescription>
        <SmartphonePreview>
          <PreviewBanner image={smartphoneContent}>
            {onBoarding.networkName ? (
              <PreviewTitle>{onBoarding.networkName}</PreviewTitle>
            ) : (
              <PreviewTitle>O nome da sua rede</PreviewTitle>
            )}
          </PreviewBanner>
        </SmartphonePreview>
      </PreviewBox>
    );
  };

  const render = () => (
    <>
      <Container>
        <Col>
          <Base>
            <StepLink>
              <Link to="/wizard">
                <FaChevronLeft size={14} />
              </Link>
              <span>PASSO 2/4</span>
            </StepLink>

            <h1>Como você vai chamar sua rede Wi-Fi?</h1>

            <Label>Nome da sua rede</Label>

            <WrapperInput>
              <Input
                name="inputNomeRede"
                type="text"
                placeholder="Digite o nome da sua rede"
                value={onBoarding.networkName}
                onChange={(e) => {
                  dispatch(
                    userSetState({
                      onBoarding: {
                        ...onBoarding,
                        networkName: e.target.value,
                      },
                    })
                  );
                }}
                maxLength={30}
              />

              <a data-tip data-for="test">
                <ReactTooltip
                  id="test"
                  place="left"
                  backgroundColor="#FFFFFF"
                  textColor="#323232"
                  border
                  borderColor="#d1d1d1"
                  className="tooltip-wrap"
                >
                  <TooltipTitle>Informações:</TooltipTitle>
                  <TooltipContent>
                    É permitido no máximo 30 caracteres e não pode inserir
                    acentuação. Obrigado.
                  </TooltipContent>
                </ReactTooltip>
                <FaInfoCircle data-for="test" />
              </a>
            </WrapperInput>

            <p className="info">
              Esse é o nome que aparecerá quando seu cliente acessar a sua rede
              WiFi
            </p>

            <Button
              title="Avançar"
              disabled={!onBoarding.networkName}
              onClick={() => {
                confirm({
                  theme,
                  title: 'Atenção',
                  close: false,
                  message: 'A criação de sua rede WIFI pode demorar até 24h',
                  button: {
                    label: 'Ok, entendi',
                    onClick: () => history.push('/wizard/step3'),
                  },
                });
              }}
            />
          </Base>
        </Col>
        <Col>{renderPreview()}</Col>
      </Container>
    </>
  );

  return render();
}

Step2.propTypes = {
  theme: PropTypes.shape().isRequired,
};

export default withTheme(Step2);
