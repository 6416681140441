import styled from 'styled-components';

export const Container = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #f4f2eb;
  overflow: hidden;
  padding: 30px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;
