import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function ProfilePic({ profilePic }) {
  return (
    <Container>
      <img src={profilePic} alt="profilePic" />
    </Container>
  );
}

ProfilePic.propTypes = {
  profilePic: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
    .isRequired,
};

export default ProfilePic;
