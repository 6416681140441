import styled from 'styled-components';
import ButtonComponent from '~/components/Button';
import TableComponent from '~/components/Table';
import SelectComponent from '~/components/Select';
import CheckboxComponent from '~/components/Checkbox';
import imageBackground from '~/assets/images/step-3.png';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px;
`;

export const Row1 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const Row1Content = styled.div`
  width: 100%;
  min-height: 240px;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 40px;
  padding: 30px 70px;
  background-image: url(${imageBackground});
  background-repeat: no-repeat;
  background-size: 220px;
  background-position-x: calc(100% - 100px);
  background-position-y: 30px;
  > h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    color: #0a0724;
    max-width: 600px;
    line-height: 1.11;
  }
  > p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.46;
    letter-spacing: 0.12px;
    color: #323236;
    margin: 10px 0 25px 0;
  }
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row2Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 20px 0;
  > span {
    font-family: Quicksand;
    font-size: 24px;
    font-weight: normal;
    line-height: 2.08;
    color: #0a0724;
  }
`;

export const Row2Content = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const Row3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row3Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 20px 0;
  > span {
    font-family: Quicksand;
    font-size: 24px;
    font-weight: normal;
    line-height: 2.08;
    color: #0a0724;
  }
`;

export const Row3Content = styled.div`
  .campaign-calendar {
    min-height: ${(props) => (props.viewType === 'month' ? 700 : 400)}px;
    position: relative;
    background-color: #ffffff;
    padding: 20px;
    .rbc-time-view {
      border: 0;
      .rbc-time-header {
        > .rbc-time-header-gutter {
          display: none;
        }
        > .rbc-time-header-content {
          border-left: 0;
          min-height: 300px;
          .rbc-allday-cell {
            .rbc-row-bg {
              .rbc-day-bg {
                border-left: 1px solid #d0d5d9;
              }
              .rbc-day-bg:first-child {
                border-left: 0;
              }
            }
          }
          .rbc-time-header-cell {
            .rbc-header {
              border-bottom: 0;
              border-left: 1px solid #d0d5d9;
              font-family: Poppins;
              font-size: 10px;
              font-weight: normal;
              letter-spacing: 1.84px;
              color: #32353a60;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              text-transform: capitalize;
            }
            .rbc-header:first-child {
              border-left: 0;
            }
          }
        }
      }
      .rbc-time-content {
        border-top: 0;
        display: none;
        > * + * > * {
          border-left: 0;
        }
        > .rbc-time-gutter {
          display: none;
        }
        .rbc-day-slot {
          border-left: 1px solid #d0d5d9;
          .rbc-timeslot-group {
            border-bottom: 0;
            .rbc-time-slot {
              border: 0;
            }
          }
        }
        .rbc-day-slot:nth-child(2) {
          border-left: 0;
        }
      }
    }
    .rbc-month-view {
      border: 0;
      .rbc-header {
        border: 0;
        text-align: left;
        opacity: 0.6;
        font-family: Poppins;
        font-size: 9.2px;
        font-weight: normal;
        letter-spacing: 1.84px;
        color: #32353a;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0;
        text-transform: capitalize;
      }
      .rbc-month-row {
        .rbc-row-bg {
          .rbc-off-range-bg {
            background: transparent;
          }
          .rbc-today {
            background: transparent;
          }
        }
        .rbc-row-content {
          .rbc-row {
            .rbc-date-cell {
              text-align: left;
              padding-left: 5px;
              a {
                font-family: Poppins;
                font-size: 11px;
                font-weight: normal;
                letter-spacing: 0.33px;
                color: #aaadb9;
              }
            }
            .rbc-row-segment {
              a.rbc-show-more {
                font-family: Poppins;
                font-size: 9px;
                font-weight: bold;
                line-height: 3.2;
                color: #26272a;
                text-decoration: none;
                padding-left: 10px;
                height: 17px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
`;

export const RowTable = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  > h2 {
    font-family: Quicksand;
    font-size: 24px;
    font-weight: normal;
    line-height: 2.08;
    color: #0a0724;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 35px;
`;

export const ButtonCreateCampaign = styled(ButtonComponent).attrs({
  style: {
    height: 40,
    minHeight: 40,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 10,
    paddingBottom: 10,
    width: 180,
  },
})``;

export const Table = styled(TableComponent)`
  thead {
    tr {
      th {
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  tbody {
    tr {
      cursor: auto;

      td {
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: normal;

        a {
          font-family: 'Poppins';
          font-size: 12px;
          font-weight: 600;
          color: #0062ff;
        }
      }

      td.nowrap {
        white-space: nowrap;
      }
    }
  }
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
`;

export const TabbleActionButton = styled.button.attrs({
  type: 'button',
})`
  background-color: transparent;
  border: 0;
  padding: 0;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 600;
  color: #0062ff;
  margin-right: 40px;
  white-space: nowrap;
  &:last-child {
    margin-right: 0px;
  }
`;

export const Checkbox = styled(CheckboxComponent).attrs((props) => ({
  color: props.theme.colors.blue2,
}))``;

export const PropagateCard = styled.div`
  height: 146px;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 22px;
  padding: 25px;
  min-width: 320px;
  &:last-child {
    margin-right: 0;
  }
  span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    line-height: 2.36;
    color: #0f2522;
    display: flex;
    align-items: center;
    > svg {
      margin-left: 5px;
      font-size: 14px;
    }
  }
  h2 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 0.71;
    color: #0f2522;
    margin: 10px 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    li {
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      line-height: 2.36;
      color: #0f2522;
      margin-right: 18px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const Select = styled(SelectComponent)`
  width: 250px;
  height: 40px;
  margin-left: 15px;
  .custom-select__control {
    height: 40px;
    .custom-select__value-container {
      .custom-select__placeholder,
      .custom-select__single-value,
      .custom-select__input input {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.06px;
        color: #323236;
      }
    }
  }
  .custom-select__menu {
    z-index: 999999999;
    .custom-select__menu-list {
      .custom-select__group {
        .custom-select__group-heading {
          font-family: Poppins !important;
          font-size: 12px !important;
          font-weight: bold !important;
          letter-spacing: 0.06px !important;
          color: #323236 !important;
        }
        .custom-select__option {
          font-family: Poppins !important;
          font-size: 12px !important;
          letter-spacing: 0.06px !important;
          color: #323236 !important;
          cursor: pointer;
        }
      }
    }
  }
`;

export const CalendarToolbar = styled.div`
  display: flex;
  width: 100%;
  padding: 6px;
  justify-content: space-between;
  > h2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.67;
    color: #26272a;
    text-transform: capitalize;
  }
  > button {
    position: absolute;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border: 0;
    background-color: transparent;
    > svg {
      font-size: 44px;
      color: #fc5a5a;
    }
  }
  > button.calendar-prev-button {
    left: -44px;
    top: 50%;
  }

  > button.calendar-next-button {
    right: -44px;
    top: 50%;
  }
  .calendar-legend-colors {
    display: flex;
    align-items: center;
  }
`;

export const CalendarLegendColorsItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  > div {
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    svg {
      font-size: 10px;
      color: #0a0724;
    }
  }
  span {
    font-family: Poppins;
    font-size: 11px;
    font-weight: normal;
    line-height: 2.17;
    color: #26272a;
  }
`;
