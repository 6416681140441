import React from 'react';
import PropTypes from 'prop-types';
import { FiUser } from 'react-icons/fi';

import { getInitialsFromName } from '../../../../../../utils';

import {
  Container,
  AmountBox,
  Amount,
  SubTitle,
  ListVisitors,
  ListVisitorsItem,
  ListVisitorsItemIcon,
  VisitorsTitle,
} from './styles';

const colors = {
  M: '#FC5A5A',
  F: '#FFC542',
  O: '#4A90E2',
};

function TotalAccess({ data }) {
  return (
    <Container>
      <AmountBox>
        <FiUser />
        <Amount>{data.amount}</Amount>
      </AmountBox>
      <SubTitle>Clientes online</SubTitle>
      <VisitorsTitle>Últimos visitantes</VisitorsTitle>
      <ListVisitors>
        {data.lastVisitors.map((item) => (
          <ListVisitorsItem key={item.name}>
            <ListVisitorsItemIcon color={colors[item.gender]}>
              {getInitialsFromName(item.name)}
            </ListVisitorsItemIcon>
          </ListVisitorsItem>
        ))}
      </ListVisitors>
    </Container>
  );
}
TotalAccess.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default TotalAccess;
