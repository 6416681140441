import React from 'react';
import PropTypes from 'prop-types';
import { TiArrowShuffle } from 'react-icons/ti';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';

import { Container, Amount, Percentage } from './styles';

function DataTrafficPerCustomer({ data }) {
  return (
    <Container>
      <TiArrowShuffle />
      <Percentage isPositive={data.percentage >= 0}>
        {data.percentage >= 0 && '+'}
        {`${data.percentage}%`}
        {data.percentage >= 0 && <MdArrowUpward />}
        {data.percentage < 0 && <MdArrowDownward />}
      </Percentage>
      <Amount>{`${data.amount} MB`}</Amount>
    </Container>
  );
}
DataTrafficPerCustomer.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default DataTrafficPerCustomer;
