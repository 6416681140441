import React from 'react';
import PropTypes from 'prop-types';

import { Wrap, Container, ButtonItem, Label } from './styles';

function GroupButtons({ options, value, onChange, label }) {
  return (
    <Wrap>
      {!!label && <Label>{label}</Label>}
      <Container>
        {options.map((item) => (
          <ButtonItem
            key={item.value}
            onClick={() => onChange(item.value)}
            active={value === item.value}
          >
            {item.label}
          </ButtonItem>
        ))}
      </Container>
    </Wrap>
  );
}

GroupButtons.defaultProps = {
  value: null,
  label: '',
};

GroupButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default GroupButtons;
