import styled, { css } from 'styled-components';
import SelectComponent from '~/components/Select';
import InputComponent from '~/components/Input';
import CheckToggleComponent from '~/components/CheckToggle';
import Button from '~/components/Button';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 30px 80px 50px 80px;
  h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.39;
    color: #0a0724;
  }
  .header-box-viewmode {
    position: absolute;
    right: 80px;
    top: 100px;
  }
  b {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #0a0724;
    margin-top: 30px;
  }
  span {
    width: 504px;
    height: 22px;
    opacity: 0.53;
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.1px;
    color: #323236;
    margin-top: 5px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  ${(props) =>
    props.mode === 'desktop' &&
    css`
      background-image: url(${props.bgPic});
      background-repeat: no-repeat;
      background-size: cover;
    `}
  > button.button-edit {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0a0724;
    color: #ffffff;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .content-header {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    width: 100%;
    max-width: 380px;
    ${(props) =>
      props.mode === 'mobile' &&
      css`
        background-image: url(${props.bgPic});
        background-repeat: no-repeat;
        background-size: cover;
      `}
  }
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 380px;
    min-height: 350px;
    max-height: 350px;
    border-radius: 10px;
    border: 2px solid #ffffff;
    background-color: #ffffff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    padding: 0 ${(props) => (props.mode === 'mobile' ? 20 : 40)}px;
    > span {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: bold;
      color: #696974;
      margin-top: 60px;
      width: 100%;
    }

    > label {
      font-family: 'Poppins';
      font-size: 11px;
      font-weight: bold;
      color: #191531;
      margin-top: 20px;
      width: 100%;
    }

    > input {
      border: 1px solid #dbdbdb;
      background-color: #ffffff;
      font-size: 16px;
      color: #4e4e52;
      border-radius: 5px;
      margin-top: 5px;
      height: 40px;
      padding: 0 10px;
      width: 100%;
    }

    > button {
      border: 0;
      background-color: #0062ff;
      color: #ffffff;
      font-size: 14px;
      border-radius: 5px;
      margin-top: 50px;
      height: 35px;
      width: 150px;
      box-shadow: 1px 1px 5px 0px #737373;
    }
  }
`;

export const PreviewTitle = styled.div`
  font-family: Poppins;
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
`;

export const PreviewLogo = styled.div`
  width: 80px;
  height: 80px;
  padding: ${(props) => (props.isDefault ? 10 : 0)}px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  position: absolute;
  top: -40px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    color: black;
    ${(props) =>
      !props.isDefault &&
      css`
        border-radius: 50%;
        overflow: hidden;
      `}
  }
  > button.button-edit {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0a0724;
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    top: 40px;
    right: -8px;
  }
`;

export const FormBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 40px 80px;
  .form-box-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80px;
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-bottom: 15px;
    position: relative;
    > h2 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.1px;
      text-align: left;
      color: #0a0724;
    }
    > button.button-open-close {
      position: absolute;
      right: 48px;
      top: 20px;
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: fit-content;
      height: fit-content;
      font-size: 40px;
    }
  }
`;

export const SelectType = styled(SelectComponent)`
  width: 400px;
  margin-top: 10px;
`;

export const InputName = styled(InputComponent)`
  width: 400px;
  margin-top: 10px;
`;

export const InputEmail = styled(InputComponent)`
  width: 400px;
`;

export const CheckToggle = styled(CheckToggleComponent)`
  .react-custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: #0062ff;
  }
`;

export const Label = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
  text-align: left;
  color: #0a0724;
`;

export const InputEmailList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
`;

export const InputEmailListItem = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: space-between;
  > span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: #171725;
  }
  > button {
    padding: 0;
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #0062ff;
    border: 0;
    background-color: transparent;
  }
`;

export const InputEmailBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > button {
    width: fit-content;
    height: 48px;
    background-color: #fc5a5a;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    color: #fafafb;
    border: 0;
    border-radius: 30px;
    padding: 0 30px;
    margin: 0 0 0 18px;
    &:disabled {
      opacity: 0.7;
    }
  }
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  > .input-wrap-body {
    display: flex;
    flex-direction: column;
    > span {
      opacity: 0.53;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
      letter-spacing: 0.1px;
      text-align: left;
      color: #323236;
      margin-bottom: 25px;
    }
    > .toggle-input-box {
      width: 400px;
      height: 48px;
      border-radius: 4px;
      border: solid 1px #d1d1d1;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      > span {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.07px;
        color: #0a0724;
        display: flex;
        align-items: center;
        svg {
          margin-left: 10px;
        }
      }
      &[disabled] {
        opacity: 0.7;
      }
    }
  }
`;

export const ButtonSave = styled(Button)``;

export const ButtonCancel = styled(Button)`
  color: #fa6115;
  background-color: transparent;
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  border: 0;
`;
