import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    background: #fff;
    color: #0a0725;
    font-family: Quicksand, Arial, sans-serif;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input {
    font-size: 16px;
  }

  h1,h2,h3,h4,h5 {
    font-family: Poppins;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
  }

  button {
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins, sans-serif;

    cursor: pointer;
  }

  #react-confirm-alert {
    .react-confirm-alert-overlay {
      background-color: rgba(0, 0, 0, 0.7) !important;
    }
  }

  .react-tour {
    .reactour__close {
      top: 10px;
      right: 10px;
    }
  }
`;
