import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const Pages = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;

  /* background: blueviolet; */

  ${(props) =>
    props.backgroundImage &&
    css`
      &::before {
        /* z-index: 1; */
        width: 994px;
        height: 656px;
        content: ' ';
        background-image: url(${props.backgroundImage});
        position: absolute;
        top: 24px;
        right: 0;
      }

      @media (min-width: 1410px) {
        &::before {
          right: 50px;
        }
      }

      @media (min-width: 2000px) {
        &::before {
          right: 300px;
        }
      }

      @media (min-width: 2300px) {
        &::before {
          right: 400px;
        }
      }

      @media (max-width: 1410px) {
        &::before {
          width: 800px;
          height: 656px;
        }
      }

      @media (max-width: 1250px) {
        &::before {
          width: 850px;
          height: 656px;
        }
      }

      @media (max-width: 1180px) {
        &::before {
          width: 780px;
          height: 656px;
        }
      }

      @media (max-width: 1030px) {
        &::before {
          width: 754px;
          height: 656px;
        }
      }
    `}
`;

export const Body = styled.div`
  width: 100%;
  padding: 0 25px;
  max-width: 1100px;
`;
