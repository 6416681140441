import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

// wizard pages
import Step1 from '~/pages/Onboarding/pages/Step1';
import Step2 from '~/pages/Onboarding/pages/Step2';
import Step3 from '~/pages/Onboarding/pages/Step3';
import Step4 from '~/pages/Onboarding/pages/Step4';

// dashboard pages
import Main from '~/pages/Dashboard/pages/Main';
import Visitors from '~/pages/Dashboard/pages/Visitors';
import Settings from '~/pages/Dashboard/pages/Settings';
import Propagate from '~/pages/Dashboard/pages/Propagate';
import SupportList from '~/pages/Dashboard/pages/Support/List';
import SupportDetails from '~/pages/Dashboard/pages/Support/Details';
import SupportContactUs from '~/pages/Dashboard/pages/Support/ContactUs';
import BannerStep1 from '~/pages/Dashboard/pages/Propagate/pages/Banner/Step1';
import BannerStep2 from '~/pages/Dashboard/pages/Propagate/pages/Banner/Step2';
import VideoStep1 from '~/pages/Dashboard/pages/Propagate/pages/Video/Step1';
import VideoStep2 from '~/pages/Dashboard/pages/Propagate/pages/Video/Step2';
import ResearchStep1 from '~/pages/Dashboard/pages/Propagate/pages/Research/Step1';
import ResearchStep2 from '~/pages/Dashboard/pages/Propagate/pages/Research/Step2';
import SmsStep1 from '~/pages/Dashboard/pages/Propagate/pages/Sms/Step1';
import SmsStep2 from '~/pages/Dashboard/pages/Propagate/pages/Sms/Step2';
import SmsStep3 from '~/pages/Dashboard/pages/Propagate/pages/Sms/Step3';
import EmailStep1 from '~/pages/Dashboard/pages/Propagate/pages/Email/Step1';
import EmailStep2 from '~/pages/Dashboard/pages/Propagate/pages/Email/Step2';
import EmailStep3 from '~/pages/Dashboard/pages/Propagate/pages/Email/Step3';
import PropagateBlank from '~/pages/Dashboard/pages/Propagate/pages/Blank';
import CampaignResult from '~/pages/Dashboard/pages/Propagate/pages/Result';

export default function Routes() {
  return (
    <Switch>
      {/* wizard routes */}
      <Route exact layout="wizard" path="/wizard/" component={Step1} />
      <Route exact layout="wizard" path="/wizard/step2" component={Step2} />
      <Route exact layout="wizard" path="/wizard/step3" component={Step3} />
      <Route exact layout="wizard" path="/wizard/step4" component={Step4} />

      {/* dashboard routes */}
      <Route exact layout="dashboard" path="/" component={Main} />
      <Route exact layout="dashboard" path="/visitors" component={Visitors} />
      <Route exact layout="dashboard" path="/settings" component={Settings} />
      <Route exact layout="dashboard" path="/propagate" component={Propagate} />
      <Route exact layout="dashboard" path="/support" component={SupportList} />
      <Route
        exact
        layout="dashboard"
        path="/support/contact-us"
        component={SupportContactUs}
      />
      <Route
        exact
        layout="dashboard"
        path="/support/:id"
        component={SupportDetails}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/banner/step1"
        component={BannerStep1}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/banner/step2"
        component={BannerStep2}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/video/step1"
        component={VideoStep1}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/video/step2"
        component={VideoStep2}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/research/step1"
        component={ResearchStep1}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/research/step2"
        component={ResearchStep2}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/sms/step1"
        component={SmsStep1}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/sms/step2"
        component={SmsStep2}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/sms/step3"
        component={SmsStep3}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/email/step1"
        component={EmailStep1}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/email/step2"
        component={EmailStep2}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/email/step3"
        component={EmailStep3}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/first"
        component={PropagateBlank}
      />
      <Route
        exact
        layout="dashboard"
        path="/propagate/:id/result"
        component={CampaignResult}
      />
    </Switch>
  );
}
