import { all, takeLatest, put, select, call } from 'redux-saga/effects';

import types from './types';
import { dashboardSetState } from './actions';
import {
  setIsLoading,
  // setIsSuccess,
  setIsError,
} from '~/store/modules/loading/actions';

import api from '../../../services/api';

export function* dataRequest() {
  const {
    dashboard: { filter },
  } = yield select();
  yield put(setIsLoading(true));

  try {
    const {
      data: { data },
    } = yield call(api.get, `dashboard/${filter.period}`);
    yield put(dashboardSetState({ dashboardData: data }));
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsError(true));
  }
}

export default all([takeLatest(types.DASHBOARD_DATA_REQUEST, dataRequest)]);
