import React from 'react';
import PropTypes from 'prop-types';

import { Container, Body } from './styles';

function DashboardCard({ title, children, flex, spaceRight, spaceLeft }) {
  return (
    <Container flex={flex} spaceRight={spaceRight} spaceLeft={spaceLeft}>
      <h3>{title}</h3>
      <Body>{children}</Body>
    </Container>
  );
}

DashboardCard.defaultProps = {
  flex: 1,
  spaceRight: 0,
  spaceLeft: 0,
};

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  flex: PropTypes.number,
  spaceRight: PropTypes.number,
  spaceLeft: PropTypes.number,
};

export default DashboardCard;
