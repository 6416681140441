import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowUpward, MdArrowDownward, MdTimer } from 'react-icons/md';

import { Container, Percentage, Amount, SubTitle } from './styles';

function ConnectionTime({ data }) {
  return (
    <Container>
      <MdTimer />
      <Percentage isPositive={data.percentage >= 0}>
        {data.percentage >= 0 && '+'}
        {`${data.percentage}%`}
        {data.percentage >= 0 && <MdArrowUpward />}
        {data.percentage < 0 && <MdArrowDownward />}
      </Percentage>
      <Amount>{data.amount}</Amount>
      <SubTitle>Tempo médio por cliente</SubTitle>
    </Container>
  );
}
ConnectionTime.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ConnectionTime;
