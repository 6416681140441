import React from 'react';
import PropTypes from 'prop-types';

import history from '~/services/history';
import { getInitialsFromName } from '~/utils';

import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemContent,
  ListItemContentName,
  ListItemContentEmail,
  Separator,
  ShowMoreButton,
} from './styles';

const colors = {
  M: '#FC5A5A',
  F: '#FFC542',
  O: '#4A90E2',
};

function TotalAccess({ data }) {
  return (
    <Container>
      <List>
        {data.map((item) => (
          <ListItem key={item.email}>
            <ListItemIcon color={colors[item.gender]}>
              {getInitialsFromName(item.name)}
            </ListItemIcon>
            <ListItemContent>
              <ListItemContentName>{item.name}</ListItemContentName>
              <ListItemContentEmail>{item.email}</ListItemContentEmail>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
      <Separator />
      <ShowMoreButton onClick={() => history.push('/visitors')}>
        Ver mais
      </ShowMoreButton>
    </Container>
  );
}
TotalAccess.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default TotalAccess;
