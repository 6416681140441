import React from 'react';
import PropTypes from 'prop-types';

import FormAddField from './components/FormAddField';
import FormItems from './components/FormItems';

function Form({ formItems, onChange, isInTourMode }) {
  return (
    <>
      <FormAddField onAdd={(item) => onChange([...formItems, item])} />
      <FormItems
        onChange={onChange}
        formItems={formItems}
        isInTourMode={isInTourMode}
      />
    </>
  );
}

Form.defaultProps = {
  isInTourMode: false,
};

Form.propTypes = {
  formItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  isInTourMode: PropTypes.bool,
};

export default Form;
