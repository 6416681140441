import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  h1 {
    font-size: 32px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 50px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;
