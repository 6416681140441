import types from './types';

export function supportSetState(state) {
  return {
    type: types.SUPPORTS_SET_STATE,
    payload: { state },
  };
}

export function supportDataRequest() {
  return {
    type: types.SUPPORTS_DATA_REQUEST,
  };
}

export function supportGetRequest(id) {
  return {
    type: types.SUPPORTS_GET_REQUEST,
    payload: { id },
  };
}
