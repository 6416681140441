import React, { useState } from 'react';
import Popover from 'react-tiny-popover';
import PropTypes from 'prop-types';
import { FaRegCalendar } from 'react-icons/fa';
import moment from 'moment';

import history from '~/services/history';
import { CampaignBox, ButtonOpen } from './styles';

function PopupCampaignDetails({ campaign }) {
  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const renderButton = (ref) => (
    <ButtonOpen
      type="button"
      ref={ref}
      onClick={() => setNotificationIsOpen(!notificationIsOpen)}
    >
      {campaign.event.title}
    </ButtonOpen>
  );

  const getCampaignType = () => {
    switch (campaign.event.type) {
      case 'video':
        return 'Vídeo';
      case 'research':
        return 'Pesquisa';
      case 'banner':
        return 'Banner';
      case 'sms':
        return 'SMS';
      case 'email':
        return 'E-mail';
      default:
        return '';
    }
  };

  return (
    <Popover
      isOpen={notificationIsOpen}
      position={['right', 'left']}
      padding={5}
      onClickOutside={() => setNotificationIsOpen(false)}
      content={() => (
        <CampaignBox>
          <h2>{campaign.event.title}</h2>
          <h3>{getCampaignType()}</h3>
          <span>
            <FaRegCalendar />
            Início da campanha:
            <small>
              {moment(campaign.event.realDate.start).format(
                'DD/MM/YYYY HH:mm:ss'
              )}
            </small>
          </span>
          <span>
            <FaRegCalendar />
            Fim da campanha:
            <small>
              {moment(campaign.event.realDate.end).format(
                'DD/MM/YYYY HH:mm:ss'
              )}
            </small>
          </span>
          <div className="popup-footer">
            <button
              className="button-report"
              type="button"
              onClick={() =>
                history.push(`/propagate/${campaign.event.id}/result`)
              }
            >
              Publicar
            </button>
            <button className="button-edit" type="button">
              Editar
            </button>
          </div>
        </CampaignBox>
      )}
    >
      {renderButton}
    </Popover>
  );
}

PopupCampaignDetails.propTypes = {
  campaign: PropTypes.shape().isRequired,
};

export default PopupCampaignDetails;
