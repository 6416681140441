import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaChevronLeft } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import backgroundList from './backgrounds';
import avatarList from './avatars';

import Tour from '~/components/Tour';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { userSetState, userSetupSave } from '~/store/modules/user/actions';

import StepLink from '../../components/StepLink';

import DropZoneUpload from '~/components/DropZoneUpload';
import SmartphonePreview from '~/components/SmartphonePreview';
import { fileToBase64 } from '~/utils';

import {
  Container,
  PreviewBox,
  Form,
  BoxForm,
  Label,
  Sublabel,
  UploadBox,
  ColorBox,
  WrapperButtons,
  ColorPicker,
  PreviewBanner,
  PreviewForm,
  PreviewTitle,
  PreviewLogo,
  PreviewDescription,
  PreviewDescriptionIcon,
  PreviewDescriptionContent,
} from './styles';

function Step4({ theme }) {
  const { onBoarding } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [color, setColor] = useState('#095cff');
  const [logo, setLogo] = useState(null);
  const [background, setBackground] = useState(null);

  // TOUR STEPS
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    {
      selector: '.tour-step-1',
      content: 'Este é o nome que vai aparecer na sua página',
    },
    {
      selector: '.tour-step-2',
      content: 'Escolha uma imagem de perfil.',
    },
    {
      selector: '.tour-step-3',
      content: 'E depois escolha uma cor.',
    },
    {
      selector: '.tour-step-4',
      content: 'Seus clientes irão visualizar a sua página dessa forma',
    },
    {
      selector: '.tour-step-5',
      content:
        'Pronto! Agora é só publicar. A qualquer momento você poderá editar mais funcionalidades dentro de "configurações".',
    },
  ];

  useEffect(() => {
    dispatch(userSetState({ wizardProgress: 100, backgroundImage: null }));
    setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const handleSetLogo = async (file) => {
    const base64File = file ? await fileToBase64(file) : null;
    setLogo(base64File);
  };

  const handleSetBackground = async (file) => {
    const base64File = file ? await fileToBase64(file) : null;
    setBackground(base64File);
  };

  const renderForm = () => {
    return (
      <Form>
        <StepLink>
          <Link to="/wizard/step3">
            <FaChevronLeft size={14} />
          </Link>
          <span>PASSO 4/4</span>
        </StepLink>
        <BoxForm>
          <h1>Personalize sua página de acesso ao WiFi</h1>
          <div className="tour-step-1">
            <Label>Nome do seu negócio</Label>
            <Input
              name="inputNomeRede"
              type="text"
              placeholder="Digite o nome do seu negócio"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              maxLength={32}
              className="tour-step-1"
            />
          </div>
          <Label style={{ marginTop: 20 }}>Suas imagens</Label>
          <Sublabel>Formato JPG, GIF, SVG ou PNG</Sublabel>
          <UploadBox>
            <DropZoneUpload
              label="Selecione a imagem da sua marca."
              title="Sua marca"
              modalTitle="Imagem da sua marca"
              modalLabel="Arraste e solte sua imagem aqui"
              fileUrl={logo}
              onChange={handleSetLogo}
              className="tour-step-2"
              cropImage="round"
            />
          </UploadBox>
          <ColorBox>
            <Label>Selecione suas cores</Label>
            <ColorPicker
              value={color}
              onChange={(hex) => {
                setColor(hex);
              }}
              className="tour-step-3"
            />
          </ColorBox>
          <p className="info">
            Sua página de acesso será publicada e você poderá editá-la a
            qualquer momento em configurações
          </p>
          <WrapperButtons>
            <Button
              title="Publicar"
              onClick={() => {
                dispatch(userSetupSave());
              }}
              style={{
                width: 200,
                marginTop: 20,
              }}
              className="tour-step-5"
            />
            <a
              className="tour-step-1"
              href="#"
              onClick={() => setTurIsOpen(true)}
            >
              Ver tutorial novamente
            </a>
          </WrapperButtons>
        </BoxForm>
      </Form>
    );
  };

  const renderPreview = () => {
    return (
      <PreviewBox>
        <PreviewDescription>
          <PreviewDescriptionIcon>
            <AiFillEye />
          </PreviewDescriptionIcon>
          <PreviewDescriptionContent>
            Simulação de como seu cliente irá visualizar a página de acesso
          </PreviewDescriptionContent>
        </PreviewDescription>
        <SmartphonePreview className="tour-step-4">
          <PreviewBanner
            image={
              background ||
              (onBoarding.businessType &&
                backgroundList[onBoarding.businessType])
            }
          >
            <PreviewTitle>{name}</PreviewTitle>
            {(!!logo || onBoarding.businessType) && (
              <PreviewLogo isDefault={!logo}>
                <img
                  alt="Avatar"
                  src={
                    logo ||
                    (onBoarding.businessType &&
                      avatarList[onBoarding.businessType])
                  }
                />
              </PreviewLogo>
            )}
          </PreviewBanner>
          <PreviewForm color={color}>
            <span>Acesso à rede</span>
            <label>Preencha com seu CPF ou Passaporte para se conectar</label>
            <input />
            <button type="button">Conectar</button>
          </PreviewForm>
        </SmartphonePreview>
      </PreviewBox>
    );
  };

  return (
    <Container>
      {renderForm()}
      {renderPreview()}
      <Tour
        steps={tourSteps}
        isOpen={turIsOpen}
        onRequestClose={() => setTurIsOpen(false)}
        closeWithMask={false}
        accentColor={theme.colors.primary}
        className=""
        startAt={0}
      />
    </Container>
  );
}

Step4.propTypes = {
  theme: PropTypes.shape().isRequired,
};

export default withTheme(Step4);
