import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

import { Container } from './styles';

function ChartCheckboxType({ data }) {
  const config = {
    series: data.options.map((item) => item.answers),
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: data.options.map((item) => item.option),
      theme: {
        monochrome: {
          enabled: true,
          color: '#EB4335',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <Container>
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="pie"
        width={380}
        height={380}
      />
    </Container>
  );
}

ChartCheckboxType.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ChartCheckboxType;
