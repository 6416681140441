/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import { FaInfoCircle } from 'react-icons/fa';

import ChartGender from '../components/Gender';
import ChartAgeRange from '../components/AgeRange';
import {
  Container,
  ContainerHeader,
  CampaignIcon,
  ContainerSection,
  ContainerSectionCard,
  CampaignBoxDemographicCard,
} from './styles';

function ResultEmail({ campaign }) {
  return (
    <Container>
      <ContainerHeader>
        <CampaignIcon color="#ff974a">
          <BsFillEnvelopeFill />
        </CampaignIcon>
        <div className="container-header-content">
          <h2>{campaign.name}</h2>
          <span>{`Status: ${campaign.status}`}</span>
          <span>
            {`Início da campanha: ${moment(campaign.startDate).format(
              'DD/MM/YYYY HH:mm'
            )}`}
          </span>
          <span>Assunto: {campaign.subject}</span>
          <a href="#" target="_blank">
            Ver e-mail
          </a>
        </div>
      </ContainerHeader>
      <ContainerSection>
        <h2>Dados gerais</h2>
        <div className="campaign-box-section-content">
          <ContainerSectionCard flex={1}>
            <span>
              Base total
              <FaInfoCircle
                data-tip="Base total."
                data-for="tooltip-campaign-results"
              />
            </span>
            <h2 style={{ marginTop: 50 }}>3.060</h2>
          </ContainerSectionCard>
          <ContainerSectionCard flex={1}>
            <span>
              E-mail entregues
              <FaInfoCircle
                data-tip="E-mail entregues."
                data-for="tooltip-campaign-results"
              />
            </span>
            <h3 style={{ marginTop: 30 }}>1234</h3>
            <h2>99%</h2>
          </ContainerSectionCard>
          <ContainerSectionCard flex={1}>
            <span>
              E-mail inválidos
              <FaInfoCircle
                data-tip="E-mail inválidos."
                data-for="tooltip-campaign-results"
              />
            </span>
            <h3 style={{ marginTop: 30 }}>1234</h3>
            <h2>99%</h2>
          </ContainerSectionCard>
        </div>
      </ContainerSection>
      <ContainerSection>
        <h2>Resultados</h2>
        <div className="campaign-box-section-content">
          <ContainerSectionCard flex={1}>
            <span>
              Enviados
              <FaInfoCircle
                data-tip="Enviados."
                data-for="tooltip-campaign-results"
              />
            </span>
            <h2 style={{ marginTop: 50 }}>3.060</h2>
          </ContainerSectionCard>
          <ContainerSectionCard flex={1}>
            <span>
              Abertos
              <FaInfoCircle
                data-tip="Abertos."
                data-for="tooltip-campaign-results"
              />
            </span>
            <h2 style={{ marginTop: 50 }}>3.060</h2>
          </ContainerSectionCard>
          <ContainerSectionCard flex={1}>
            <span>
              Cliques
              <FaInfoCircle
                data-tip="Cliques."
                data-for="tooltip-campaign-results"
              />
            </span>
            <h2 style={{ marginTop: 50 }}>3.060</h2>
          </ContainerSectionCard>
          <ContainerSectionCard flex={1}>
            <span>
              Assinaturas canceladas
              <FaInfoCircle
                data-tip="Assinaturas canceladas."
                data-for="tooltip-campaign-results"
              />
            </span>
            <h2 style={{ marginTop: 50 }}>3.060</h2>
          </ContainerSectionCard>
        </div>
      </ContainerSection>
      <ContainerSection>
        <h2>
          Dados demográficos
          <FaInfoCircle
            data-tip="Perfil de pessoas no relatório tem base na segmentação da campanha."
            data-for="tooltip-campaign-results"
          />
        </h2>
        <div className="campaign-box-section-content">
          <CampaignBoxDemographicCard flex={1}>
            <span>Gênero</span>
            <ChartGender data={campaign.reportData.gender} />
          </CampaignBoxDemographicCard>
          <CampaignBoxDemographicCard flex={2}>
            <span>Faixa etária</span>
            <ChartAgeRange data={campaign.reportData.ageRange} />
          </CampaignBoxDemographicCard>
        </div>
      </ContainerSection>
      <ReactTooltip
        place="bottom"
        backgroundColor="#FFFFFF"
        textColor="#323232"
        border
        borderColor="#d1d1d1"
        className="tooltip-wrap"
        id="tooltip-campaign-results"
      />
    </Container>
  );
}

ResultEmail.propTypes = {
  campaign: PropTypes.shape().isRequired,
};

export default ResultEmail;
