import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

import { Container } from './styles';

function Peakhours({ data }) {
  const renderChart = () => {
    const dataChart = {
      options: {
        chart: {
          zoom: { enabled: false },
          toolbar: { tools: { download: false } },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            // columnWidth: '70%',
            // endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: data.map((item) => item.label),
        },
        fill: {
          colors: ['#F5A623'],
          opacity: 1,
          type: 'solid',
        },
      },
      series: [
        {
          name: 'Visitantes',
          data: data.map((item) => item.value),
        },
      ],
    };
    return (
      <Chart
        options={dataChart.options}
        series={dataChart.series}
        type="bar"
        width="100%"
        height="150px"
      />
    );
  };

  return <Container>{renderChart()}</Container>;
}
Peakhours.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Peakhours;
