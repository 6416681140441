import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 25px;
  > span {
    min-width: 400px;
    max-width: 400px;
    padding: 7px 5px;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    background-color: #fafafb;
    color: #263238;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0;
    }
  }
  > div {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.07px;
    color: #0a0724;
    margin: 35px 0;
    > a {
      font-weight: 500;
      color: #0062ff;
      text-decoration: none;
    }
  }
`;
