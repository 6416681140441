import React, { useEffect, useState } from 'react';
import Popover from 'react-tiny-popover';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';

import { FaChevronDown, FaRegBell, FaCircle } from 'react-icons/fa';
import { MdDateRange, MdAccessTime } from 'react-icons/md';

import Proptypes from 'prop-types';
import { useTenant, TENANT_CLARO } from '../../hooks/tenant';

import { headerNotificationsRequest } from '~/store/modules/header/actions';

import logo from '../../assets/images/logo.png';
import logoClaro from '../../assets/images/logo-claro.png';

import {
  Container,
  Content,
  Logo,
  BaseLogin,
  Avatar,
  ActionBox,
  NotificationButton,
  NotificationBox,
  NotificationBoxHeader,
  NotificationBoxBody,
  NotificationItem,
  NotificationItemContent,
  NotificationEmpty,
} from './styles';

const Header = ({ wizard }) => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.header);
  const { tenant } = useTenant();
  const [notificationIsOpen, setNotificationIsOpen] = useState(false);

  useEffect(() => {
    dispatch(headerNotificationsRequest());
  }, []);

  const renderPopoverNotifications = () => {
    const renderButton = (ref) => (
      <NotificationButton
        ref={ref}
        onClick={() => setNotificationIsOpen(!notificationIsOpen)}
      >
        <FaRegBell />
        {notifications.filter((item) => item.unRead).length > 0 && (
          <FaCircle className="badge" />
        )}
      </NotificationButton>
    );

    return (
      <Popover
        isOpen={notificationIsOpen}
        position="bottom"
        padding={5}
        onClickOutside={() => setNotificationIsOpen(false)}
        content={() => (
          <NotificationBox>
            <NotificationBoxHeader>
              <span>Notificações</span>
              {notifications.length > 0 && (
                <button type="button">Marcar tudo como lido</button>
              )}
            </NotificationBoxHeader>
            <NotificationBoxBody>
              {notifications.length > 0 ? (
                notifications.map((item) => (
                  <NotificationItem key={item.id}>
                    <NotificationItemContent>
                      <p className={item.unRead ? 'un-read' : ''}>
                        {item.content}
                      </p>
                      <div className="content-date">
                        <span>
                          <MdDateRange />
                          {moment(item.date).format('DD/MM/YYYY')}
                        </span>
                        <span>
                          <MdAccessTime />
                          {!item.past
                            ? moment(item.date).locale('pt-br').fromNow()
                            : moment(item.date).format('HH:mm')}
                        </span>
                      </div>
                    </NotificationItemContent>
                  </NotificationItem>
                ))
              ) : (
                <NotificationEmpty>Sem notificações</NotificationEmpty>
              )}
            </NotificationBoxBody>
          </NotificationBox>
        )}
      >
        {renderButton}
      </Popover>
    );
  };
  return (
    <Container>
      <Content>
        <Logo src={tenant === TENANT_CLARO ? logoClaro : logo} />
        <ActionBox>
          {!wizard && renderPopoverNotifications()}
          <BaseLogin>
            <Avatar>FG</Avatar>
            <p>Felipe Goldin</p>
            <FaChevronDown size={24} color="#468EE5" />
          </BaseLogin>
        </ActionBox>
      </Content>
    </Container>
  );
};

Header.defaultProps = {
  wizard: false,
};

Header.propTypes = {
  wizard: Proptypes.bool,
};

export default Header;
