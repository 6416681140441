import styled from 'styled-components';

export default styled.table`
  table-layout: auto;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0;
  font-size: 14px;
  thead {
    tr {
      th {
        padding: 0 10px;
        text-align: left;
        color: ${(props) => props.theme.colors.black};
        font-size: 14px;
        font-weight: 700;
        height: 70px;
      }
      th[align='center'] {
        text-align: center;
      }
      th[align='left'] {
        text-align: left;
      }
      th[align='right'] {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      background-color: ${(props) => props.theme.colors.white};
      transition: 0.3s;
      &:hover {
        background-color: ${(props) => props.theme.colors.lighterWhite};
      }
      td {
        padding: 0 10px;
        text-align: left;
        border-bottom: 1px solid ${(props) => props.theme.colors.lighter};
        font-size: 14px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.black};
        height: 70px;
        > b {
          font-weight: bold;
        }
        > a {
          font-weight: bold;
          text-decoration: none;
          color: ${(props) => props.theme.colors.blue2};
          font-size: 14px;
        }
      }
      td[align='center'] {
        text-align: center;
      }
      td[align='left'] {
        text-align: left;
      }
      td[align='right'] {
        text-align: right;
      }
    }
  }
`;
