import styled from 'styled-components';
import supportBg from '~/assets/images/support-bg.png';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
  background-image: url(${supportBg});
  background-repeat: no-repeat;
  background-size: 522px auto;
  background-position-x: 100%;
  background-position-y: 70px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  > a {
    margin-right: 10px;
    svg {
      color: #fc5a5a;
      font-size: 20px;
    }
  }
  > h3 {
    font-size: 32px;
    font-weight: normal;
    font-family: Quicksand;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 537px;
  .title {
    font-size: 17px;
    font-weight: 600;
    font-family: Poppins;
    line-height: 26px;
    margin-top: 25px;
  }
  .description {
    font-size: 17px;
    font-weight: normal;
    font-family: Poppins;
    line-height: 26px;
    margin-top: 25px;
    min-height: 250px;
  }
`;

export const Footerbox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  border-top: 1px solid #d1d1d1;
  .footer-title {
    color: #323236;
    size: 14px;
    font-family: Poppins;
    font-weight: normal;
  }
  .footer-buttons {
    margin-top: 25px;
    display: flex;
    button {
      height: 40px;
      width: 120px;
      border-radius: 20px;
      border: 2px solid #4a90e2;
      margin-right: 28px;
      background-color: #ffffff;
      font-size: 14px;
      color: #4a90e2;
      font-family: Poppins;
      font-weight: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      svg {
        font-size: 20px;
      }
    }
  }
`;
