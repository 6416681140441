import React from 'react';
import PropTypes from 'prop-types';

import { Container, InputText } from './styles';

function Input({
  maxWidth,
  className,
  icon: Icon,
  placeholder,
  placeHolderAlign,
  disabled,
  ...rest
}) {
  return (
    <Container maxWidth={maxWidth} disabled={disabled} className={className}>
      {Icon && <Icon className="icon" />}
      <InputText
        hasIcon={!!Icon}
        placeholder={placeholder}
        placeHolderAlign={placeHolderAlign}
        disabled={disabled}
        {...rest}
      />
    </Container>
  );
}

Input.defaultProps = {
  maxWidth: null,
  placeHolderAlign: 'start',
  icon: null,
  placeholder: '',
  rest: null,
  disabled: false,
};

Input.propTypes = {
  maxWidth: PropTypes.number,
  placeHolderAlign: PropTypes.string,
  className: PropTypes.string.isRequired,
  icon: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rest: PropTypes.shape(),
};

export default Input;
