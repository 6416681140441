import styled from 'styled-components';
import bgNotebook from './notebook.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  padding: 0;
`;

export const NotebookBox = styled.div`
  width: 1000px;
  height: 592px;
  background-image: url(${bgNotebook});
  background-repeat: no-repeat;
  background-size: contain;
  padding: 44px 121px 74px 121px;
  .notebook-box-content {
    display: flex;
    width: 758px;
    height: 474px;
    max-height: 474px;
    padding: 0px;
    overflow-y: auto;
  }
`;
