import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineSetting, AiOutlineForm } from 'react-icons/ai';
import { TiThLargeOutline } from 'react-icons/ti';
import { FiUser, FiTarget } from 'react-icons/fi';
import { GoNote } from 'react-icons/go';

import ReactTooltip from 'react-tooltip';

import {
  Container,
  HamburguerBox,
  HamburguerItem,
  MenuBox,
  MenuItem,
} from './styles';

function MenuLeft() {
  const [expandedMenu, setExpandedMenu] = useState(false);

  const menuList = [
    {
      uuid: 1,
      label: 'Visão geral',
      icon: TiThLargeOutline,
      path: '/',
    },
    {
      uuid: 2,
      label: 'Visitantes',
      icon: FiUser,
      path: '/visitors',
    },
    {
      uuid: 3,
      label: 'Configurações',
      icon: AiOutlineSetting,
      path: '/settings',
    },
    {
      uuid: 4,
      label: 'Divulgue seu estabelecimento',
      icon: FiTarget,
      path: '/propagate',
    },
    {
      uuid: 5,
      label: 'Ajuda',
      icon: GoNote,
      path: '/support',
    },
    {
      uuid: 6,
      label: 'Onboard',
      icon: AiOutlineForm,
      path: '/wizard',
    },
  ];

  return (
    <Container expandedMenu={expandedMenu}>
      <HamburguerBox expandedMenu={expandedMenu}>
        <HamburguerItem onClick={() => setExpandedMenu(!expandedMenu)}>
          <AiOutlineMenu />
        </HamburguerItem>
      </HamburguerBox>
      <MenuBox expandedMenu={expandedMenu}>
        {menuList.map((item) => (
          <MenuItem
            key={item.uuid}
            expandedMenu={expandedMenu}
            to={item.path}
            onClick={() => setExpandedMenu(false)}
            exact
          >
            <item.icon data-tip={item.label} />
            {expandedMenu && <span>{item.label}</span>}
          </MenuItem>
        ))}
      </MenuBox>
      {!expandedMenu && <ReactTooltip place="right" effect="solid" />}
    </Container>
  );
}

export default MenuLeft;
