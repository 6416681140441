import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiPlus } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';
import { BiUpload } from 'react-icons/bi';
import { BsFillImageFill } from 'react-icons/bs';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { fileToBase64 } from '~/utils';
import getCroppedImg from './cropImage';

import {
  Wrap,
  Container,
  IconBox,
  Label,
  ModalContentDropzone,
  ModalContentCrop,
  ModalCropArea,
  ModalCropFooter,
  ModalCropSlider,
  Title,
  Thumbnail,
  VideoThumb,
  DeleteButton,
} from './styles';

function DropZoneUpload({
  label,
  title,
  onChange,
  fileUrl,
  className,
  modalTitle,
  modalLabel,
  cropImage,
  aspect,
  accept,
  maxSize,
  typeThumb,
  component: Component,
}) {
  const [modalDropIsOpen, setModalDropIsOpen] = useState(false);
  // state imagecrop
  const [fileToCrop, setFileToCrop] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const rotation = 0;
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        if (cropImage) {
          const base64File = await fileToBase64(acceptedFiles[0]);
          setFileToCrop(base64File);
        } else {
          setFileToCrop(null);
          onChange(acceptedFiles[0]);
        }
        setModalDropIsOpen(false);
      }
    },
    [modalDropIsOpen]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize,
  });

  const resetCropData = useCallback(() => {
    setFileToCrop(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
  }, []);

  const onCropComplete = useCallback((croppedArea, cropAreaPixels) => {
    setCroppedAreaPixels(cropAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        fileToCrop,
        croppedAreaPixels,
        rotation
      );
      onChange(croppedImage);
      resetCropData();
    } catch (e) {
      // console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  // const renderThumbnail = () => (
  //   <Container isThumbnail>
  //     <Title>{title}</Title>
  //     <Thumbnail src={fileUrl} />
  //     <DeleteButton onClick={() => onChange(null)}>
  //       <IoMdClose />
  //     </DeleteButton>
  //   </Container>
  // );

  return (
    <>
      {Component ? (
        <Component onClick={() => setModalDropIsOpen(true)} />
      ) : (
        <Wrap>
          {!!fileUrl && (
            <DeleteButton onClick={() => onChange(null)}>
              <IoMdClose />
            </DeleteButton>
          )}
          <Container
            className={className}
            isThumbnail={!!fileUrl}
            onClick={() => setModalDropIsOpen(true)}
          >
            {fileUrl ? (
              <>
                <Title>{title}</Title>
                {typeThumb === 'image' && (
                  <Thumbnail src={fileUrl} cropImage={cropImage} />
                )}
                {typeThumb === 'video' && (
                  <VideoThumb>
                    <source src={fileUrl} type="video/mp4" />
                    Seu browser não suporta o preview de vídeos
                  </VideoThumb>
                )}
              </>
            ) : (
              <>
                <IconBox>
                  <FiPlus />
                </IconBox>
                <Label>{label}</Label>
              </>
            )}
          </Container>
        </Wrap>
      )}
      <Modal
        visible={modalDropIsOpen}
        width="512"
        height="414"
        onClose={() => setModalDropIsOpen(false)}
      >
        <ModalContentDropzone {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <BiUpload />
          <h2>{modalTitle}</h2>
          <h3>{modalLabel}</h3>
          <span>ou</span>
          <button type="button">Faça upload</button>
        </ModalContentDropzone>
      </Modal>
      {!!fileToCrop && (
        <Modal visible width="500" onClose={resetCropData}>
          <ModalContentCrop>
            <h2>Imagem da sua marca</h2>
            <ModalCropArea>
              <Cropper
                image={fileToCrop}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                rotation={rotation}
                cropShape={cropImage}
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </ModalCropArea>
            <ModalCropSlider>
              <BsFillImageFill className="slider-zoom-image-small" />
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, sZoom) => setZoom(sZoom)}
                className="slider-zoom-crop"
              />
              <BsFillImageFill className="slider-zoom-image-big" />
            </ModalCropSlider>
            <ModalCropFooter>
              <Button title="Faça upload" onClick={showCroppedImage} />
            </ModalCropFooter>
          </ModalContentCrop>
        </Modal>
      )}
    </>
  );
}

DropZoneUpload.defaultProps = {
  fileUrl: '',
  title: '',
  onChange: () => {},
  modalTitle: '',
  modalLabel: '',
  cropImage: '', // 'rect' | 'round'
  aspect: 1,
  accept: 'image/png,image/jpg,image/jpeg',
  maxSize: null,
  typeThumb: 'image',
  component: null,
};

DropZoneUpload.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fileUrl: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  modalTitle: PropTypes.string,
  modalLabel: PropTypes.string,
  cropImage: PropTypes.string,
  aspect: PropTypes.number,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  typeThumb: PropTypes.string,
  component: PropTypes.element,
};

export default DropZoneUpload;
