import styled from 'styled-components';
import Button from '../Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 25px;
  min-width: 500px;
  max-width: 500px;
  min-height: 400px;
  .icon-modal {
    font-size: 45px;
    color: #92929d;
    margin-bottom: 25px;
  }
`;

export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 25px;
  right: 25px;
  border: 0;
  background-color: transparent;
  svg {
    font-size: 25px;
    color: #92929d;
  }
`;

export const Title = styled.h2`
  font-family: ${(props) => props.fontFamily};
  color: #0a0724;
  font-size: 40px;
  font-weight: ${(props) => props.fontWeight || 'bold'};
  margin-bottom: 25px;
`;

export const Message = styled.p`
  width: 260px;
  color: #323236;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 25px;
`;

export const ButtonConfirm = styled(Button)``;
