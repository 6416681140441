import styled from 'styled-components';

export const CampaignBox = styled.div`
  background: #ffffff;
  width: 317px;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  margin: 3px;
  display: flex;
  flex-direction: column;
  > span {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 10px;
    letter-spacing: 0.1px;
    color: #171725;
    margin-bottom: 5px;
    > svg {
      font-size: 10px;
      margin-right: 5px;
    }
    > small {
      opacity: 0.5;
      font-family: Poppins;
      font-size: 10px;
      letter-spacing: 0.1px;
      color: #323236;
      margin-left: 5px;
    }
  }
  > h2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    line-height: 2.06;
    color: #171725;
  }
  > h3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    line-height: 2.4;
    color: #171725;
  }
  .popup-footer {
    display: flex;
    margin-top: 15px;
    button.button-report {
      border: 0;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      background-color: #fc5a5a;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: normal;
      color: #fafafb;
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-right: 20px;
    }
    button.button-edit {
      border: 0;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      background-color: transparent;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: normal;
      color: #fa6115;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }
  }
`;

export const ButtonOpen = styled.button`
  display: flex;
  align-items: center;
  height: 17px;
  width: 100%;
  line-height: 17px;
  font-family: Poppins;
  font-size: 9px;
  font-weight: normal;
  line-height: 3.2;
  color: #26272a;
  border: 0;
  background-color: transparent;
  padding: 0 5px;
`;
