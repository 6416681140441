import React, { useEffect } from 'react';
import Emoji from 'react-emoji-render';
import { useDispatch } from 'react-redux';

import history from '~/services/history';
import Button from '~/components/Button';
import ImageBackgroundStep1 from '~/assets/images/onboarding-step-01.png';
import { userSetState } from '~/store/modules/user/actions';

import StepLink from '../../components/StepLink';

import { Container, Base } from './styles';

function Step1() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      userSetState({
        wizardProgress: 25,
        backgroundImage: ImageBackgroundStep1,
      })
    );
  }, []);

  return (
    <Container>
      <Base>
        <StepLink>PASSO 1/4</StepLink>

        <h1>
          <Emoji text="Bem-vindo, Felipe! :)" />
        </h1>

        <p>
          Aqui você pode fazer a gestão completa da sua rede Wi-Fi! Configure o
          visual do seu portal, receba informações para auxiliar na gestão do
          seu negócio e comunique-se com seus clientes.
        </p>

        <Button
          title="Começar"
          onClick={() => {
            dispatch(userSetState({ onBoarding: {} }));
            history.push('/wizard/step2');
          }}
        />
      </Base>
    </Container>
  );
}

export default Step1;
