import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function StepLink({ children }) {
  return <Container>{children}</Container>;
}

StepLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StepLink;
