import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d8d8d8;
  min-height: 73px;
  max-height: 73px;
`;

export const Content = styled.div`
  width: 100%;
  padding: 30px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img``;

export const BaseLogin = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  p {
    padding: 0 20px;
  }
`;

export const ActionBox = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  background: #92929d;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
`;

export const NotificationButton = styled.button`
  margin-right: 10px;
  width: 32px;
  height: 32px;
  border: 2px solid ${(props) => props.theme.colors.primaryHex}90;
  background: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  svg {
    color: ${(props) => props.theme.colors.primaryHex}90;
    font-size: 15px;
  }
  &:hover {
    border: 2px solid ${(props) => props.theme.colors.primaryHex};
    svg {
      color: ${(props) => props.theme.colors.primaryHex};
    }
  }
  position: relative;
  .badge {
    position: absolute;
    font-size: 10px;
    color: ${(props) => props.theme.colors.primaryHex};
    top: -4px;
    right: -4px;
  }
`;

export const NotificationBox = styled.div`
  background: #fafafb;
  width: 317px;
  border-radius: 5px;
  border: 2px solid #d1d1d1;
`;

export const NotificationBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 54px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0 23px;
  span {
    color: #171725;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 600;
  }
  button {
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.blue2};
    border: 0;
    background-color: transparent;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 8px;
  }
`;

export const NotificationBoxBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
`;

export const NotificationItem = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 23px;
  position: relative;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  &:last-child {
    border-bottom: 0;
  }
`;

export const NotificationItemAvatar = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  color: #ffffff;
  background-color: #4a90e2;
  border-radius: 50%;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-right: 15px;
`;

export const NotificationItemContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  p {
    text-align: justify;
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 10px;
    color: #171725;
  }
  p.un-read {
    font-weight: bold;
  }
  .content-date {
    display: flex;
    flex-direction: row;
    width: 100%;
    span {
      font-family: 'Roboto';
      font-size: 10px;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: #696974;
      margin-top: 5px;
      margin-right: 10px;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }
    }
  }
`;

export const NotificationEmpty = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
  text-align: center;
  margin: 20px 0;
`;
