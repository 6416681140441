import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoIosArrowBack, IoMdClose, IoMdCheckmark } from 'react-icons/io';

import { supportGetRequest } from '~/store/modules/supports/actions';
import { WrapPage, Container, Header, Content, Footerbox } from './styles';

function Details({ match }) {
  const { id } = match.params;
  const dispatch = useDispatch();
  const { details } = useSelector((state) => state.supports);

  useEffect(() => {
    dispatch(supportGetRequest(id));
  }, []);

  const renderHeader = () => (
    <>
      <Link to="/support">
        <IoIosArrowBack />
      </Link>
      <h3>Ajuda</h3>
    </>
  );

  const renderContent = () => (
    <Content>
      <p className="title">{details.title}</p>
      <p className="description">{details.description}</p>
      <Footerbox>
        <div className="footer-title">Esta informação foi útil?</div>
        <div className="footer-buttons">
          <button type="button">
            <IoMdClose />
            Não
          </button>
          <button type="button">
            <IoMdCheckmark />
            Sim
          </button>
        </div>
      </Footerbox>
    </Content>
  );

  return (
    <WrapPage>
      <Container>
        <Header>{renderHeader()}</Header>
        {!!details && renderContent()}
      </Container>
    </WrapPage>
  );
}

Details.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
};

export default Details;
