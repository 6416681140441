import styled from 'styled-components';

export const Container = styled.div`
  .preview-steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 2px solid #bdbdbd;
    margin-top: 40px;
    span {
      width: 10px;
      height: 10px;
      background-color: #bdbdbd;
      border-radius: 50px;
      margin-top: -6px;
    }
    span.preview-steps-active {
      background-color: #fc5a5a;
    }
  }
  .preview-question {
    padding-top: 15px;
    > span {
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
    }
  }
  .preview-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
      display: flex;
      flex: 1;
      height: 25px;
      border-radius: 4px;
      background-color: #055cff;
      border: 0;
      align-items: center;
      justify-content: center;
      font-family: Poppins;
      font-size: 10px;
      font-weight: 600;
      color: #fafafb;
      &:disabled {
        opacity: 0.3;
      }
      &:first-child {
        margin-right: 20px;
      }
    }
  }
`;

export const CheckTypeBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  > button {
    display: flex;
    padding: 0;
    width: max-content;
    align-items: center;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 10px;
    font-weight: bold;
    color: #0a0724;
    > svg {
      margin-right: 5px;
      font-size: 18px;
      color: #0a0724;
    }
  }
  .options-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    > svg {
      font-size: 18px;
      color: #0a0724;
    }
    > span {
      display: flex;
      flex: 1;
      height: 32px;
      border-radius: 4px;
      border: solid 1px transparent;
      background-color: transparent;
      margin-left: 10px;
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
      padding: 0;
      outline: none;
      height: max-content;
    }
  }
`;

export const RadioTypeBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  > button {
    display: flex;
    padding: 0;
    width: max-content;
    align-items: center;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-size: 10px;
    font-weight: bold;
    color: #0a0724;
    > svg {
      margin-right: 5px;
      font-size: 18px;
      color: #0a0724;
    }
  }
  .options-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    > svg {
      font-size: 18px;
      color: #0a0724;
    }
    > span {
      display: flex;
      flex: 1;
      height: 32px;
      border-radius: 4px;
      border: solid 1px transparent;
      background-color: transparent;
      margin-left: 10px;
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
      padding: 0;
      outline: none;
      height: max-content;
    }
  }
`;

export const TextTypeBox = styled.div`
  display: flex;
  margin-top: 10px;
  input {
    display: flex;
    flex: 1;
    height: 32px;
    border-radius: 4px;
    padding: 0 12px;
    border: solid 1px #d1d1d1;
    background-color: #fafafb;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    color: #0a0724;
    outline: none;
    &::placeholder {
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
`;

export const NumberTypeBox = styled.div`
  display: flex;
  margin-top: 10px;
  input {
    display: flex;
    flex: 1;
    height: 32px;
    border-radius: 4px;
    padding: 0 12px;
    border: solid 1px #d1d1d1;
    background-color: #fafafb;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    color: #0a0724;
    outline: none;
    &::placeholder {
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
`;

export const RangeTypeBox = styled.div`
  display: flex;
  margin-top: 10px;
  svg {
    color: #0a0724;
    font-size: 30px;
    margin-right: 10px;
  }
`;

export const DateTypeBox = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  position: relative;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
    input {
      display: flex;
      flex: 1;
      height: 32px;
      width: 100%;
      border-radius: 4px;
      padding: 0 12px;
      border: solid 1px #d1d1d1;
      background-color: #fafafb;
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      color: #0a0724;
      outline: none;
      &::placeholder {
        font-family: Poppins;
        font-size: 10px;
        font-weight: normal;
        color: #0a0724;
      }
      &:disabled {
        opacity: 0.7;
      }
    }
  }
  svg {
    position: absolute;
    right: 12px;
  }
`;
