/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { FaChevronLeft, FaRegCalendar, FaInfoCircle } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import history from '~/services/history';
import SmartphonePreview from '~/components/SmartphonePreview';
import Template1 from '../Step2/components/Template1';
import cafeteria from '~/assets/images/avatar/cafeteria.svg';
import bgMock from '~/assets/images/step4-preview-bg.jpg';

import {
  WrapPage,
  Container,
  VideoTitle,
  VideoSubtitle,
  ContentBox,
  FormBox,
  Info,
  Radiobox,
  InputWrap,
  ButtonNext,
  ButtonCancel,
  Input,
  InputData,
  PreviewDescription,
  PreviewDescriptionIcon,
  PreviewDescriptionContent,
  PreviewBox,
  // SmartphoneContent,
} from './styles';

function Step2() {
  const [email, setEmail] = useState('');
  const [emailType, setEmailType] = useState('scheduled');
  const [initialDate, setInitialDate] = useState(null);
  const [templateConfig, setTemplateConfig] = useState({
    profilePic: cafeteria,
    title: 'Inserir título',
    styleTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      textAlign: 'center',
      fontFamily: 'Poppins',
      textDecoration: 'none',
    },
    subtitle: 'Inserir subtítulo',
    styleSubtitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      textAlign: 'left',
      fontFamily: 'Poppins',
      textDecoration: 'none',
    },
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
    styleContent: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      textAlign: 'justify',
      fontFamily: 'Poppins',
      textDecoration: 'none',
    },
    contentImage: bgMock,
    actionLabel: 'Acessar',
    actionLink: 'https://google.com',
    actionColor: '#582369',
  });

  return (
    <WrapPage>
      <Container>
        <VideoTitle>
          <Link to="/propagate/email/step2">
            <FaChevronLeft size={14} />
          </Link>
          E-mail
        </VideoTitle>
        <VideoSubtitle>PASSO 3/3</VideoSubtitle>
        <ContentBox>
          <FormBox>
            <Info>
              Configure o E-mail que será enviado para os seus clientes.
            </Info>
            <div className="form-section-box">
              <span className="form-section-box-title">
                E-mail de teste
                <FaInfoCircle
                  data-tip="E-mail de teste"
                  data-for="tooltip-provider"
                />
              </span>
              <Info style={{ marginBottom: 5 }}>Insira o e-mail</Info>
              <div className="form-section-box-email">
                <Input
                  placeholder="email@email.com.br"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="button">Enviar teste</button>
              </div>
            </div>
            <span className="form-section-title">Configurar envio</span>
            <div className="form-section-box">
              <Radiobox
                label="E-mail programado"
                value={emailType === 'scheduled'}
                onChange={() => setEmailType('scheduled')}
              />
              <Info>Duração da campanha</Info>
              <div className="form-section-box-scheduled">
                <span>
                  Início do envio:
                  <FaInfoCircle
                    data-tip="Início do envio"
                    data-for="tooltip-provider"
                  />
                </span>
                <InputData
                  name="init-date"
                  type="text"
                  placeholder="99/99/9999 às 00:00h"
                  value={initialDate}
                  onChange={setInitialDate}
                  icon={FaRegCalendar}
                  responseFormat="YYYY-MM-DD HH:mm:ss"
                  disabled={emailType === 'submit'}
                />
              </div>
            </div>
            <div className="form-section-box">
              <Radiobox
                label="Enviar agora"
                value={emailType === 'submit'}
                onChange={() => setEmailType('submit')}
              />
            </div>
            <InputWrap style={{ marginTop: 70 }}>
              <ButtonNext
                title="Concluir"
                onClick={() => history.push('/propagate')}
              />
              <ButtonCancel title="Salvar como rascunho" onClick={() => {}} />
              <ButtonCancel
                title="Cancelar"
                onClick={() => history.push('/propagate')}
              />
            </InputWrap>
          </FormBox>
          <PreviewBox>
            <PreviewDescription>
              <PreviewDescriptionIcon>
                <AiFillEye />
              </PreviewDescriptionIcon>
              <PreviewDescriptionContent>
                Simulação de como seu cliente irá visualizar a página de acesso
              </PreviewDescriptionContent>
            </PreviewDescription>
            <SmartphonePreview className="tour-step-4">
              <Template1
                mode="mobile"
                value={templateConfig}
                onChange={setTemplateConfig}
                disabled
              />
            </SmartphonePreview>
          </PreviewBox>
        </ContentBox>
        <ReactTooltip
          place="right"
          backgroundColor="#FFFFFF"
          textColor="#323232"
          border
          borderColor="#d1d1d1"
          className="tooltip-wrap"
          id="tooltip-provider"
        />
      </Container>
    </WrapPage>
  );
}

export default withTheme(Step2);
