import styled, { css } from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
`;

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background-color: #f7f1e4;
  border-radius: 8px;
  cursor: pointer;
  padding: 5px;
  position: relative;
  border: 1px solid #f7f1e4;
  outline: none;
  overflow: hidden;
  ${(props) =>
    props.isThumbnail &&
    css`
      padding: 0;
      background-color: #848484;
      justify-content: flex-start;
      align-items: flex-start;
    `}
`;

export const ModalContentDropzone = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    font-size: 60px;
    color: #92929d;
  }
  > h2 {
    font-family: 'Quicksand';
    font-size: 32px;
    font-weight: normal;
    color: #0a0724;
    margin-top: 25px;
  }
  > h3 {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: bold;
    color: #323236;
    margin-top: 16px;
  }
  > span {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: normal;
    color: #323236;
    margin-top: 10px;
  }
  > button {
    background-color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
    height: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    color: ${(props) => props.theme.colors.white};
    margin-top: 10px;
  }
`;

export const ModalContentCrop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-family: 'Quicksand';
    font-size: 32px;
    font-weight: normal;
    color: #0a0724;
    margin-bottom: 30px;
    margin-top: 20px;
  }
`;

export const ModalCropArea = styled.div`
  width: 290px;
  height: 200px;
  position: relative;
`;

export const ModalCropSlider = styled.div`
  width: 290px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  .slider-zoom-image-small {
    font-size: 13px;
    color: #0a0724;
    margin-right: 15px;
  }
  .slider-zoom-image-big {
    font-size: 20px;
    color: #0a0724;
    margin-left: 15px;
  }
  .slider-zoom-crop {
    color: ${(props) => props.theme.colors.regular} !important;
    .MuiSlider-thumb {
      background-color: ${(props) => props.theme.colors.white} !important;
      border: 1px solid ${(props) => props.theme.colors.regular};
      width: 18px;
      height: 18px;
      margin-top: -9px;
      margin-left: -9px;
    }
  }
`;

export const ModalCropFooter = styled.div`
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  button {
    width: max-content;
  }
`;

/*
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    background-color: #f7f1e4;
    border-radius: 8px;
    cursor: pointer;
    padding: 5px;
    margin-right: 40px;
    position: relative;

    ${(props) =>
      props.isDragActive &&
      css`
        border: 2px dashed #ffc542;
      `}
    ${(props) =>
      props.isThumbnail &&
      css`
        padding: 0;
        background-color: #848484;
        justify-content: flex-start;
        align-items: flex-start;
      `}
  `;
*/

export const Thumbnail = styled.img`
  width: 150px;
  height: 85px;
  object-fit: cover;

  ${(props) =>
    props.cropImage === 'round' &&
    css`
      width: 90px;
      height: 90px;
      object-fit: contain;
      border-radius: 50%;
      align-self: center;
    `}
`;

export const VideoThumb = styled.video`
  width: 150px;
  height: 85px;
  object-fit: cover;
`;

export const IconBox = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ffc54230;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  svg {
    color: #ffc542;
    font-size: 30px;
  }
`;

export const Label = styled.div`
  color: #171725;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

export const Title = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const DeleteButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 1px 1px 5px 0px #737373;
  border-radius: 50%;
  border: 0;
  z-index: 9;
  svg {
    color: #4d91ff;
    font-size: 20px;
  }
`;
