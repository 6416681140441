import styled from 'styled-components';
import imageBackground from '~/assets/images/step-3.png';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
  > h2 {
    font-family: Poppins;
    font-size: 38px;
    font-weight: 600;
    color: #0a0724;
  }
`;

export const Row1 = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-top: 70px;
  background-image: url(${imageBackground});
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: 400px;
  background-position-x: right;
  background-position-y: top;
  padding-right: 400px;
  > h3 {
    font-family: Poppins;
    font-size: 38px;
    font-weight: 600;
    color: #0a0724;
  }
  > span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    color: #323236;
    margin-top: 30px;
  }
  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      margin-top: 25px;
      p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        color: #323236;
      }
    }
  }
`;

export const Row2 = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  margin: 25px 0 70px 0;
  .row2-col-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 400px;
    border-radius: 25px;
    border: solid 1px #e2e2ea;
    background-color: #ffffff;
    padding: 40px;
    > img {
      width: 282px;
      height: 206px;
      object-fit: contain;
      object-position: left;
      margin-top: -30px;
    }
    > h4 {
      font-family: Poppins;
      font-size: 23px;
      font-weight: 600;
      color: #171725;
      margin-bottom: 10px;
    }
    > p {
      font-family: Quicksand;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.89;
      color: #7f7f80;
      b {
        font-weight: bold;
      }
    }
    > span.span-normal {
      font-family: Poppins;
      font-size: 17px;
      font-weight: normal;
      color: #7e7e7e;
    }
    > span.span-danger {
      font-family: Quicksand;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.57;
      color: #fc5a5a;
    }
    > div.row2-col-box-footer {
      display: flex;
      margin-top: 60px;
      button {
        width: 120px;
        height: 45px;
        border-radius: 5px;
        border: solid 1px #0062ff;
        background-color: #e5efff;
        font-family: Poppins;
        font-size: 15px;
        font-weight: normal;
        color: #0062ff;
        margin-right: 20px;
        &:disabled {
          opacity: 0.4;
        }
      }
    }
  }
`;

export const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 54px;
  max-width: 54px;
  min-height: 54px;
  max-height: 54px;
  border-radius: 50%;
  opacity: 0.68;
  background-color: ${(props) => (props.color ? props.color : '')};
  margin-right: 25px;

  svg {
    object-fit: contain;
    object-position: center;
    width: 25px;
    height: 25px;
    opacity: 0.68;
  }
`;
