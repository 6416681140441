import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

// import { Container } from './styles';

function ChartCheckboxType({ data }) {
  const config = {
    series: [
      {
        name: 'Respostas',
        data: data.options.map((item) => item.answers),
      },
    ],
    options: {
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: data.options.map((item) => item.option),
      },
      fill: {
        colors: '#EB4335',
      },
    },
  };

  return (
    <ReactApexChart
      options={config.options}
      series={config.series}
      type="bar"
      height={300}
    />
  );
}

ChartCheckboxType.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ChartCheckboxType;
