import styled from 'styled-components';

import Button from '~/components/Button';
import TextAreaComponent from '~/components/TextArea';
import RadioboxComponent from '~/components/Radiobox';
import CheckToggleComponent from '~/components/CheckToggle';
import InputComponent from '~/components/Input';
import InputMaskComponent from '~/components/InputMask';
import InputDateComponent from '~/components/InputDate';
import step4PreviewBg from '~/assets/images/step4-preview-bg.jpg';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
`;

export const VideoTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  font-family: 'Quicksand';
  font-weight: 400;
  color: #0a0724;
  position: relative;
  a {
    position: absolute;
    left: -30px;
    display: flex;
    align-items: center;
    > svg {
      height: 22px;
      color: #fa6115;
    }
  }
`;

export const VideoSubtitle = styled.div`
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 400;
  color: #fa6115;
  margin-top: 5px;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  .input-textarea-box {
    display: flex;
    flex-direction: column;
    width: 475px;
    align-items: flex-end;
    > span {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      color: #92929d;
      margin-top: 5px;
    }
  }
`;

export const ButtonNext = styled(Button)``;

export const ButtonCancel = styled(Button)`
  color: #fa6115;
  background-color: transparent;
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  border: 0;
  white-space: nowrap;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 500px;
  .form-section-title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #323236;
    margin-bottom: 20px;
  }
  .form-section-box {
    display: flex;
    flex-direction: column;
    width: 475px;
    min-height: 50px;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 15px 25px;
    > span.form-section-box-title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: normal;
      color: #0f2522;
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
      }
    }
    .form-section-box-email {
      display: flex;
      align-items: center;
      width: 100%;
      > button {
        margin-left: 12px;
        min-width: 120px;
        height: 43px;
        border: 0;
        border-radius: 30px;
        background-color: #fc5a5a;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        color: #fafafb;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-section-box-scheduled {
      display: flex;
      flex-direction: column;
      width: 100%;
      > span {
        font-family: Poppins;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.1px;
        color: #323236;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        svg {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const PreviewBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.span`
  font-family: Poppins;
  font-weight: bold;
  font-size: 14px;
  color: #323236;
`;

export const Description = styled.span`
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  color: #323236;
`;

export const Info = styled.span`
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  color: #0f2522;
  margin: 20px 0;
`;

export const PreviewVideo = styled.div`
  background-image: url(${step4PreviewBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 125px;
  position: relative;
`;

export const PreviewTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
`;

export const PreviewLogo = styled.div`
  width: 80px;
  height: 80px;
  bottom: -40px;
  padding: ${(props) => (props.isDefault ? 18 : 0)}px;

  border-radius: 50%;
  position: absolute;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    color: black;
  }
`;

export const PreviewForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 330px;
  max-height: 330px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -10px;
  background-color: #ffffff;
  padding: 20px;

  span {
    font-family: Poppins;
    font-weight: bold;
    font-size: 10px;
    color: #0a0724;
    margin-top: 40px;
  }

  .preview-form-video {
    width: 100%;
    height: 130px;
    border-radius: 4px;
    border: solid 0.7px #d1d1d1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    svg {
      font-size: 35px;
      color: #0a0724;
    }
  }

  button {
    border: 0;
    background-color: #0f65fe;
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    border-radius: 5px;
    margin-top: 10px;
    height: 25px;
    width: 150px;
  }
`;

export const PreviewDescription = styled.div`
  display: flex;
  width: 350px;
`;

export const PreviewDescriptionIcon = styled.div`
  display: flex;
  margin-right: 10px;
  > svg {
    font-size: 20px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const PreviewDescriptionContent = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.3;
  font-weight: 600;
  display: flex;
  flex: 1;
  text-align: justify;
`;

export const TextArea = styled(TextAreaComponent)`
  width: 475px;
  height: 145px;
`;

export const Input = styled(InputComponent)`
  width: 475px;
`;

export const InputMask = styled(InputMaskComponent)`
  width: 475px;
`;

export const InputData = styled(InputDateComponent)`
  width: 475px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  > span {
    font-family: Poppins;
    font-weight: bold;
    font-size: 14px;
    color: #323236;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const CheckToggle = styled(CheckToggleComponent)`
  .react-custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: #0062ff;
  }
`;

export const Radiobox = styled(RadioboxComponent).attrs({
  colorChecked: '#0062ff',
})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  color: #0f2522;
  padding-left: 35px;
  .radio {
    border: 2px solid #0062ff;
  }
`;

export const SmartphoneContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 10px;
  .messagte-text-box {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: #0062ff;
    padding: 10px 15px 30px 15px;
    > b {
      font-family: Poppins;
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
    }
    > p {
      margin-top: 10px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .messagte-hour-box {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    color: #0062ff;
    margin-top: 10px;
  }
`;
