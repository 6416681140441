import styled, { css } from 'styled-components';
import ButtonComponent from '~/components/ButtonCampaign';

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 40px 0 40px;
  flex-direction: column;
`;

export const ModalHeaderTitle = styled.div`
  font-family: 'Poppins';
  font-size: 23px;
  font-weight: bold;
  color: #0f2522;
`;

export const ModalHeaderDescription = styled.div`
  font-family: 'Poppins';
  margin-top: 25px;
  line-height: 30px;
  font-size: 14px;
  font-weight: normal;
  color: #0f2522;
`;

export const ModalForm = styled.div`
  display: flex;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const ModalFormHeader = styled.div`
  border-bottom: 1px solid #d8d8d8;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.direction ? `${props.direction}` : 'row'};
  flex: 1;
  margin-top: 24px;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'unset')};
  align-items: ${(props) =>
    props.direction && props.direction === 'column' ? 'flex-start' : 'center'};

  span {
    margin-left: 15px;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
    max-width: 250px;
  }

  p {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: normal;
    max-width: 360px;
    color: #7e7e7e;
  }
`;

export const WrapperImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  opacity: 0.68;
  background-color: ${(props) => (props.color ? props.color : '')};

  svg {
    object-fit: contain;
    object-position: center;
    width: 25px;
    height: 25px;
    opacity: 0.68;
  }
`;

export const ModalFormBody = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #0f2522;
`;

export const WrapperButtons = styled.div``;

export const ButtonCampaign = styled(ButtonComponent)`
  width: 111px;
  height: 73px;
  border: solid 1px #0062ff;
  border-radius: 5px;
  background-color: #e5efff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  padding-right: 0px;
`;
