import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import { lighten } from 'polished';

export const EditorBox = styled.div`
  display: flex;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  .editor-box-separator {
    background-color: #d1d1d1;
    width: 1px;
    height: 40px;
  }
`;

export const Input = styled.div`
  width: 100%;
  min-height: 50px;
  font-size: 32px;
  color: #0a0724;
  border: 0;
  outline: none;
  word-break: break-all;
`;

export const TextArea = styled.div`
  width: 100%;
  min-height: 100px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: #0a0724;
  border: 0;
  outline: none;
  resize: none;
  overflow-y: unset;
  overflow-x: hidden;
`;

export const Select = styled(ReactSelect).attrs({
  className: 'custom-select',
  classNamePrefix: 'custom-select',
})`
  width: 150px;
  height: 38px;
  .custom-select__control {
    height: 38px;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    .custom-select__value-container {
      padding: 0;
      padding-left: 16px;
      .custom-select__placeholder,
      .custom-select__single-value,
      .custom-select__input input {
        font-size: 14px;
        font-weight: bold;
        color: #323236;
      }
    }
    .custom-select__indicators {
      .custom-select__indicator-separator {
        display: none;
      }
    }
  }
  .custom-select__control:hover {
    border-color: #d1d1d1;
  }
`;

export const EditorBoxButton = styled.button.attrs({
  type: 'button',
})`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: none;
  background-color: transparent;
  color: ${lighten(0.3, '#323236')};
  font-size: 18px;
  &:hover {
    color: #323236;
  }
  ${(props) =>
    props.active &&
    css`
      color: #323236;
    `}
`;
