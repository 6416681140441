import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { Container } from './styles';

function CheckToggle({ value, onChange, className, ...rest }) {
  return (
    <Container className={className}>
      <Toggle
        defaultChecked={value}
        checked={value}
        className="react-custom-toggle"
        onChange={onChange}
        icons={false}
        {...rest}
      />
    </Container>
  );
}

CheckToggle.defaultProps = {
  value: false,
  onChange: () => {},
};

CheckToggle.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string.isRequired,
};

export default CheckToggle;
