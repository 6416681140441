/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiVideo, BiMessageDots } from 'react-icons/bi';
import { BsFillEnvelopeFill, BsWifi } from 'react-icons/bs';
import { FaWpforms, FaInfoCircle } from 'react-icons/fa';
import { AiOutlineFileImage } from 'react-icons/ai';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import './local.css';

import history from '~/services/history';
import DashboardTitle from '~/components/DashboardTitle';
import GroupButtons from '~/components/GroupButtons';
import CheckToggle from '~/components/CheckToggle';

import {
  propagateDataRequest,
  propagateSetState,
} from '~/store/modules/propagate/actions';

import ModalCreateCampaign from '~/pages/Dashboard/pages/Propagate/components/ModalCreateCampaign';
import PopupCampaignDetails from './components/PopupCampaignDetails';

import {
  WrapPage,
  Container,
  RowTable,
  Row1,
  Row1Content,
  Row2,
  Row2Header,
  Row2Content,
  Row3,
  Row3Header,
  Row3Content,
  HeaderActions,
  ButtonCreateCampaign,
  PropagateCard,
  Select,
  CalendarToolbar,
  CalendarLegendColorsItem,
  Table,
  TableActions,
  TabbleActionButton,
} from './styles';

const localizer = momentLocalizer(moment);

const Propagate = () => {
  const [modalCreateCampaignOpen, setModalCreateCampaignOpen] = useState(false);
  const [selectTypeView, setSelectTypeView] = useState('month');
  const [calendarActualDate, setCalendarActualDate] = useState(
    moment().format('YYYY-MM-01')
  );
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const { filter, propagateData } = useSelector((state) => state.propagate);
  const { firstAccess } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(propagateDataRequest());
  }, [filter]);

  useEffect(() => {
    if (firstAccess) {
      // history.push('/propagate/first');
    }
  }, [firstAccess]);

  const renderRow1 = () => (
    <Row1>
      <DashboardTitle title="Divulgue seu estabelecimento" />
      <Row1Content>
        <h2>Que tal se comunicar com seus clientes?</h2>
        <p>Use a criatividade para aumentar as suas vendas.</p>
        <ButtonCreateCampaign
          title="Criar campanha"
          onClick={() => setModalCreateCampaignOpen(true)}
        />
      </Row1Content>
    </Row1>
  );

  const renderRow2 = () => {
    const { reach, sms, email, banner, video, research } = propagateData;
    return (
      <Row2>
        <Row2Header>
          <span>Desempenho de campanhas</span>
          <GroupButtons
            label="Visualizar últimos:"
            options={[
              { label: '7 dias', value: 7 },
              { label: '30 dias', value: 30 },
            ]}
            onChange={(period) => {
              dispatch(propagateSetState({ filter: { ...filter, period } }));
            }}
            value={filter.period}
          />
        </Row2Header>
        <Row2Content>
          <PropagateCard>
            <span>
              Pessoas alcançadas
              <FaInfoCircle
                data-tip="Pessoas alcançadas são referentes a CPF único. Uma mesma pessoa pode ser impactada por um banner, pesquisa, vídeo, sms ou e-mail."
                data-for="tooltip-row-2"
              />
            </span>
            <h2>{reach}</h2>
          </PropagateCard>
          <PropagateCard>
            <span>
              SMS e E-mail enviados
              <FaInfoCircle
                data-tip="Soma total do envio de campanhas para clientes que já estiveram em seu estabelecimento."
                data-for="tooltip-row-2"
              />
            </span>
            <h2>{sms + email}</h2>
            <ul>
              <li>{`SMS: ${sms}`}</li>
              <li>{`E-mail: ${email}`}</li>
            </ul>
          </PropagateCard>
          <PropagateCard style={{ marginRight: 0 }}>
            <span>
              Visualizações de banner, vídeo e pesquisa
              <FaInfoCircle
                data-tip="Soma total da visualização de campanhas exibidas para seus clientes conectados na Rede Wi-fi."
                data-for="tooltip-row-2"
              />
            </span>
            <h2>{banner + video + research}</h2>
            <ul>
              <li>{`Banner: ${banner}`}</li>
              <li>{`Vídeo: ${video}`}</li>
              <li>{`Pesquisa: ${research}`}</li>
            </ul>
          </PropagateCard>
          <ReactTooltip
            place="right"
            backgroundColor="#FFFFFF"
            textColor="#323232"
            border
            borderColor="#d1d1d1"
            className="tooltip-wrap"
            id="tooltip-row-2"
          />
        </Row2Content>
      </Row2>
    );
  };

  const renderCalendar = () => {
    const agendaMessages = {
      next: 'Próximo',
      previous: 'Anterior',
      today: 'Hoje',
      month: 'Mês',
      week: 'Semana',
      work_week: 'Semana de trabalho',
      day: 'Dia',
      agenda: 'Agenda',
      event: 'Evento',
      time: 'Hora',
      date: 'Data',
      showMore: (/* number */) => 'Ver mais', // `Ver +${number}`,
      noEventsInRange: 'Nenhum evento encontrado neste intervalo',
      allDay: 'Dia todo',
      yesterday: 'Ontem',
      tomorrow: 'Amanhã',
    };

    const eventList =
      propagateData && propagateData.campaigns.length > 0
        ? propagateData.campaigns.map((item) => {
            let customHour = '00:30:00';
            if (item.startDate === item.endDate) customHour = '00:00:00';
            return {
              id: item.id,
              title: item.name,
              initial_date: moment(`${item.startDate} ${customHour}`).toDate(),
              final_date: moment(`${item.endDate} ${customHour}`).toDate(),
              type: item.type,
              realDate: item.realDate,
            };
          })
        : [];

    const getWeekDayNameByNumber = (number) => {
      switch (number) {
        case 0:
          return 'Domingo';
        case 1:
          return 'Segunda';
        case 2:
          return 'Terça';
        case 3:
          return 'Quarta';
        case 4:
          return 'Quinta';
        case 5:
          return 'Sexta';
        case 6:
          return 'Sábado';
        default:
          return '';
      }
    };

    return (
      <Calendar
        ref={calendarRef}
        popup
        title="Calendário"
        className="campaign-calendar"
        defaultDate={moment(calendarActualDate).toDate()}
        defaultView="month"
        events={eventList}
        localizer={localizer}
        messages={agendaMessages}
        views={['month', 'week']}
        startAccessor="initial_date"
        endAccessor="final_date"
        onNavigate={(date) => {
          setCalendarActualDate(moment(date).format('YYYY/MM/01'));
        }}
        formats={{
          weekdayFormat: (dateW, cultureW, localizerW) => {
            const weeDayName = getWeekDayNameByNumber(
              parseInt(localizerW.format(dateW, 'd', cultureW), 10)
            );
            return `${weeDayName}`;
          },
          dayFormat: (dateW, cultureW, localizerW) => {
            const weeDayName = getWeekDayNameByNumber(
              parseInt(localizerW.format(dateW, 'd', cultureW), 10)
            );
            const dayNumber = localizerW.format(dateW, 'DD', cultureW);

            return `${dayNumber} ${weeDayName}`;
          },
        }}
        components={{
          // eslint-disable-next-line react/prop-types
          toolbar: ({ onNavigate }) => (
            <CalendarToolbar>
              <h2>{moment(calendarActualDate).format('MMMM YYYY')}</h2>
              <button
                className="calendar-prev-button"
                type="button"
                onClick={() => onNavigate('PREV')}
              >
                <IoIosArrowBack />
              </button>
              <button
                className="calendar-next-button"
                type="button"
                onClick={() => onNavigate('NEXT')}
              >
                <IoIosArrowForward />
              </button>
              <div className="calendar-legend-colors">
                <CalendarLegendColorsItem color="#4a90e2">
                  <div>
                    <BiVideo />
                  </div>
                  <span>Vídeo</span>
                </CalendarLegendColorsItem>
                <CalendarLegendColorsItem color="#ffc542">
                  <div>
                    <FaWpforms />
                  </div>
                  <span>Pesquisa</span>
                </CalendarLegendColorsItem>
                <CalendarLegendColorsItem color="#ff9ad5">
                  <div>
                    <AiOutlineFileImage />
                  </div>
                  <span>Banner</span>
                </CalendarLegendColorsItem>
                <CalendarLegendColorsItem color="#3dd598">
                  <div>
                    <BiMessageDots />
                  </div>
                  <span>SMS</span>
                </CalendarLegendColorsItem>
                <CalendarLegendColorsItem color="#ff974a">
                  <div>
                    <BsFillEnvelopeFill />
                  </div>
                  <span>E-mail</span>
                </CalendarLegendColorsItem>
                <CalendarLegendColorsItem color="#E3262E">
                  <div>
                    <BsWifi />
                  </div>
                  <span>Wifi</span>
                </CalendarLegendColorsItem>
              </div>
            </CalendarToolbar>
          ),
          event: (item) => <PopupCampaignDetails campaign={item} />,
          week: {
            event: (item) => <PopupCampaignDetails campaign={item} />,
          },
        }}
        eventPropGetter={(event) => {
          const newStyle = {
            borderRadius: '3px',
            height: '17px',
            fontFamily: 'Poppins',
            fontSize: '9px',
            fontWeight: 'normal',
            color: '#26272a',
            padding: 0,
          };
          switch (event.type) {
            case 'video': {
              newStyle.backgroundColor = '#4a90e2';
              break;
            }
            case 'research': {
              newStyle.backgroundColor = '#ffc542';
              break;
            }
            case 'banner': {
              newStyle.backgroundColor = '#ff9ad5';
              break;
            }
            case 'sms': {
              newStyle.backgroundColor = '#3dd598';
              break;
            }
            case 'email': {
              newStyle.backgroundColor = '#ff974a';
              break;
            }
            case 'wifi': {
              newStyle.backgroundColor = '#E3262E';
              break;
            }
            default:
              break;
          }
          return {
            className: 'calendar-campaign-event',
            style: newStyle,
          };
        }}
      />
    );
  };

  const renderTable = () => {
    return (
      <RowTable>
        <h2>
          {selectTypeView === 'wifi' && 'Campanhas de rede wi-fi'}
          {selectTypeView === 'sms-email' && 'Campanhas de SMS e E-mail'}
        </h2>
        <Table>
          <thead>
            <tr>
              <th>Nome da campanha</th>
              <th>Status</th>
              <th>Tipo</th>
              <th>Pessoas alcançadas</th>
              <th width="1%">Ações</th>
            </tr>
          </thead>
          <tbody>
            {propagateData && propagateData.campaigns.length > 0 ? (
              propagateData.campaigns
                .filter((item) => {
                  return (
                    item.type === selectTypeView ||
                    (selectTypeView === 'sms-email' && item.type === 'sms') ||
                    (selectTypeView === 'sms-email' && item.type === 'email')
                  );
                })
                .map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>
                      {item.type === 'sms' && 'SMS'}
                      {item.type === 'email' && 'E-MAIL'}
                      {item.type === 'wifi' && 'WIFI'}
                    </td>
                    <td>{item.delivered}</td>
                    <td>
                      <TableActions>
                        <TabbleActionButton
                          onClick={() =>
                            history.push(`/propagate/${item.id}/result`)
                          }
                        >
                          Ver resultados
                        </TabbleActionButton>
                        <TabbleActionButton onClick={() => {}}>
                          Editar
                        </TabbleActionButton>
                        <CheckToggle value={false} onChange={() => {}} />
                      </TableActions>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="5" align="center">
                  Sem registros!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </RowTable>
    );
  };

  const renderRow3 = () => {
    const optionsSelect = [
      {
        label: 'Calendário',
        options: [
          {
            value: 'month',
            type: 'calendar',
            label: 'Calendário mês',
          },
          {
            value: 'week',
            type: 'calendar',
            label: 'Calendário semana',
          },
        ],
      },
      {
        label: 'Lista',
        options: [
          {
            value: 'wifi',
            type: 'list',
            label: 'Campanhas de rede wi-fi',
          },
          {
            value: 'sms-email',
            type: 'list',
            label: 'Campanhas de SMS e E-mail',
          },
        ],
      },
    ];
    let selectValue = null;
    if (selectTypeView === 'month') {
      selectValue = {
        value: 'month',
        type: 'calendar',
        label: 'Calendário mês',
      };
    }
    if (selectTypeView === 'week') {
      selectValue = {
        value: 'week',
        type: 'calendar',
        label: 'Calendário semana',
      };
    }
    if (selectTypeView === 'wifi') {
      selectValue = {
        value: 'wifi',
        type: 'list',
        label: 'Campanhas de rede wi-fi',
      };
    }
    if (selectTypeView === 'sms-email') {
      selectValue = {
        value: 'sms-email',
        type: 'list',
        label: 'Campanhas de SMS e E-mail',
      };
    }
    return (
      <Row3>
        <Row3Header>
          <span>Suas campanhas</span>
          <HeaderActions>
            <ButtonCreateCampaign
              title="Criar campanha"
              onClick={() => setModalCreateCampaignOpen(true)}
            />
            <Select
              placeholder="Campanha de SMS e E-mail"
              value={selectValue}
              onChange={(item) => {
                setSelectTypeView(item.value);
                setTimeout(() => {
                  if (item.type === 'calendar' && calendarRef.current) {
                    calendarRef.current.handleViewChange(item.value);
                  }
                }, 300);
              }}
              options={optionsSelect}
            />
          </HeaderActions>
        </Row3Header>
        <Row3Content viewType={selectTypeView}>
          {(selectTypeView === 'month' || selectTypeView === 'week') &&
            renderCalendar()}
          {(selectTypeView === 'wifi' || selectTypeView === 'sms-email') &&
            renderTable()}
        </Row3Content>
      </Row3>
    );
  };

  return (
    <WrapPage>
      <Container>
        {renderRow1()}
        {!!propagateData && renderRow2()}
        {renderRow3()}
        {modalCreateCampaignOpen && (
          <ModalCreateCampaign
            onClose={() => setModalCreateCampaignOpen(false)}
          />
        )}
      </Container>
    </WrapPage>
  );
};

export default Propagate;
