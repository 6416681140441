import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BiSquareRounded, BiCalendarAlt } from 'react-icons/bi';
import { AiOutlineStar } from 'react-icons/ai';
import { MdRadioButtonUnchecked } from 'react-icons/md';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Container,
  CheckTypeBox,
  RadioTypeBox,
  TextTypeBox,
  NumberTypeBox,
  RangeTypeBox,
  DateTypeBox,
} from './styles';

registerLocale('pt-BR', pt);

function Preview({ formItems }) {
  const [actualStep, setActualStep] = useState(null);

  useEffect(() => {
    if (formItems.length > 0 && !actualStep) {
      setActualStep(0);
    }
  }, [formItems]);

  const renderCheckType = (field) => (
    <CheckTypeBox>
      {field.options.map((i) => (
        <div className="options-item" key={i.uuid}>
          <BiSquareRounded />
          <span>{i.option}</span>
        </div>
      ))}
    </CheckTypeBox>
  );

  const renderRadioType = (field) => (
    <RadioTypeBox>
      {field.options.map((i) => (
        <div className="options-item" key={i.uuid}>
          <MdRadioButtonUnchecked />
          <span>{i.option}</span>
        </div>
      ))}
    </RadioTypeBox>
  );

  const renderTextType = () => (
    <TextTypeBox>
      <input type="text" placeholder="Abc" />
    </TextTypeBox>
  );

  const renderNumberType = () => (
    <NumberTypeBox>
      <input type="number" placeholder="1234567890" />
    </NumberTypeBox>
  );

  const renderRangeType = () => (
    <RangeTypeBox>
      <AiOutlineStar />
      <AiOutlineStar />
      <AiOutlineStar />
      <AiOutlineStar />
      <AiOutlineStar />
    </RangeTypeBox>
  );

  const renderDateType = () => (
    <DateTypeBox>
      <DatePicker
        type="text"
        placeholder="99/99/9999"
        locale="pt-BR"
        dateFormat="dd/MM/yyyy"
        popperPlacement="top-end"
      />
      <BiCalendarAlt />
    </DateTypeBox>
  );

  const renderOptionsByType = (field) => {
    switch (field.type) {
      case 'check':
        return renderCheckType(field);
      case 'radio':
        return renderRadioType(field);
      case 'text':
        return renderTextType(field);
      case 'number':
        return renderNumberType(field);
      case 'range':
        return renderRangeType(field);
      case 'date':
        return renderDateType(field);
      default:
        return null;
    }
  };

  const renderQuestion = () => {
    const item = formItems[actualStep];
    if (formItems.length > 0 && item) {
      return (
        <>
          <div className="preview-question">
            <span>{item.question}</span>
            {renderOptionsByType(item)}
          </div>
          <div className="preview-footer">
            <button
              disabled={actualStep === 0}
              type="button"
              onClick={() => {
                setActualStep(actualStep - 1);
              }}
            >
              Anterior
            </button>
            <button
              disabled={actualStep === formItems.length - 1}
              type="button"
              onClick={() => {
                setActualStep(actualStep + 1);
              }}
            >
              Próximo
            </button>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <Container>
      {formItems.length > 1 && (
        <div className="preview-steps">
          {formItems.map((item, index) => (
            <span
              key={item.uuid}
              className={actualStep === index ? 'preview-steps-active' : ''}
            />
          ))}
        </div>
      )}
      {renderQuestion()}
    </Container>
  );
}

Preview.propTypes = {
  formItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Preview;
