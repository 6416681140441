import styled from 'styled-components';

import InputComponent from '~/components/Input';

export const Container = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;

  .base-image {
    margin-top: 50px;
  }

  .tooltip-wrap {
    width: 300px;
  }
`;

export const Col = styled.div`
  flex: 1;
`;

export const Base = styled.div`
  flex: 1;
  max-width: 500px;

  > h1 {
    margin: 25px 0;
  }

  .info {
    max-width: 380px;
    line-height: 25px;
    margin: 15px 0 30px 0;
  }
`;

export const Input = styled(InputComponent).attrs({
  maxWidth: 380,
})`
  margin-right: 15px;
`;

export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 7px;
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
`;

export const PreviewBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 60px;
`;

export const PreviewDescription = styled.div`
  display: flex;
  width: 350px;
`;

export const PreviewDescriptionIcon = styled.div`
  display: flex;
  margin-right: 10px;
  > svg {
    font-size: 20px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const PreviewDescriptionContent = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.3;
  font-weight: 600;
  display: flex;
  flex: 1;
  text-align: justify;
`;

export const PreviewBanner = styled.div`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const PreviewTitle = styled.div`
  position: absolute;
  top: 127px;
  left: 22px;
  min-width: 170px;
  max-width: 170px;
  height: fit-content;
  color: #0a0724;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
`;

export const TooltipTitle = styled.h4`
  margin-bottom: 5px;
`;

export const TooltipContent = styled.p`
  font-size: 13px;
`;
