import styled from 'styled-components';
import supportBg from '~/assets/images/support-bg.png';
import ButtonComponent from '~/components/Button';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
  background-image: url(${supportBg});
  background-repeat: no-repeat;
  background-size: 522px auto;
  background-position-x: 100%;
  background-position-y: 70px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 100px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 537px;
  p.description {
    margin-top: 10px;
    font-family: Quicksand;
    font-size: 18px;
    font-weight: normal;
    line-height: 2.78;
    letter-spacing: normal;
    color: #0a0724;
  }
  p.list-title {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: normal;
    line-height: 3.13;
    color: #0a0724;
  }
`;

export const ListBox = styled.ul`
  padding: 0;
  margin: 20px 0 0 0;
  list-style: none;
`;

export const ListItem = styled.li`
  height: 50px;
  position: relative;
  border-bottom: 1px solid #97979725;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  text-align: left;
  color: #0a0724;

  &:hover {
    background-color: ${(props) => props.theme.colors.lighterWhite};
  }
  &:last-child {
    border-bottom: 0;
  }
  svg {
    position: absolute;
    right: 10px;
    color: #fc5a5a;
    font-size: 18px;
  }
`;

export const Footerbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 537px;
  height: 115px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px ${(props) => props.theme.colors.lighterWhite};
  margin-top: 20px;
  padding: 0 30px;
  .description {
    font-size: 17px;
    font-weight: normal;
    font-family: Quicksand;
  }
`;

export const ButtonContactUs = styled(ButtonComponent).attrs({
  style: {
    height: 40,
    minHeight: 40,
    paddingLeft: 30,
    paddingRight: 30,
    width: 180,
  },
})``;
