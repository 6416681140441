import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  height: 22px;
  padding: 0;
  margin: 0;
  .react-custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: #34c759;
  }
  .react-custom-toggle .react-toggle-track {
    background-color: #e9e9eb;
  }
  .react-custom-toggle .react-toggle-thumb {
    border: 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
`;
