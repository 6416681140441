import React from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { Container } from './styles';

function DropZoneButton({
  title,
  multiple,
  onChange,
  className,
  accept,
  disabled,
}) {
  const onDrop = (acceptedFiles) => {
    onChange(multiple ? acceptedFiles : acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    disabled,
  });

  const renderUploader = () => (
    <Container {...getRootProps()} className={className} disabled={disabled}>
      <input {...getInputProps()} multiple={multiple} />
      {title}
    </Container>
  );

  return renderUploader();
}

DropZoneButton.defaultProps = {
  title: '',
  multiple: false,
  onChange: () => {},
  accept: '*.*',
  disabled: false,
};

DropZoneButton.propTypes = {
  title: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DropZoneButton;
