import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  padding: 52px 18px;
  > div.container-header-content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    h2 {
      font-family: Poppins;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      letter-spacing: 0.11px;
      color: #323236;
      margin-bottom: 5px;
    }
    span {
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.83;
      letter-spacing: 0.09px;
      color: #323236;
    }
    a {
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.83;
      letter-spacing: 0.09px;
      color: #f5a623;
    }
  }
`;

export const CampaignIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  svg {
    font-size: 25px;
    color: #0a0724;
  }
`;

export const ContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  > h2 {
    display: flex;
    align-items: center;
    font-family: Quicksand;
    font-size: 24px;
    font-weight: normal;
    line-height: 1;
    color: #0a0724;
    margin: 30px 0 20px 0;
    svg {
      font-size: 20px;
      margin-left: 10px;
    }
  }
  > div.campaign-box-section-content {
    display: flex;
    width: 100%;
  }
`;

export const ContainerSectionCard = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  flex-direction: column;
  padding: 30px 20px 20px 20px;
  border-radius: 6px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  > span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    color: #0f2522;
    display: flex;
    align-items: center;
    svg {
      cursor: pointer;
      margin-left: 10px;
      color: #323236;
      font-size: 17px;
    }
  }
  > h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    color: #0a0724;
  }
  > h2 {
    font-family: Poppins;
    font-size: 43px;
    font-weight: 600;
    line-height: 1;
    color: #0a0724;
  }
`;

export const CampaignBoxDemographicCard = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  flex-direction: column;
  height: 350px;
  padding: 30px 20px 20px 20px;
  border-radius: 6px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  > span {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    color: #171725;
    letter-spacing: 0.13px;
    display: flex;
    align-items: center;
  }
`;
