import styled from 'styled-components';
import ColorPickerComponent from '~/components/ColorPicker';
import Button from '~/components/Button';

export const Container = styled.div`
  width: 100%;
  .template-profilepic-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: ${(props) => (props.mode === 'mobile' ? 10 : 30)}px;
    padding-bottom: 0;
  }
  .template-title-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 ${(props) => (props.mode === 'mobile' ? 10 : 30)}px;
  }
  .template-image-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 ${(props) => (props.mode === 'mobile' ? 10 : 30)}px;
  }
  .template-subtitle-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 ${(props) => (props.mode === 'mobile' ? 10 : 30)}px;
  }
  .template-content-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 ${(props) => (props.mode === 'mobile' ? 10 : 30)}px;
  }
  .template-button-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 ${(props) => (props.mode === 'mobile' ? 10 : 30)}px;
    button {
      height: 60px;
      border-radius: 30px;
      background-color: #e3262e;
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      color: #fafafb;
      border: 0;
    }
  }
  .template-footer-box {
    display: flex;
    width: 100%;
    height: 100px;
    background-color: #d1d1d1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 ${(props) => (props.mode === 'mobile' ? 10 : 30)}px;
    > b {
      font-family: Poppins;
      font-size: 10px;
      font-weight: bold;
      line-height: 2.4;
      letter-spacing: 0.1px;
      color: #0a0724;
      word-break: break-all;
      text-align: center;
    }
    > span {
      font-family: Poppins;
      font-size: 10px;
      font-weight: normal;
      line-height: 2.4;
      letter-spacing: 0.1px;
      text-align: justify;
      color: #0a0724;
      word-break: break-all;
      text-align: center;
      > a {
        color: #0062ff;
        margin-left: 3px;
      }
    }
  }
`;

export const ActionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  height: auto;
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  .action-box-row {
    display: flex;
    flex-direction: column;
  }
`;

export const ColorBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0.1px;
  text-align: left;
  color: #0a0724;
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const ColorPicker = styled(ColorPickerComponent).attrs({
  style: {
    marginLeft: 20,
  },
})``;

export const ButtonNext = styled(Button)``;

export const ButtonCancel = styled(Button)`
  color: #fa6115;
  background-color: transparent;
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  border: 0;
`;
