/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { FaCircle } from 'react-icons/fa';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';

import {
  Container,
  LegendBox,
  LegendBoxItem,
  LegendBoxItemPercentage,
  LegendBoxItemAmount,
} from './styles';

function NetworkAccessByCustomers({ data }) {
  const colors = ['#0062FF', '#ADCDFF'];
  const renderChart = () => {
    const dataChart = {
      series: data.map((item) => item.value),
      options: {
        chart: {
          zoom: { enabled: false },
          toolbar: { tools: { download: false } },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        fill: {
          colors,
          opacity: 1,
          type: 'solid',
        },
        legend: {
          show: false,
        },
        labels: data.map((item) => item.label),
      },
    };
    return (
      <Chart
        options={dataChart.options}
        series={dataChart.series}
        type="donut"
        width={200}
        className="custom-chart"
      />
    );
  };

  return (
    <Container>
      {renderChart()}
      <LegendBox>
        {data.map((item, index) => (
          <LegendBoxItem key={index}>
            <FaCircle color={colors[index]} />
            <LegendBoxItemPercentage isPositive={item.percentageType === 'up'}>
              {`${item.percentageType === 'up' ? '+' : '-'} ${
                item.percentage
              }%`}
              {item.percentageType === 'up' && <MdArrowUpward />}
              {item.percentageType === 'down' && <MdArrowDownward />}
            </LegendBoxItemPercentage>
            <LegendBoxItemAmount>
              {item.value}
              <small>{item.label}</small>
            </LegendBoxItemAmount>
          </LegendBoxItem>
        ))}
      </LegendBox>
    </Container>
  );
}
NetworkAccessByCustomers.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default NetworkAccessByCustomers;
