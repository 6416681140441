import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .custom-chart {
    min-height: unset !important;
    .apexcharts-canvas {
      > svg {
        margin-top: -25px !important;
      }
    }
  }
`;

export const LegendBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-top: -25px;
`;

export const LegendBoxItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
`;

export const LegendBoxItemLabel = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  svg {
    font-size: 15px;
    margin-right: 10px;
  }
`;

export const LegendBoxItemPercentage = styled.div`
  display: flex;
  flex: 1;
  color: #1e1f20;
  font-size: 14px;
  font-weight: bold;
`;
