import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const Pages = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100% -73px);
  min-height: calc(100% -73px);
  position: relative;
  align-items: center;
  background-color: #fafafb;
`;

export const WrapContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  margin-left: 73px;
`;
