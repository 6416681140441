import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function ChartDateType({ data }) {
  return (
    <Container>
      {data.answers.map((item) => (
        <span>{item.answer}</span>
      ))}
      <div>
        Para visualizar mais respostas da pesquisa{' '}
        <a href="http://google.com" target="_blank" rel="noreferrer">
          baixe o relatório aqui
        </a>
        .
      </div>
    </Container>
  );
}

ChartDateType.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ChartDateType;
