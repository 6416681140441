import styled from 'styled-components';
import TableComponent from '~/components/Table';
import Input from '~/components/Input';

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: 1280px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 80px 30px 80px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 35px;

  > svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;

export const InputSearch = styled(Input)`
  width: 200px;
  height: 42px;
  border-radius: 30px;
  border: solid 2px #fc5a5a;
  background-color: #fafafb;
  margin-right: 15px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 21px;
    height: 21px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const PaginateBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 35px;
`;

export const Table = styled(TableComponent)`
  tbody {
    tr {
      cursor: pointer;
    }
  }
`;
