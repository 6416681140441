import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.expandedMenu ? 260 : 73)}px;
  transition: width 0.5s;
  height: 100%;
  background-color: #474f5c;
  display: flex;
  flex-direction: column;
`;

export const HamburguerBox = styled.div`
  width: ${(props) => (props.expandedMenu ? 260 : 73)}px;
  transition: width 0.5s;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HamburguerItem = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 73px;
  border: 0;
  background: transparent;
  svg {
    color: #ffffff;
    font-size: 24px;
    animation: fadein 2s;
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const MenuBox = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.expandedMenu ? 260 : 73)}px;
  transition: width 0.5s;
  padding-top: 20px;
`;

export const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0px;
  }
  &.active {
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
  span {
    display: flex;
    flex: 1;
    margin-left: 25px;
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
    line-height: 25px;
    animation: fadein 2s;
  }
  svg {
    font-size: 24px;
    margin-left: 25px;
    animation: fadein 2s;
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
