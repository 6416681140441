/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from 'styled-components';

import confirm from '~/components/Confirm';
import history from '~/services/history';

import {
  WrapPage,
  Container,
  VideoTitle,
  VideoSubtitle,
  VideoLogo,
  Label,
  SubLabel,
  InputName,
  InputWrap,
  InputBox,
  Legend,
  ButtonNext,
  ButtonCancel,
  SectionSeparator,
  CheckToggle,
  Checkbox,
} from './styles';

function Step1({ theme }) {
  const [campaignName, setCampaignName] = useState('');
  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [didNotReturnSlider, setDidNotReturnSlider] = useState(50);
  const [visitedEstablishment, setVisitedEstablishment] = useState(50);
  const [genderCkecks, setGenderCkecks] = useState([]);
  const [ageRangeCkecks, setAgeRangeCkecks] = useState([]);
  const [allCustomersCheck, setAllCustomersCheck] = useState(true);
  const [didNotReturnCheck, setDidNotReturnCheck] = useState(false);
  const [frequentCustomersCheck, setFrequentCustomersCheck] = useState(false);

  const resetFormData = () => {
    setAllCustomersCheck(true);
    setDidNotReturnCheck(false);
    setFrequentCustomersCheck(false);
    setDidNotReturnSlider(50);
    setVisitedEstablishment(50);
    setGenderCkecks([]);
    setAgeRangeCkecks([]);
  };

  useEffect(resetFormData, []);

  const LightTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#FFFFFF',
      color: '#171725',
      boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.5)',
      border: '1px solid #e3e3e3',
      fontSize: 12,
    },
  }))(Tooltip);
  // eslint-disable-next-line react/prop-types
  const ValueLabelComponent = ({ children, open, value }) => (
    <LightTooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom"
      title={value}
      style={{
        backgroundColor: 'red',
      }}
    >
      {children}
    </LightTooltip>
  );

  return (
    <WrapPage>
      <Container>
        <VideoTitle>
          <Link to="/propagate">
            <FaChevronLeft size={14} />
          </Link>
          E-mail
        </VideoTitle>
        <VideoSubtitle>PASSO 1/3</VideoSubtitle>
        <VideoLogo />
        <InputWrap>
          <InputBox>
            <Label>
              Nome da campanha
              <FaInfoCircle
                data-tip="Escolha um nome que seja de fácil identificação da campanha."
                data-for="tooltip-provider"
              />
            </Label>
            <InputName
              name="campaignName"
              type="text"
              placeholder="Digite o nome da campanha"
              value={campaignName}
              onChange={(e) => {
                setCampaignName(e.target.value);
              }}
            />
          </InputBox>
        </InputWrap>
        <Legend style={{ marginBottom: 30 }}>
          Exemplo: "Promoções de Natal". Seu cliente não irá visualizar esse
          item.
        </Legend>
        <InputWrap>
          <InputBox>
            <Label style={{ marginBottom: 4 }}>
              Assunto
              <FaInfoCircle data-tip="Assunto." data-for="tooltip-provider" />
            </Label>
            <SubLabel style={{ marginBottom: 30 }}>
              Sobre o que esta campanha fala
            </SubLabel>
            <InputName
              name="Subject"
              type="text"
              placeholder="Digite o assunto"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
          </InputBox>
        </InputWrap>
        <Legend style={{ marginBottom: 30 }}>
          Exemplo: "Promoções de Natal". Seu cliente não irá visualizar esse
          item.
        </Legend>
        <InputWrap>
          <InputBox>
            <Label style={{ marginBottom: 4 }}>
              Remetente
              <FaInfoCircle data-tip="Remetente." data-for="tooltip-provider" />
            </Label>
            <SubLabel style={{ marginBottom: 30 }}>
              Quem está enviando a campanha
            </SubLabel>
          </InputBox>
        </InputWrap>
        <InputWrap>
          <InputBox style={{ marginRight: 24 }}>
            <Label style={{ fontWeight: 'normal' }}>
              Nome
              <FaInfoCircle data-tip="Nome." data-for="tooltip-provider" />
            </Label>
            <InputName
              name="name"
              type="text"
              placeholder="Digite o nome"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </InputBox>
          <InputBox>
            <Label style={{ fontWeight: 'normal' }}>
              E-mail
              <FaInfoCircle data-tip="E-mail." data-for="tooltip-provider" />
            </Label>
            <InputName
              name="Email"
              type="text"
              placeholder="Digite o nome da campanha"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </InputBox>
        </InputWrap>
        <Legend style={{ marginBottom: 30 }}>
          Exemplo: "Nome do seu estabelecimento"
        </Legend>
        <SectionSeparator style={{ marginBottom: 30 }}>
          <b>Público alvo</b>
          <span>
            Selecione as características do público com quem deseja se
            comunicar.
          </span>
        </SectionSeparator>
        <InputWrap style={{ marginBottom: 50 }}>
          <div className="input-wrap-body">
            <span>Presença no estabelecimento</span>
            <div className="toggle-input-box">
              <span>Todos os clientes</span>
              <CheckToggle
                value={allCustomersCheck}
                onChange={(e) => {
                  if (e.target.checked) {
                    confirm({
                      theme,
                      title: 'Atenção',
                      close: false,
                      message:
                        'Ao marcar esta função, o filtro de público criado será desconsiderado',
                      button: {
                        label: 'Ok, entendi',
                        onClick: () => resetFormData(),
                      },
                    });
                  } else {
                    setAllCustomersCheck(e.target.checked);
                  }
                }}
              />
            </div>
          </div>
        </InputWrap>
        <InputWrap style={{ marginBottom: 50 }}>
          <div className="input-wrap-body">
            <div className="toggle-input-box" disabled={allCustomersCheck}>
              <span>
                Clientes frequentes
                <FaInfoCircle
                  data-tip="Clientes frequentes são aqueles que fizeram mais de uma visita nos últimos 30 dias"
                  data-for="tooltip-provider"
                />
              </span>
              <CheckToggle
                value={frequentCustomersCheck}
                onChange={(e) => setFrequentCustomersCheck(e.target.checked)}
                disabled={allCustomersCheck}
              />
            </div>
          </div>
        </InputWrap>
        <InputWrap style={{ marginBottom: 50 }}>
          <div className="input-wrap-body">
            <div className="toggle-input-box" disabled={allCustomersCheck}>
              <span>
                Clientes que não retornaram
                <FaInfoCircle
                  data-tip="Escolha abaixo o período de dias"
                  data-for="tooltip-provider"
                />
              </span>
              <CheckToggle
                value={didNotReturnCheck}
                onChange={(e) => setDidNotReturnCheck(e.target.checked)}
                disabled={allCustomersCheck}
              />
            </div>
            <div className="toggle-slider-box">
              <span className="toggle-slider-legend">1 dia</span>
              <Slider
                value={didNotReturnSlider}
                min={1}
                max={90}
                step={1}
                aria-labelledby="Zoom"
                onChange={(e, value) => setDidNotReturnSlider(value)}
                className="slider-component"
                disabled={allCustomersCheck}
                ValueLabelComponent={ValueLabelComponent}
              />
              <span className="toggle-slider-legend">90 dias</span>
            </div>
          </div>
        </InputWrap>
        <InputWrap style={{ marginBottom: 50 }}>
          <div className="input-wrap-body">
            <div className="toggle-input-box" disabled={allCustomersCheck}>
              <span>
                Clientes que visitaram o estabelecimento
                <FaInfoCircle
                  data-tip="Escolha abaixo o período de dias"
                  data-for="tooltip-provider"
                />
              </span>
              <CheckToggle
                value={false}
                onChange={() => {}}
                disabled={allCustomersCheck}
              />
            </div>
            <div className="toggle-slider-box">
              <span className="toggle-slider-legend">1 dia</span>
              <Slider
                value={visitedEstablishment}
                min={1}
                max={90}
                step={1}
                aria-labelledby="Zoom"
                onChange={(e, value) => setVisitedEstablishment(value)}
                className="slider-component"
                disabled={allCustomersCheck}
                ValueLabelComponent={ValueLabelComponent}
              />
              <span className="toggle-slider-legend">90 dias</span>
            </div>
          </div>
        </InputWrap>
        <InputWrap style={{ marginBottom: 30 }}>
          <div className="input-wrap-body">
            <span>Gênero</span>
            <div className="toggle-checklist-box">
              {['Masculino', 'Feminino', 'Outros'].map((item) => (
                <div key={item} className="toggle-checklist-box-item">
                  <Checkbox
                    label={item}
                    value={!!genderCkecks.find((i) => i === item)}
                    onChange={(checked) => {
                      if (checked) {
                        setGenderCkecks([...genderCkecks, item]);
                      } else {
                        setGenderCkecks(genderCkecks.filter((i) => i !== item));
                      }
                    }}
                    disabled={allCustomersCheck}
                  />
                </div>
              ))}
            </div>
          </div>
        </InputWrap>
        <InputWrap style={{ marginBottom: 30 }}>
          <div className="input-wrap-body">
            <span>Faixa etária (anos):</span>
            <div className="toggle-checklist-box">
              {[
                '18 a 24 anos',
                '25 a 39 anos',
                '40 a 54 anos',
                '55 a 64 anos',
                'Maior que 65 anos',
                'Menor que 18 anos',
                'Não identificado',
              ].map((item) => (
                <div key={item} className="toggle-checklist-box-item">
                  <Checkbox
                    label={item}
                    value={!!ageRangeCkecks.find((i) => i === item)}
                    onChange={(checked) => {
                      if (checked) {
                        setAgeRangeCkecks([...ageRangeCkecks, item]);
                      } else {
                        setAgeRangeCkecks(
                          ageRangeCkecks.filter((i) => i !== item)
                        );
                      }
                    }}
                    disabled={allCustomersCheck}
                  />
                </div>
              ))}
            </div>
          </div>
        </InputWrap>
        <InputWrap style={{ marginBottom: 100 }}>
          <div className="input-wrap-body">
            <span>
              Estimativa de pessoas alcançadas
              <FaInfoCircle
                data-tip="Estimativa de pessoas alcançadas"
                data-for="tooltip-provider"
              />
            </span>
            <div className="toggle-message-box">
              <h2>1.800 pessoas</h2>
              <p>
                Refine seu público para alcançar mais pessoas que sejam
                relevantes para você.
              </p>
            </div>
          </div>
        </InputWrap>
        <InputWrap style={{ marginBottom: 30 }}>
          <ButtonNext
            title="Avançar"
            onClick={() => history.push('/propagate/email/step2')}
          />
          <ButtonCancel
            title="Cancelar"
            onClick={() => history.push('/propagate')}
          />
        </InputWrap>
        <ReactTooltip
          place="right"
          backgroundColor="#FFFFFF"
          textColor="#323232"
          border
          borderColor="#d1d1d1"
          className="tooltip-wrap"
          id="tooltip-provider"
        />
      </Container>
    </WrapPage>
  );
}

Step1.propTypes = {
  theme: PropTypes.shape().isRequired,
};

export default withTheme(Step1);
