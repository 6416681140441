import React from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'react-awesome-modal';
import { IoMdClose } from 'react-icons/io';

import { Container, ButtonClose } from './styles';

function Modal({ visible, children, width, height, onClose }) {
  return (
    <ModalComponent
      visible={visible}
      width={width}
      height={height}
      effect="fadeInUp"
      onClickAway={() => {}}
    >
      <Container>
        {!!onClose && (
          <ButtonClose onClick={onClose}>
            <IoMdClose />
          </ButtonClose>
        )}
        {children}
      </Container>
    </ModalComponent>
  );
}

Modal.defaultProps = {
  width: '300',
  height: null,
  onClose: null,
};

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onClose: PropTypes.func,
};

export default Modal;
