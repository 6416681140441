import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ButtonClose = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 24px;
  right: 24px;
  border: 0;
  background: transparent;
  svg {
    font-size: 28px;
    color: #92929d;
  }
`;
