/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { IoMdDownload } from 'react-icons/io';
import { GoSearch } from 'react-icons/go';
import DashboardTitle from '~/components/DashboardTitle';
import PaginateData from '~/components/PaginateData';
import ModalVisitorDetails from './components/ModalVisitorDetails';

import {
  visitorsDataRequest,
  visitorsSetState,
} from '~/store/modules/visitors/actions';

import {
  WrapPage,
  Container,
  Header,
  HeaderActions,
  InputSearch,
  Row,
  PaginateBox,
  Table,
} from './styles';

function Visitors() {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const { list, filter } = useSelector((state) => state.visitors);

  useEffect(() => {
    dispatch(visitorsDataRequest());
  }, [filter.page]);

  const handleTableRowClick = (visitorDetails) => {
    dispatch(visitorsSetState({ visitorDetails }));
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      dispatch(visitorsDataRequest({ search }));
    }
  };

  const renderHeader = () => (
    <DashboardTitle title="Visitantes">
      <HeaderActions>
        <InputSearch
          maxWidth={200}
          placeHolderAlign="center"
          icon={GoSearch}
          placeholder="Buscar"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <IoMdDownload onClick={() => {}} />
      </HeaderActions>
    </DashboardTitle>
  );

  const renderRow1 = () => {
    return (
      <Row>
        <Table>
          <thead>
            <tr>
              <th>CPF/Passaporte</th>
              <th>Primeiro nome</th>
              <th>Sobrenome</th>
              <th>Celular</th>
              <th>Email</th>
              <th>Data de Nascimento</th>
              <th>Gênero</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 ? (
              list.map((item) => (
                <tr
                  key={item.id}
                  onClick={() => handleTableRowClick(item)}
                  style={{
                    backgroundColor: !item.status && 'rgba(252, 90, 90, 0.15)',
                  }}
                >
                  <td>{item.document}</td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>{moment(item.birthDate).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{item.gender}</td>
                  <td>{item.status ? 'Desbloqueado' : 'Bloqueado'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" align="center">
                  Sem registros!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
    );
  };

  const renderRow2 = () => {
    return (
      <PaginateBox>
        <PaginateData
          pageCount={Math.ceil(18 / 6)}
          onPageChange={(data) => {
            const page = data.selected + 1;
            dispatch(visitorsSetState({ filter: { ...filter, page } }));
          }}
        />
      </PaginateBox>
    );
  };

  const renderContent = () => (
    <Container>
      <Header>{renderHeader()}</Header>
      {renderRow1()}
      {renderRow2()}
    </Container>
  );

  return (
    <WrapPage>
      {renderContent()}
      <ModalVisitorDetails />
    </WrapPage>
  );
}

export default Visitors;
