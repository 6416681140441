import { combineReducers } from 'redux';

import user from './user/reducer';
import menuLeft from './menuLeft/reducer';
import dashboard from './dashboard/reducer';
import visitors from './visitors/reducer';
import header from './header/reducer';
import supports from './supports/reducer';
import propagate from './propagate/reducer';
import loading from './loading/reducer';

export default combineReducers({
  user,
  menuLeft,
  dashboard,
  visitors,
  header,
  supports,
  propagate,
  loading,
});
