import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex};
  padding: 24px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-right: ${(props) => props.spaceRight}px;
  margin-left: ${(props) => props.spaceLeft}px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  h3 {
    color: #171725;
    font-size: 18px;
    font-weight: 600;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  flex: 1;
`;
