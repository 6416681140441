import types from './types';

export function visitorsSetState(state) {
  return {
    type: types.VISITORS_SET_STATE,
    payload: { state },
  };
}

export function visitorsDataRequest(params) {
  return {
    type: types.VISITORS_DATA_REQUEST,
    payload: { params },
  };
}
