import styled, { css } from 'styled-components';

export const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .radio {
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.colors.primary};
  }

  &:hover input ~ .radio {
    background-color: #fff;
  }

  input:checked ~ .radio {
    background-color: ${(props) =>
      props.colorChecked ? props.colorChecked : props.theme.colors.primary};
    border-color: ${(props) =>
      props.colorChecked ? props.colorChecked : props.theme.colors.primary};
  }

  .radio:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .radio:after {
    display: block;
  }

  .radio:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`;
