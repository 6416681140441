import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import { IoIosSearch, IoIosArrowDown } from 'react-icons/io';

import { Container, Select } from './styles';

function SearchWithOrderBy({
  options,
  valueOrder,
  valueSearch,
  onChangeOrder,
  onChangeSearch,
}) {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <IoIosArrowDown color="#FA6115" size={20} />
      </components.DropdownIndicator>
    );
  };

  return (
    <Container>
      <Select
        components={{ DropdownIndicator }}
        value={valueOrder}
        onChange={onChangeOrder}
        options={options}
      />
      <input
        type="text"
        value={valueSearch}
        onChange={(e) => onChangeSearch(e.target.value)}
      />
      <button type="button">
        <IoIosSearch />
      </button>
    </Container>
  );
}

SearchWithOrderBy.propTypes = {
  options: PropTypes.string.isRequired,
  valueOrder: PropTypes.string.isRequired,
  valueSearch: PropTypes.string.isRequired,
  onChangeOrder: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.string.isRequired,
};

export default SearchWithOrderBy;
