import React from 'react';
import PropTypes from 'prop-types';

import SmartphonePreview from '~/components/SmartphonePreview';
import { Container, SmartphoneContent } from './styles';

function WrapSmartphone({ children }) {
  return (
    <Container>
      <SmartphonePreview>
        <SmartphoneContent>{children}</SmartphoneContent>
      </SmartphonePreview>
    </Container>
  );
}

WrapSmartphone.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WrapSmartphone;
