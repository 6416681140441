import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-tiny-popover';
import ReactTooltip from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import color from 'color';

import ProfilePic from '../ProfilePic';
import Editor from '../Editor';
import Input from '~/components/Input';
import Dropzone from '../Dropzone';
import { fileToBase64 } from '~/utils';

import {
  Container,
  ActionBox,
  ColorBox,
  Label,
  ColorPicker,
  ButtonNext,
  ButtonCancel,
} from './styles';

function Template1({ mode, value, onChange, disabled }) {
  const [actionIsOpen, setActionIsOpen] = useState(false);
  const [dataBkp, setDataBkp] = useState({
    actionLabel: '',
    actionLink: '',
    actionColor: '',
  });
  const renderProfileBox = () => (
    <div className="template-profilepic-box">
      <ProfilePic profilePic={value.profilePic} />
    </div>
  );

  const renderTitleBox = () => (
    <div className="template-title-box">
      <Editor
        disabled={disabled}
        multiple={false}
        buttonsShow={{
          hasFont: true,
          hasAlign: true,
          hasBold: true,
          hasItalic: true,
          hasUnderlined: true,
        }}
        textValue={value.title}
        onChangeTextValue={(title) =>
          onChange({
            ...value,
            title,
          })
        }
        textStyle={value.styleTitle}
        onChangeTextStyle={(styleTitle) =>
          onChange({
            ...value,
            styleTitle,
          })
        }
      />
    </div>
  );

  const renderImageBox = () => (
    <div className="template-image-box">
      <Dropzone
        value={value.contentImage}
        disabled={disabled}
        onDropOk={async (file) => {
          const base64File = file ? await fileToBase64(file) : null;
          onChange({
            ...value,
            contentImage: base64File,
          });
        }}
        onClear={() => {
          onChange({
            ...value,
            contentImage: '',
          });
        }}
      />
    </div>
  );

  const renderSubtitleBox = () => (
    <div className="template-subtitle-box">
      <Editor
        disabled={disabled}
        multiple={false}
        buttonsShow={{
          hasFont: true,
          hasAlign: true,
          hasBold: true,
          hasItalic: true,
          hasUnderlined: true,
        }}
        textValue={value.subtitle}
        onChangeTextValue={(subtitle) =>
          onChange({
            ...value,
            subtitle,
          })
        }
        textStyle={value.styleSubtitle}
        onChangeTextStyle={(styleSubtitle) =>
          onChange({
            ...value,
            styleSubtitle,
          })
        }
      />
    </div>
  );

  const renderContentBox = () => (
    <div className="template-content-box">
      <Editor
        disabled={disabled}
        multiple
        buttonsShow={{
          hasFont: true,
          hasAlign: true,
          hasBold: true,
          hasItalic: true,
          hasUnderlined: true,
        }}
        textValue={value.content}
        onChangeTextValue={(content) =>
          onChange({
            ...value,
            content,
          })
        }
        textStyle={value.styleContent}
        onChangeTextStyle={(styleContent) =>
          onChange({
            ...value,
            styleContent,
          })
        }
      />
    </div>
  );

  const renderButtonBox = () => (
    <div className="template-button-box">
      <Popover
        isOpen={actionIsOpen}
        position="top"
        padding={5}
        onClickOutside={() => {}}
        containerStyle={{
          overflow: 'visible',
        }}
        content={() => (
          <ActionBox>
            <div className="action-box-row" style={{ marginBottom: 30 }}>
              <Label>Editar botão de direcionamento</Label>
            </div>
            <div className="action-box-row" style={{ marginBottom: 20 }}>
              <Label style={{ marginBottom: 5 }}>
                Texto botão
                <FaInfoCircle
                  data-tip="Texto botão."
                  data-for="tooltip-provider"
                />
              </Label>
              <Input
                name="inputNomeRede"
                type="text"
                placeholder="Texto botão"
                value={value.actionLabel}
                onChange={(e) => {
                  onChange({
                    ...value,
                    actionLabel: e.target.value,
                  });
                }}
              />
            </div>
            <div className="action-box-row" style={{ marginBottom: 20 }}>
              <Label style={{ marginBottom: 5 }}>
                Inserir link
                <FaInfoCircle
                  data-tip="Escolha um nome que seja de fácil identificação da campanha."
                  data-for="tooltip-provider"
                />
              </Label>
              <Input
                name="inputNomeRede"
                type="text"
                placeholder="https://"
                value={value.actionLink}
                onChange={(e) => {
                  onChange({
                    ...value,
                    actionLink: e.target.value,
                  });
                }}
              />
            </div>
            <div className="action-box-row" style={{ marginBottom: 20 }}>
              <ColorBox>
                <Label>Selecione suas cores</Label>
                <ColorPicker
                  value={value.actionColor}
                  onChange={(hex) => {
                    onChange({
                      ...value,
                      actionColor: hex,
                    });
                  }}
                  className="tour-step-3"
                />
              </ColorBox>
            </div>
            <div className="action-box-row" style={{ flexDirection: 'row' }}>
              <ButtonNext
                disabled={
                  !value.actionColor || !value.actionLink || !value.actionLabel
                }
                title="Salvar"
                onClick={() => {
                  setActionIsOpen(false);
                }}
              />
              <ButtonCancel
                title="Cancelar"
                onClick={() => {
                  setActionIsOpen(false);
                  onChange({
                    ...value,
                    ...dataBkp,
                  });
                }}
              />
            </div>
            <ReactTooltip
              place="right"
              backgroundColor="#FFFFFF"
              textColor="#323232"
              border
              borderColor="#d1d1d1"
              className="tooltip-wrap"
              id="tooltip-provider"
            />
          </ActionBox>
        )}
      >
        <button
          type="button"
          style={{
            backgroundColor: value.actionColor,
            color: color(value.actionColor).isLight() ? '#0A0724' : '#ffffff',
          }}
          onClick={() => {
            setActionIsOpen(!disabled);
            setDataBkp({
              actionLabel: value.actionLabel,
              actionLink: value.actionLink,
              actionColor: value.actionColor,
            });
          }}
        >
          {value.actionLabel || 'Texto de ação'}
        </button>
      </Popover>
    </div>
  );

  const renderFooterBox = () => (
    <div className="template-footer-box">
      <b>Enviado por Apolo PME</b>
      <span>
        Caso não queira mais receber e-mails como este,
        <a href="https://google.com" target="_blank" rel="noreferrer">
          clique aqui
        </a>
      </span>
    </div>
  );

  return (
    <Container mode={mode}>
      {renderProfileBox()}
      {renderTitleBox()}
      {renderImageBox()}
      {renderSubtitleBox()}
      {renderContentBox()}
      {renderButtonBox()}
      {renderFooterBox()}
    </Container>
  );
}

Template1.defaultProps = {
  disabled: false,
};

Template1.propTypes = {
  mode: PropTypes.string.isRequired,
  value: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Template1;
