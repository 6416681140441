import styled from 'styled-components';
import SelectComponent from '~/components/Select';
import previewStep3 from '~/assets/images/step-3.png';

import InputComponent from '~/components/Input';

export const Container = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;

  .baseSegment {
    max-width: 400px;
  }
`;

export const Col = styled.div`
  flex: 1;
`;

export const Base = styled.div`
  flex: 1;
  max-width: 450px;

  > h1 {
    margin: 25px 0;
  }

  .info {
    max-width: 400px;
    line-height: 25px;
    margin: 15px 0 30px 0;
  }
`;

export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 20px;
`;

export const Input = styled(InputComponent).attrs({
  maxWidth: 400,
})``;

export const Select = styled(SelectComponent)`
  margin-top: 50px;
`;

export const ImagePreview = styled.img`
  width: 500px;
  object-fit: contain;
`;

export const ImageBackground = styled.div`
  width: 100%;
  height: 700px;
  position: absolute;
  top: 125px;
  right: 0;
  max-width: 870px;
  background-image: url(${previewStep3});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 112px;
`;
