import types from './types';

export function setIsLoading(isLoading) {
  return {
    type: types.SET_IS_LOADING,
    payload: { isLoading },
  };
}

export function setIsSuccess(isSuccess, callback = () => {}) {
  return {
    type: types.SET_IS_SUCCESS,
    payload: { isSuccess, callback },
  };
}

export function setIsError(isError, callback = () => {}) {
  return {
    type: types.SET_IS_ERROR,
    payload: { isError, callback },
  };
}
