import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function ProgressBarStepOnBoarding({ progress }) {
  return (
    <Container progress={progress}>
      <div className="progress" />
    </Container>
  );
}

ProgressBarStepOnBoarding.defaultProps = {
  progress: 0,
};

ProgressBarStepOnBoarding.propTypes = {
  progress: PropTypes.number,
};

export default ProgressBarStepOnBoarding;
